import { DateTime } from 'luxon';
import BookingSummary from '../components/booking-summary.js';
import Cookie from '../wr/base/cookie.js';
import ConfigsUtils from '../base/aem-configs/config-utils.js';
import MaxAllowedRoomConfig from '../base/aem-configs/max-allowed-room-config';
import {
  getElTrueHeight,
  scrollToAnimated,
  isMobileWidth,
  exists
} from '../base/dom-utils.js';
import { isBNPLEX } from '../base/utils.js';
import {
  countryChange,
  populateForm,
  stateChange
} from '../base/form-population.js';
import RateDetails from '../components/rate-details.js';

import {
  getCriteria,
  setCriteria,
  getSessionCriteria,
  getSearchOverview,
  getSessionStorage,
  getRoomsRates,
  getBrand,
  getBrandTier,
  ReservationHandler,
  UserHandler,
  deletePropAvailSessionData,
  removeRoomsRates,
  buildSearchQuery,
  setLocalStorage
} from '../base/session-handler.js';
import {
  _isNotNull,
  formatDateForBWSAvailability,
  formatDateForPrinting,
  formatDateForUplift,
  formatNumber,
  formatTimeShow,
  getCurrencyMapping,
  getDateFromDateString,
  getNumDays,
  getServiceUrl,
  handlePostSubtotal,
  isBonusRP,
  isGoFreeRate,
  isGoFastRate,
  isSMORate,
  isLoyaltyRP,
  hasPolicyFlag,
  isBookMessagingForcedToHide,
  serializeObject,
  toMoney,
  CookieHandler,
  callService,
  getAddress,
  getAddressWithoutProvinceCountry,
  getName,
  getUNAP,
  setUNAP,
  EventHandler
} from '../base/utils.js';
import {
  $_PAGE_,
  _LANGUAGE_,
  _LOCALE_,
  _REGION_,
  brand_id,
  brandMap,
  confirmation_redirect_url,
  isVisaCheckout,
  overview_brandId,
  overview_brandTier,
  overview_orsId,
  overview_propertyId,
  property_country_code,
  PROPERTY_PHONE_NUMBER,
  roomrate_redirect_url,
  req_rate_plan,
  req_rate_cc,
  direct_bill_supported,
  req_roomImg,
  req_room_type,
  additionalCorpCodes,
  CHECKOUT_READY,
  CARD_SUBMITTING,
  OFFERID,
  PRESCREENID,
  DECISIONSTATUS,
  pageHash,
  debitCardOfferId,
  URLOFFERID
} from '../base/vars.js';
import BNPLUplift from '../components/bnpl-uplift.js';
import Analytics from '../base/wyn-analytics-module.js';
import BnplAnalytics from '../base/bnpl-uplift-analytics.js';
import PhoneInput from '../wr/components/phone-input.js';
import HideProvinceCountryConfig from '../base/aem-configs/hide-Province-Country';


const dateFormats = [
  {locale: 'en-us', format: 'MM/dd/yyyy', display: 'MM/DD/YYYY'},
  {locale: 'en-uk', format: 'dd/MM/yyyy', display: 'DD/MM/YYYY'},
  {locale: 'en-ca', format: 'dd/MM/yyyy', display: 'DD/MM/YYYY'},
  {locale: 'es-xl', format: 'dd/MM/yyyy', display: 'DD/MM/AAAA'},
  {locale: 'es-la', format: 'dd/MM/yyyy', display: 'DD/MM/AAAA'}
];

function getDateFormat(locale) {
  const record = dateFormats.filter(obj => {
    return obj.locale === locale;
  });
  return (record.length > 0) ? record[0].format : 'dd/MM/yyyy';
}

function getDateDisplay(locale) {
  const record = dateFormats.filter(obj => {
    return obj.locale === locale;
  });
  return (record.length > 0) ? record[0].display : 'DD/MM/YYYY';
}

class BookingForm {
  constructor() {
    this.refresh_token = '';
    this._params = {};
    this._data = {};
    this._bcus = '';
    this._data.overview = '';
    this._bookTrigger = false;
    this.preScreenShowed = false;
    this._isInstantRateMatch = false;
    this.MyCheckConfig = {};
    if($('.payment-method__form').length) {
      this.loadMyCheckV3Script();
    }
    this.onGetProfileSignOut();
    this.currentCountry = '';
    this.reduceCCPromoHeaderLetterSpacing();
    this.handleCCPromoImageSpacing();
    this.hasToken;
  }

  init() {

    this.resetBookingForm();
    this.addUserInfoToMyCheck();
    this.initParams();
    this.initPhoneInput();
    this.viewInit();
    this.bookingFormPopulateTermsAndConditions();
    this.removeValidationIfAuthored();
    this.showMarketingOptWR();
    this.onReadMoreCollapse();
    this.onGetCriteriaSuccess();
    this.onFullProfileFetched();
    if(!$_PAGE_.is('.packages-page')){
      this.handleInstantHold();
      this.handleCaesarsRewards();
      this.onBookingFormSubmit();
      this.onCCPromoClick();
      this.onDirectBillLinkClick();
      this.validateDirectBillNumber();
      this.ifRateDetailsLinkAuthored();
      this.smsUpdatesInit();
      this.initStorageListener();
      this.chinaPiplConsentInit();
      deletePropAvailSessionData();
      $('#uplift-monthly-payment').disabled = true;
    }

    $(document).on('iframeStatusChange', function(e, eventInfo) {
      this.hasToken = eventInfo;
      const paymentMethodRadio = document.querySelector('input[name="paymentmethodradio"]:checked');
      if (paymentMethodRadio && paymentMethodRadio.value == 'uplift-monthly-payment') {
        if (this.hasToken) {
          $('button.confirm-booking').removeClass('disabled-booking');
        } else {
          $('button.confirm-booking').addClass('disabled-booking');
        }
      }
    });
  }
  chinaPiplConsentInit() {
    let _this = this;
    let $chinaPiplCheckbox = $('#china-pipl');
    let $chinaPiplContainer = $('.china-pipl');
    let $mobilePiplDescription = $('.china-pipl__description--mobile');
    const CHINA_SYMBOL = 'CN';

    let chinaPiplConsentDone = (sessionStorage.getItem('wyndham-china-pipl-consent') === 'true') || false;

    $('#customerCountryCode').on('change', function() {
      if (!UserHandler.isWRLoggedIn()) {
        if (this.value == CHINA_SYMBOL && !chinaPiplConsentDone) {
          $chinaPiplContainer.show();
          setupMobilePiplReadMore();
          $chinaPiplCheckbox.attr('data-parsley-required', true);
          $chinaPiplCheckbox.parsley();
        } else {
          if (_this.currentCountry == CHINA_SYMBOL) {
            $mobilePiplDescription.hide();
            $chinaPiplContainer.hide();
            $chinaPiplCheckbox.attr('data-parsley-required', false);
            $chinaPiplCheckbox.parsley();
          }
        }
        _this.currentCountry = this.value;
      }
      decideIfRunValidatePiplTC();
    });

    $('.submit-btn-container .submit').click(function() {
      validatePiplAndTC(true);
    });

    $('.terms #checkbox10, #china-pipl').on('change', (e) => {
      decideIfRunValidatePiplTC();
    });

    bindMobilePiplArrowEvents();

    setupPiplAnalytics();

    function setupMobilePiplReadMore() {
      if (isMobileWidth()) {
        $('.terms-legal-bttn .china-pipl__text--mobile').show();
        $('.terms-legal-bttn .china-pipl__text--desktop').hide();
        $mobilePiplDescription.show();
      }
    }

    function bindMobilePiplArrowEvents() {
      let $mobilePiplDescArrow = $mobilePiplDescription.find('.arrow');
      $mobilePiplDescArrow.click(function() {
        let $mobilePiplDescriptionBox = $mobilePiplDescription.find('.info-box-content');
        if ($mobilePiplDescriptionBox.hasClass('line-clamp')) {
          $mobilePiplDescArrow.attr('aria-expanded','true');
          $mobilePiplDescriptionBox.removeClass('line-clamp');
        } else {
          $mobilePiplDescArrow.attr('aria-expanded','false');
          $mobilePiplDescriptionBox.addClass('line-clamp');
        }
      });
    }

    function setupPiplAnalytics() {
      $chinaPiplCheckbox.on('change', (e) => {
        if (e.target.value == 'on') {
          if (typeof window._satellite !== 'undefined') {
            window._satellite.track('chinaConsent');
          }
        }
      });
    }

    function decideIfRunValidatePiplTC() {
      let alreadyValidatedOnce = $('.parsley-errors-list:not(.pipl-tc-validator-errors-list)').length > 0;
      if (alreadyValidatedOnce) {
        validatePiplAndTC();
      }
    }

    function validatePiplAndTC(fromSubmit) {
      let $tcAndPiplError = $('#pipl-tc-validator-errors-list');
      let $tcAndPiplText = $tcAndPiplError.find('p');

      let piplAndTermsError = $('.terms-legal-bttn').data('piplAndTermsError');
      let termsOnlyError = $('.terms-legal-bttn').data('tcError');
      let piplError = $('.terms-legal-bttn').data('piplError');

      let piplOn = $chinaPiplCheckbox.parsley().validate() === true;
      let termsOn = $('#checkbox10').parsley()
        .validate() === true;

      // user not logged and China selection
      if (_this.currentCountry == CHINA_SYMBOL && !UserHandler.isWRLoggedIn() && !chinaPiplConsentDone) {
        if (!piplOn && !termsOn) {
          $tcAndPiplText.html(piplAndTermsError);
          $tcAndPiplError.show();
        } else if (piplOn && !termsOn) {
          $tcAndPiplText.html(termsOnlyError);
          $tcAndPiplError.show();
        } else if (!piplOn && termsOn) {
          $tcAndPiplText.html(piplError);
          $tcAndPiplError.show();
        } else {
          $tcAndPiplError.hide();

          let fullFormValid = $chinaPiplCheckbox.closest('#booking-req-user-form').parsley()
            .validate();
          if (fromSubmit && fullFormValid) {
            sessionStorage.setItem('wyndham-china-pipl-consent', true);
          }
        }
      } else {
        if (!termsOn) {
          $tcAndPiplText.html(termsOnlyError);
          $tcAndPiplError.show();
        } else {
          $tcAndPiplError.hide();
        }
      }
    }
  }

  checkInstantRate() {
    if (additionalCorpCodes && req_rate_cc && (additionalCorpCodes.indexOf(req_rate_cc) > -1)) {
      this._isInstantRateMatch = true;
      $('.hold-rate, .hold-rate-msg').show();
      $('#booking-req-user-form .billing-address, .contact-details .phonetype-radio, .contact-details .email, .contact-details .confirm-email').hide();
      $('.booking-form-content .name .form-heading:first-child, .booking-form-content .submit:first-child').hide();
      $('.rewards-promo-banner-component, .special-requests, .wyndham-rewards, .iata').hide();
      // Hiding payment when instant rate is selected for InstantHold
      $('#booking-req-user-form .payment-method-select, .payment-method').hide();
    }
  }

  handleInstantHold() {
    if (this._isInstantRateMatch) {
      $('.rewards-promo-banner-component, .special-requests, .wyndham-rewards, .iata').hide();
      $('.booking-form-content input:not("#customerFirstName"):not("#customerLastName"):not("#customerPhone"),.booking-form-content select, .booking-form-content textarea').each((index, el) => {
        let $el = $(el);
        $el.removeAttr('required');
      });
    }
  }

  onGetProfileSignOut() {
    if ($_PAGE_.is('.booking-page')) {
      this.checkInstantRate();
      if (isGoFastRate(req_rate_plan) || isGoFreeRate(req_rate_plan) || (this._isInstantRateMatch)) {
        $('.direct-bill').hide();
        $('#directBillNumber').remove();
      }
      EventHandler.one(EventHandler.fullProfile.fetched + ' ' + EventHandler.profile.signedOut, () => {
        this.init();
      });
      if($_PAGE_.is('.packages-page')){
        setTimeout(() => {
          this.init();
          EventHandler.send('getPackagesEvent');
        }, 1000);
      }
      $('#deviceType').val(CookieHandler.getDeviceTypeFromCookie());
    }
  }

  resetBookingForm() {
    if($('#booking-req-user-form').length > 0){
      $('#booking-req-user-form')[0].reset();
    }
  }

  addUserInfoToMyCheck() {
    // Add user info to MyCheck component if logged in.
    if (UserHandler.isWRLoggedIn() && !isGoFreeRate(req_rate_plan) && !this._isInstantRateMatch) {
      $.ajax({
        url: getServiceUrl('myCheckToken'),
        type: 'GET',
        contentType: 'application/json',
        success: (res) => {
          this.refresh_token = res.refreshToken;
          window.refresh_token = res.refreshToken;
          EventHandler.send('tokenRetrieved');
        },
        error: (res) => {
          EventHandler.triggerEvent('brands-error', res);
        }
      });
    }
  }

  initParams() {
    let p = this._params,
      sc = getCriteria();

    p = $.extend(true, {}, sc);
    if (property_country_code != 'US') {
      $('.reservation-calc').hide();
    }
    p.propertyId = overview_propertyId;
    p.orsId = overview_orsId;
    p.altPropId = overview_orsId;
    p.brandId = overview_brandId;
    if (!(_isNotNull(p.propertyId) || _isNotNull(p.brandId))) {
      console.error('Booking page - propertyId is ' + p.propertyId + ' and brandId is ' + p.brandId);
    }
    p.rateCode = req_rate_plan;
    p.ratePlan = '';
    //check-in and check-out logic
    p.checkInDate = formatDateForBWSAvailability(p.checkInDate);
    p.checkOutDate = formatDateForBWSAvailability(p.checkOutDate);

    $.extend(true, this._params, p);
  }

  viewInit() {
    this.fetchOverview();
    this.populateCriteria();
    //pre-populate the booking form in case of user is logged in
    this.loggedInStatePop();
  }

  initPhoneInput() {
    PhoneInput.initializePhoneInputs();
  }

  loadMyCheckV3Script(rate = undefined, pub_key = undefined) {
    this.MyCheckConfig = ConfigsUtils.getMycheckConfiguration();
    if (!this.MyCheckConfig.MyCheckScriptUrl && ConfigsUtils.getMychecktV3Enabled()) {
      return;
    }else if(window.mycheck && rate && pub_key && ConfigsUtils.getMychecktV3Enabled()){
      this.myCheckInitV3(rate, pub_key);
      return;
    }

    let MyCheckStyleTag = document.createElement("link");
    MyCheckStyleTag.setAttribute("href", this.MyCheckConfig.MyCheckStyleUrl);
    MyCheckStyleTag.setAttribute("rel", 'stylesheet');
    document.head.appendChild(MyCheckStyleTag);

    let scriptTag = document.createElement("script");
    scriptTag.setAttribute("src", this.MyCheckConfig.MyCheckScriptUrl);
    document.body.appendChild(scriptTag);

    scriptTag.addEventListener("load", () => {
      if(ConfigsUtils.getMychecktV3Enabled()){
        console.log('Mycheck V3 script  Ready');
        if(rate && pub_key){
          this.myCheckInitV3(rate, pub_key);
        }
      }
    }, false);
  }

  fetchOverview() {
    let p = {},
      searchOverview = getSearchOverview();

    if (!searchOverview || searchOverview.propertyId !== overview_propertyId) {
      p.isOverviewNeeded = true;
      p.propertyId = this._params.propertyId;
      p.channelId = 'tab';
      p.language = _LOCALE_;

      callService('overview', p).then((res) => {
        if (res && res.status === 'OK') {
          Analytics.updatePropTierNum(res.properties[0].TierNum);
          this.handleOverviewSuccess(res.properties[0]);
        }
      });
    } else {
      if (!(_isNotNull(window.digitalData.property.propertyInfo.tierNum))) {
        Analytics.updatePropTierNum(searchOverview.TierNum);
      }
      this.handleOverviewSuccess(searchOverview);
    }

    //adds the property's phone number to the booking page after signing in
    const property_phone_number = getSessionStorage(PROPERTY_PHONE_NUMBER);
    const propertyDataAddress = $('.res-rate-summary .res-rate-info .res-summary-container .reservation-information .property-data .property-address');
    const propertyDataNumber = $('.res-rate-summary .res-rate-info .res-summary-container .reservation-information .property-data .property-number a');
    if (property_phone_number && property_phone_number.propertyPhoneNumber && exists(propertyDataAddress) && exists(propertyDataNumber) && propertyDataNumber.attr('href') === 'tel:') {
      propertyDataNumber.attr('href', `tel:${property_phone_number.propertyPhoneNumber}`);
      propertyDataNumber.html(property_phone_number.propertyPhoneNumber);
      sessionStorage.removeItem(PROPERTY_PHONE_NUMBER);
    }
  }

  handleOverviewSuccess(obj) {
    this._data.overview = obj;
    this.populateOverviewView(); //populate overview dependant data like TA
    // Add accepted credit cards to the booking form
    //invoke individual segments
    this.fetchRoomsRates();
  }

  fetchRoomsRates() {
    let p = {},
      roomsRates = getRoomsRates();

    if (!roomsRates) {
      p.brand_id = this._params.brandId;
      p.checkout_date = this._params.checkOutDate;
      p.checkin_date = this._params.checkInDate;
      p.adults = this._params.adults;
      p.children = this._params.children;
      p.childAge = this._params.childAge;
      p.rooms = this._params.rooms;
      p.ratePlan = this._params.ratePlan;
      p.rate_code = this._params.rateCode;
      p.group_code = this._params.groupCode;
      p.corporate_id = this._params.corpCode;
      p.propertyId = this._params.propertyId;
      p.sorting = this._params.sorting;
      p.isOrsId = this._params.orsId;
      p.altPropId = this._params.altPropId;
      p.region = this._params.region;
      p.language = this._params.language;
      p.useWRPoints = this._params.useWRPoints;

      if (p.brandId === 'WY') {
        p.isOrsId = true;
        p.altPropId = p.orsId;
      }
      if (isMobileWidth() && _isNotNull(additionalCorpCodes)) {
        p.additionalCorpCodes = additionalCorpCodes;
      }
      p.useWRPoints = true;
      callService('roomsRates', p).then((res) => {
        if (res && res.status === 'OK') {
          this.handleRoomsRatesSuccess(res.roomsAndRates);
        } else {
          this.noMatchTrigger();
        }
      });
    } else {
      this.handleRoomsRatesSuccess(roomsRates);
    }
  }

  handleRoomsRatesSuccess(obj) {
    this._data.roomsAndRates = obj;
    this.populateView();
  }

  populateCriteria() {
    let sp = this._params,
      checkInDate = getDateFromDateString(sp.checkInDate),
      checkOutDate = getDateFromDateString(sp.checkOutDate);
    setCriteria({
      nights: getNumDays(checkInDate, checkOutDate)
    });
    BookingSummary.handleAdults(sp.adults);
    BookingSummary.handleChildren(sp.children);
    BookingSummary.handleRooms(sp.rooms);
    BookingSummary.handleNights(getCriteria().nights);
    let pfpCookie = CookieHandler.readCookie('pfpCookie');
    if (!(sp.iata == null || sp.iata == '') && (pfpCookie != sp.iata)) {
      $('input[name="iata"]').val(window.digitalData.search.searchInfo.iataCode);
    } else if (pfpCookie) {
      $('input[name="iata"]').val(pfpCookie)
        .prop('disabled', true);
    }
    $('input[name="iata"]').on('change', (e) => {
      window.digitalData.search.searchInfo.iataCode = $(e.currentTarget).val();
    });
  }

  loggedInStatePop() {
    if (UserHandler.isWRLoggedIn()) {
      let mo = UserHandler.getWRObject(),
        $ctx = $('#booking-req-user-form');
      $('input[name="customerFirstName"]', $ctx).val(mo.fname);
      $('input[name="customerLastName"]', $ctx).val(mo.lname);
      $('input[name="customerEmail"]', $ctx).val(mo.email);
      $('input[name="confirmEmail"]', $ctx).val(mo.email);
      $('input[name="customerPhone"]', $ctx).val(mo.phone);
      PhoneInput.refreshInputField(0);
      if (mo.phone && mo.phone.startsWith('+')) {
        const countryCode = PhoneInput.getPhoneNumberCountryByIndex(0);
        const newPhoneNumber = mo.phone.replace(countryCode, '');
        if(newPhoneNumber !== mo.phone) {
          $('input[name="customerPhone"]', $ctx).val(newPhoneNumber);
        }
      }
      $('input[name="customerAddress"]', $ctx).val(mo.add1);
      $('input[name="customerAddress2"]', $ctx).val(mo.add2);
      $('input[name="customerCity"]', $ctx).val(mo.city);
      if (mo.country) {
        if (mo.country.match(/US|CA/)) {
          const state = mo.country + '_' + mo.state;
          $('select[name="customerStateCode"]', $ctx).val(state);
          $('select[name="customerStateCode"]', $ctx).trigger('change');
          $('select[name="customerCountryCode"]', $ctx).val(mo.country);
          this.checkCaesarsState(state);
        }
        if (!mo.country.match(/US|CA/)) {
          $('select[name="customerCountryCode"]', $ctx).val(mo.country);
          $('select[name="customerCountryCode"]', $ctx).trigger('change');
          $('select[name="customerStateCode"]', $ctx).val('NA')
            .trigger('change');
          $('select[name="customerStateCode"]', $ctx).prop('disabled', true);
        }
      }
      $('input[name="customerPostalCode"]', $ctx).val(mo.zip);
      $('input[name="customerWRNumber"]', $ctx).val(mo.wrNo);
      $('input[name="rewardNumber"]', $ctx).val(mo.wrNo);

      $('.wyndham-rewards .checkbox').hide();
      $('#marketing-sms-updates').prop('checked', mo.enrolledSMS == 'true');

      if (this.isCaesarsBrand() && ('caesarsMemberNumber' in mo)) {
        this.showCaesarsRewardsSignup(false);
      }

    } else {
      $('.wyndham-rewards .checkbox').show();
    }
    this.preScreenInit();
  }

  getIpsOfferIdForCardCpcCode(data, cardCpcCode, upSellFlag, defaultCardIpsOfferId) {

  if (defaultCardIpsOfferId === '') {
      const matchingCard = data.find(card => {
        if (upSellFlag) {
          return card.cardCpcCode === cardCpcCode && card.upsellOfferApplicable;
        }
        return card.cardCpcCode === cardCpcCode;
      });
      if (matchingCard) {
        let ipsOfferId;

        if (upSellFlag) {
            ipsOfferId = matchingCard.upsellIpsOfferId;
        } else {
            ipsOfferId = matchingCard.ipsOfferId;
        }
        const result = {
          cardTitleText: matchingCard.cardTitleText,
          cardSubtitleText: matchingCard.cardSubtitleText,
          cardLink1path: matchingCard.cardLink1path,
          cardLink2path: matchingCard.cardLink2path,
          cardDisclaimertext: matchingCard.cardDisclaimertext,
          cardStatementcreditamount: matchingCard.cardStatementcreditamount,
          cardDescription: matchingCard.cardDescription,
          cardImage: matchingCard.cardImage,
          ipsOfferId: ipsOfferId,
          upsellOfferApplicable : matchingCard.upsellOfferApplicable,
          fullAppOfferId: matchingCard.fullAppOfferId,
          upsellFullAppOfferId: ''
        };
    
        if (upSellFlag) {
          result.upsellIpsOfferId = matchingCard.upsellIpsOfferId;
          if (matchingCard.upsellFullAppOfferId !== undefined) {
            result.upsellFullAppOfferId = matchingCard.upsellFullAppOfferId;
          }
        }
        return result;
      }
    } else {
      let ipsOfferId = defaultCardIpsOfferId;
      const result = {
        cardTitleText: data.titletext,
        cardSubtitleText: data.subtitletext,
        cardLink1path: data.link1path,
        cardLink2path: data.link2path,
        cardDisclaimertext: data.disclaimertext,
        cardStatementcreditamount: data.statementcreditamount,
        cardDescription: data.description,
        cardImage: data.creditImagePath,
        ipsOfferId: ipsOfferId,
        upsellOfferApplicable : '',
        upsellIpsOfferId: '',
        fullAppOfferId: data.defaultCardFullAppOfferId
      };
      return result;
      
    }
  
    return null;
  }
  
  preScreenInit(){
      let preScreenFlag = false;
      let preScreenConfig = window.wyndham && window.wyndham.configs && window.wyndham.configs.BarclayInstantPreScreenConfig;
      const isUserLoggedIn = UserHandler.isWRLoggedIn();
      window.digitalData.cards.offerId = '';
      $('.booking-page').removeClass('loading');
      $('.promo-link .showInMobile').hide();
      localStorage.removeItem(URLOFFERID);
      //let barclayBrandId = (overview_brandTier || overview_brandId || brand_id).toLowerCase();
     // /content/whg-ecomm-responsive/en-us/lq/booking/jcr:content/mainpar/creditcardpromo_6e0b.model.json
     //content/whg-ecomm-responsive/en-us/lq/booking/jcr:content/bookingform/form_parsys/creditcardpromo.model.json
     //let queryString = '/content/whg-ecomm-responsive/' + _LOCALE_ + '/' + barclayBrandId + '/booking/jcr:content/mainpar/creditcardpromo.model.json';
      let queryString = $('.promo-path').data('promo-path') + '.model.json';
      $.ajax({
        type: 'GET',
        url: queryString,
        contentType: 'application/json',
        success: (data) => {
          let defaultCardCpcCode = '';
          let defaultCardIpsOfferId = '';
          let debitCardButtontext = '';
          let debitCardButtonUrl = '';
          let defaultCardFullAppOfferId = '';

          if (data) {
            defaultCardCpcCode = data.defaultCardCpcCode;
            defaultCardIpsOfferId = data.defaultCardIpsOfferId;
            debitCardButtontext = data.debitCardButtontext ? data.debitCardButtontext : '';
            debitCardButtonUrl = data.debitCardButtonUrl ? data.debitCardButtonUrl : '';
            defaultCardFullAppOfferId = data.defaultCardFullAppOfferId;
            $(".promo-img img").attr('src', data.creditImagePath ? data.creditImagePath : '');
          }
          if (data && data.cardMapping) {
            let offerId = '';
            let ipsOfferArr = '';
            let upSellFlag = false;
            let userTypeCode = '';
            let userTypeCodeDebit = '';
            
            if (isUserLoggedIn) {
              let wrccCodesString = localStorage.getItem("wrccCodes");
              let wrccCodesArray = JSON.parse(wrccCodesString);
              if (Array.isArray(wrccCodesArray)) {
                userTypeCode = wrccCodesArray[0];
                userTypeCodeDebit = wrccCodesArray[1];
              }
            }

            let filteredDebitCards = data.cardMapping.filter(card => 
              (card.debitCardCpcCode === userTypeCodeDebit || card.debitCardCpcCode === userTypeCode)
            );

            let filteredCreditCards = data.cardMapping.filter(card => 
              card.cardCpcCode === userTypeCode || card.cardCpcCode === userTypeCodeDebit
            );
            if (filteredCreditCards && filteredCreditCards[0]) {
              userTypeCode = filteredCreditCards[0].cardCpcCode;
            }
            if (filteredDebitCards && filteredDebitCards[0]) {
              userTypeCodeDebit = filteredDebitCards[0].debitCardCpcCode;
            }

            if (!isUserLoggedIn && preScreenConfig && preScreenConfig.configs[0].unauthenticatedPrescreenCallInitiated) {
              upSellFlag = false;
              ipsOfferArr = this.getIpsOfferIdForCardCpcCode(data, defaultCardCpcCode, upSellFlag, defaultCardIpsOfferId);   
              offerId = ipsOfferArr ? ipsOfferArr.ipsOfferId : defaultCardIpsOfferId;     
              preScreenFlag = true;
              defaultCardFullAppOfferId = data.defaultCardFullAppOfferId;
            } 
            if (isUserLoggedIn && preScreenConfig && preScreenConfig.configs[0].authenticatedNonExistingMemberPrescreenCallInitiated === true && (userTypeCode === '' || userTypeCode === undefined)) {
              upSellFlag = false;
              ipsOfferArr = this.getIpsOfferIdForCardCpcCode(data, defaultCardCpcCode, upSellFlag, defaultCardIpsOfferId);
              offerId = ipsOfferArr ? ipsOfferArr.ipsOfferId : defaultCardIpsOfferId;     
              preScreenFlag = true;
              defaultCardFullAppOfferId = data.defaultCardFullAppOfferId;
            } else if (isUserLoggedIn && preScreenConfig && preScreenConfig.configs[0].authenticatedExistingMemberPrescreenCallInitiated === true && filteredDebitCards && filteredDebitCards[0] && filteredDebitCards[0].isDebitCard === false && filteredCreditCards && filteredCreditCards.length == 0) {
              upSellFlag = false;
              ipsOfferArr = this.getIpsOfferIdForCardCpcCode(data, defaultCardCpcCode, upSellFlag, defaultCardIpsOfferId);
              offerId = ipsOfferArr ? ipsOfferArr.ipsOfferId : defaultCardIpsOfferId;     
              preScreenFlag = true;
              defaultCardFullAppOfferId = data.defaultCardFullAppOfferId;
            } else if (isUserLoggedIn && (userTypeCode !== '' || userTypeCode !== 'undefined')) {
              upSellFlag = true;
              ipsOfferArr = this.getIpsOfferIdForCardCpcCode(data.cardMapping, userTypeCode, upSellFlag, '');
              offerId = ipsOfferArr && ipsOfferArr.ipsOfferId;
              defaultCardFullAppOfferId = '';
              if( ipsOfferArr && ipsOfferArr.upsellOfferApplicable && preScreenConfig && preScreenConfig.configs[0].authenticatedExistingMemberPrescreenCallInitiated === true) {
                preScreenFlag = true;
              } else if( filteredCreditCards && filteredCreditCards.length > 0 && filteredCreditCards[0] && filteredCreditCards[0].upsellOfferApplicable === false && filteredDebitCards && filteredDebitCards[0] && filteredDebitCards[0].isDebitCard === false) {
                $('.credit-card-promo-top').hide();
                $('.credit-card-promo').hide();
              } else {
                upSellFlag = false;
                preScreenFlag = false;

                const debitCardResult = data.cardMapping.filter(card => card.isDebitCard === true)
                  .map(card => ({
                      cardTitleText: card.cardTitleText,
                      cardSubtitleText: card.cardSubtitleText,
                      cardDescription: card.cardDescription,
                      cardLink1path: card.cardLink1path,
                      cardLink2path: card.cardLink2path,
                      cardDisclaimertext: card.cardDisclaimertext,
                      cardImage: card.cardImage,
                      debitCardCpcCode: card.debitCardCpcCode
                  }));
                if(debitCardResult) {
                  const button = document.querySelector('.promo-col-cta-debit .btn-secondary-debit-card');
                  $('.analytics-learn-more').addClass('hidden-xs');
                  $('.promo-col-row').addClass('has-debit-card');  
                  $('.promo-col-disclaimer').addClass('has-debit-card'); 
                  $('.wyn-right-col .promo-link').addClass('has-debit-card-link');  
                  $('.open-modal-links').addClass('has-debit-card'); 
                  $('.promo-img').addClass('has-debit-card-img');   
                  $('.promo-link .showInMobile').show();
                  $('.showInMobile').removeClass('hidden-xs hidden-md');
                  $('.promo-link.has-debit-card-link:nth-child(2)').hide();
                  $('.modal-links').hide();
                  $('.promo-link').eq(2).hide();
                  $('.promo-desc').show();
                  $('.credit-card-main').addClass('debit-card-main');   
                  $('.open-modal-links .has-debit-card').hide();
                  $(".promo-content .heading").html(debitCardResult[0].cardTitleText);
                  $(".promo-content .subheading").html(debitCardResult[0].cardSubtitleText);
                  $('a.showInMobile').each(function() {
                    var $this = $(this);
                    $.each($this.data(), function(key, value) {
                        if (key === 'lightboxTrigger' || key === 'lightboxPath') {
                            var kebabKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
                            $this.removeAttr('data-' + kebabKey);
                        } else {
                            $this.removeAttr('data-' + key);
                        }
                    });
                });                            
                  debitCardResult[0].cardLink2path !== null ? $('.promo-link a').attr('href', debitCardResult[0].cardLink2path ).attr('target', '_blank').removeAttr('data-lightbox-trigger').removeAttr('data-lightbox-path') : $('.promo-link').hide();
                  debitCardResult[0].cardDisclaimertext ? $('.disclaimer').html(debitCardResult[0].cardDisclaimertext) : $('.disclaimer').hide();
                  debitCardResult[0].cardDescription ? $('.promo-desc').html(debitCardResult[0].cardDescription) : $('.promo-desc').hide();
                  debitCardResult[0].cardDescription ? $('.wyn-right-col .promo-desc').html(debitCardResult[0].cardDescription) : $('.wyn-right-col .promo-desc').hide();
                  debitCardResult[0].cardStatementcreditamount ? '' : $('.reservation-calc').hide(); 
                  debitCardResult[0].cardStatementcreditamount ? '' : $('.total-after-credit-val').hide(); 
                  debitCardResult[0].cardImage && $(".promo-img img").attr('src', debitCardResult[0].cardImage);
                  $('.credit-card').hide();
                  $('.promo-col-cta-debit').show();

                  const year = new Date().getFullYear();
                  const lastTwoDigits = year % 100;  

                  window.digitalData.cards.offerId =  debitCardResult[0].debitCardCpcCode + debitCardOfferId + lastTwoDigits;

                  button.innerText = debitCardButtontext ? debitCardButtontext : 'Learn More';
                  
                  button.onclick = function() {
                    var windowWidth = 800;
                    var windowHeight = 600;
                    window.open(debitCardButtonUrl, "_blank", "width=" + windowWidth + ",height=" + windowHeight);
                  };    
                  //const divElement = document.querySelector('.has-debit-card-img .pll-image-container');
                  //divElement.removeClass('ratio-3x2');
                  //divElement.removeAttribute('style');  

                }
              }
            }

            if (preScreenFlag === true) {   
              $('#booking-req-user-form').parsley();
              
              let mo = UserHandler.getWRObject();
              const parsleyInstances = {
                customerFirstName: $('#customerFirstName').parsley(),
                customerLastName: $('#customerLastName').parsley(),
                email: $('#email').parsley(),
                customerPhone: $('#customerPhone').parsley(),
                customerCity: $('#customerCity').parsley(),
                customerAddress: $('#customerAddress').parsley(),
                customerStateCode: $('#customerStateCode').parsley(),
                customerCountryCode: $('#customerCountryCode').parsley(),
                customerPostalCode: $('#customerPostalCode').parsley(),
                enroll: $('#enroll').parsley()
              };


              const getBookingData = (userData) => {
                if (isUserLoggedIn) {
                  let newPhoneNumber = '';
                  if (userData.phone && userData.phone.startsWith('+')) {
                    const countryCode = PhoneInput.getPhoneNumberCountryByIndex(0);
                    newPhoneNumber = countryCode && userData.phone.replace(countryCode, '');
                  }
              
                  // Check if email exists or if zip, state, city, and addressLine1 exist
                  const hasEmail = userData.email;
                  const hasAddress = userData.zip && userData.state && userData.city && userData.add1;
                  if (hasEmail && hasAddress) {
                    return {
                      offerIds: offerId,
                      firstName: userData.fname,
                      lastName: userData.lname,
                      primaryPhoneNumber: newPhoneNumber !== userData.phone ? newPhoneNumber : userData.phone,
                      emailAddress: userData.email,
                      zip: userData.zip,
                      state: userData.state,
                      city: userData.city,
                      addressLine1: userData.add1,
                      
                    };
                  } else if (hasEmail) {
                    return {
                      offerIds: offerId,
                      firstName: userData.fname,
                      lastName: userData.lname,
                      primaryPhoneNumber: newPhoneNumber !== userData.phone ? newPhoneNumber : userData.phone,
                      emailAddress: userData.email
                    };
                  } else if (hasAddress) {
                    return {
                      offerIds: offerId,
                      firstName: userData.fname,
                      lastName: userData.lname,
                      primaryPhoneNumber: newPhoneNumber !== userData.phone ? newPhoneNumber : userData.phone,
                      zip: userData.zip,
                      state: userData.state,
                      city: userData.city,
                      addressLine1: userData.add1
                    };
                  }
                } else {
                  const firstName = $('#customerFirstName').val();
                  const lastName = $('#customerLastName').val();
                  const customerPhone = $('#customerPhone').val();
                  const customerEmail = $('#email').val();
                  const customerZip = $('#customerPostalCode').val();
                  const customerState = $('#customerStateCode').val() && $('#customerStateCode').val().split('_')[1];
                  const customerCity = $('#customerCity').val();
                  const customerAdd1 = $('#customerAddress').val();
                  // Check if email exists or if zip, state, city, and addressLine1 exist
                  const hasEmail = customerEmail;
                  const hasAddress = customerZip && customerState && customerCity && customerAdd1;
                  const hasPhone = customerPhone;
                  
                  if (hasEmail && hasAddress) {
                    return {
                      offerIds: offerId,
                      firstName,
                      lastName,
                      ...(hasPhone ? { primaryPhoneNumber: customerPhone } : {}),
                      emailAddress: customerEmail,
                      zip: customerZip,
                      state: customerState,
                      city: customerCity,
                      addressLine1: customerAdd1
                    };
                  } else if (hasEmail) {
                    return {
                      offerIds: offerId,
                      firstName,
                      lastName,
                      ...(hasPhone ? { primaryPhoneNumber: customerPhone } : {}),
                      emailAddress: customerEmail
                    };
                  } else if (hasAddress) {
                    return {
                      offerIds: offerId,
                      firstName,
                      lastName,
                      ...(hasPhone ? { primaryPhoneNumber: customerPhone } : {}),
                      zip: customerZip,
                      state: customerState,
                      city: customerCity,
                      addressLine1: customerAdd1
                      
                    };
                  }
                }
              
                return null;
              };
                        
              const callPreScreenAPI = (bookingData, ipsOfferArr, creditCardPreapprovedMessage) => {
                let currencyCode = $('.currency-code').first().text();
                let currencySymbol = $('.currency-symbol').first()  .text();
                let statementCredit = '- ' + currencySymbol + (ipsOfferArr && ipsOfferArr.cardStatementcreditamount !== undefined ? ipsOfferArr.cardStatementcreditamount.toFixed(2) : '0.00') + ' ' + currencyCode;

                let totalCostText = $('.barclay-totalcost').contents().not('.currency-symbol, .currency-code').text().trim();
                let reservationCost = parseFloat(totalCostText);
                let total = (reservationCost - (ipsOfferArr && ipsOfferArr.cardStatementcreditamount ? ipsOfferArr.cardStatementcreditamount : 0)).toFixed(2) + ' ' + currencyCode;
                $('.credit-card-promo-top-text').removeClass("dark-background");
                $('.credit-card-promo').removeClass("dark-offer-box offer-box");
                $('.credit-card-promo-top').hide();
                const timestamp = Date.now();
                if(ipsOfferArr.upsellIpsOfferId) {
                  statementCredit = '- ' + currencySymbol + (ipsOfferArr && ipsOfferArr.cardStatementcreditamount !== undefined ? ipsOfferArr.cardStatementcreditamount.toFixed(2) : '') + ' ' + currencyCode;
                  
                  total = (reservationCost - (ipsOfferArr && ipsOfferArr.cardStatementcreditamount ? ipsOfferArr.cardStatementcreditamount : 0)).toFixed(2) + ' ' + currencyCode;
  
                  $(".promo-content .heading").html(ipsOfferArr.cardTitleText);
                  $(".promo-content .subheading").html(ipsOfferArr.cardSubtitleText);
                  $('.promo-link a[data-lightbox-trigger=""]').eq(0).attr('href', '?lightbox=' + ipsOfferArr.cardLink1path );
                  $('.promo-link a[data-lightbox-trigger=""]').eq(0).attr('data-lightbox-path', ipsOfferArr.cardLink1path );
                  $('.promo-link a').eq(1).attr('href', ipsOfferArr.cardLink2path );
                  $('.disclaimer').html(ipsOfferArr.cardDisclaimertext);
                  $('.promo-link').eq(2).html(ipsOfferArr.cardDescription);
                  $('.credit-val').attr('data-val', ipsOfferArr && ipsOfferArr.cardStatementcreditamount );
                  $('.credit-val').text(statementCredit);    
                  $('.total-after-credit-val').text(total);
                  $(".promo-img img").attr('src', ipsOfferArr.cardImage);
                }
                const Cookie = `loglocale={"seed":"qa-automated-script","pageHashCode":${pageHash},"timestamp":${timestamp},"channel":"responsive","serviceVersion":"1.0","language":"${_LOCALE_}"};`;
                $_PAGE_.addClass('prescreening');
                $.ajax({
                  type: 'POST',
                  url: '/BWSServices/services/cards/preScreenOffer',
                  data: JSON.stringify(bookingData),
                  contentType: 'application/json',
                  header: {
                    Cookie: Cookie
                  },
                  dataType: 'json',
                  success: (response) => {
                    var data = response.data;
                    const isUserLoggedIn = UserHandler.isWRLoggedIn();
                    setLocalStorage(OFFERID, data.offerId);
                    setLocalStorage(PRESCREENID, data.prescreenId);
                    setLocalStorage(DECISIONSTATUS, data.decisionStatus);

                    window.digitalData.cards.offerId =  data.offerId;

                    $_PAGE_.removeClass('prescreening');
                    if (data && data.decisionStatus === 'APPROVED') {
                      let brandsToShowDarkBackground = preScreenConfig.configs[0].brandsWithDarkBorderColorCards;
                      $('.credit-card-promo').addClass(brandsToShowDarkBackground.includes(brand_id) ? "dark-offer-box" : "offer-box");
                      if(brandsToShowDarkBackground.includes(brand_id)) {
                        $('.credit-card-promo-top-text').addClass("dark-background");    
                      }
                      if(!isUserLoggedIn) {
                        $('.credit-card-promo-top').show();
                        $('.credit-card-promo-top-text').text(creditCardPreapprovedMessage);    
                        setLocalStorage(URLOFFERID, defaultCardIpsOfferId);
                      } else {
                        if(ipsOfferArr.upsellIpsOfferId) {
                          statementCredit = '- ' + currencySymbol + (ipsOfferArr && ipsOfferArr.cardStatementcreditamount !== undefined ? ipsOfferArr.cardStatementcreditamount.toFixed(2) : '') + ' ' + currencyCode;
                          
                          total = (reservationCost - (ipsOfferArr && ipsOfferArr.cardStatementcreditamount ? ipsOfferArr.cardStatementcreditamount : 0)).toFixed(2) + ' ' + currencyCode;

                          $(".promo-content .heading").html(ipsOfferArr.cardTitleText);
                          $(".promo-content .subheading").html(ipsOfferArr.cardSubtitleText);
                          $('.promo-link a[data-lightbox-trigger=""]').eq(0).attr('href', '?lightbox=' + ipsOfferArr.cardLink1path );
                          $('.promo-link a[data-lightbox-trigger=""]').eq(0).attr('data-lightbox-path', ipsOfferArr.cardLink1path );
                          $('.promo-link a').eq(1).attr('href', ipsOfferArr.cardLink2path );
                          $('.disclaimer').html(ipsOfferArr.cardDisclaimertext);
                          $('.promo-link').eq(2).html(ipsOfferArr.cardDescription);
                          $('.credit-val').attr('data-val', ipsOfferArr && ipsOfferArr.cardStatementcreditamount );
                          $('.credit-val').text(statementCredit);    
                          $('.total-after-credit-val').text(total);
                          $(".promo-img img").attr('src', ipsOfferArr.cardImage);
                          $('.credit-card-promo-top').show();
                          $('.credit-card-promo-top-text').text(creditCardPreapprovedMessage);  
                          setLocalStorage(URLOFFERID, ipsOfferArr.upsellIpsOfferId);
                        } else {
                          $('.credit-card-promo-top').show();
                          $('.credit-card-promo-top-text').text(creditCardPreapprovedMessage);
                          setLocalStorage(URLOFFERID, ipsOfferArr.ipsOfferId);  
                        }  
                        }
                    } else {
                      $('.credit-card-promo-top-text').removeClass("dark-background");
                      $('.credit-card-promo').removeClass("dark-offer-box offer-box");
                      $('.credit-card-promo-top').hide();
                      if(!isUserLoggedIn) {
                       setLocalStorage(URLOFFERID, defaultCardFullAppOfferId);
                      } else {
                        if(ipsOfferArr.upsellOfferApplicable) {
                          setLocalStorage(URLOFFERID, defaultCardFullAppOfferId ? defaultCardFullAppOfferId : ipsOfferArr.upsellFullAppOfferId);
                        } else {
                          setLocalStorage(URLOFFERID, defaultCardFullAppOfferId ? defaultCardFullAppOfferId : ipsOfferArr.fullAppOfferId);
                        }
                      }
                    }
                  },
                  error: (errData) => {
                    $_PAGE_.removeClass('prescreening');
                    EventHandler.triggerEvent('brands-error', errData);
                    localStorage.removeItem(OFFERID);
                    localStorage.removeItem(PRESCREENID);
                    localStorage.removeItem(DECISIONSTATUS);
                    localStorage.removeItem(URLOFFERID);
                  }
                });
              };
              let creditCardMsg = data.creditCardPreapprovedMessage ? data.creditCardPreapprovedMessage : "YOU'VE BEEN PRE SELECTED!";
              if (isUserLoggedIn) {
                const bookingData = getBookingData(mo);
                if (bookingData) {
                  callPreScreenAPI(bookingData, ipsOfferArr, creditCardMsg);
                }
              } else {
                $('#booking-req-user-form input').on('change', function() {
                  const bookingData = getBookingData();
                  const fieldId = $(this).attr('id');
                  if($('#' + fieldId).val()){
                  if(bookingData && bookingData.emailAddress !== null) {
                    if(bookingData && bookingData.address === null){
                    if(fieldId === 'customerStateCode' || fieldId === 'customerCountryCode' || fieldId === 'customerPostalCode' || fieldId === 'customerAddress' || fieldId === 'customerCity'){
                      return;
                    } else{
                      parsleyInstances[fieldId].validate();
                    }
                  } else {
                    parsleyInstances[fieldId].validate();
                  }
               
                  } else{
                    if(fieldId === 'email'){
                      return;
                    } else{
                      parsleyInstances[fieldId].validate();
                    }
                  }
                }
                if(bookingData) {
                  if($('#customerFirstName').parsley().isValid() && $('#customerLastName').parsley().isValid() && (!$('#email').val() || $('#email').parsley().isValid()) && $('#' + fieldId).val()){
                  callPreScreenAPI(bookingData, ipsOfferArr, data.creditCardPreapprovedMessage);
                  }
                }
                });
              }
            }                   
          } 
        },
        error: (errData) => {
          EventHandler.triggerEvent('brands-error', errData);
        }
      });
  }

  populateOverviewView() {
    let pdata = this._data.overview,
      sp = this._params;

    if(pdata.rating && pdata.ratingCount && pdata.ratingSiteStatus && pdata.ratingSiteStatus.toLowerCase() == 'active'){
      //subRatings
      $('.review-rating-value').text(pdata.rating.toFixed(1));
      $('.review-star-populate').children().each((i,e)=> {
        $(e).removeClass();
        if(pdata.rating >= i + 1){
            $(e).addClass('amenity-icon-star');
        }else if(pdata.rating >= i) {
          if((0.3 <= (Math.round((pdata.rating - Math.floor(pdata.rating)) * 10) / 10) && (Math.round((pdata.rating - Math.floor(pdata.rating)) * 10) / 10) <= 0.7)){
            $(e).addClass('amenity-icon-star-half');
          }else if((Math.round((pdata.rating - Math.floor(pdata.rating)) * 10) / 10) >= 0.8){
            $(e).addClass('amenity-icon-star');
          }else{
            $(e).addClass('amenity-icon-star-empty');
          }
        } else {
          $(e).addClass('amenity-icon-star-empty');
        }
      });
    }else {
      $('.booking-form-review').hide();
      $('.booking-form-review-mobile').hide();
    }
    BookingSummary.handleCheckIn(getDateFromDateString(sp.checkInDate), pdata.hotelPolicies.checkInTime);
    BookingSummary.handleCheckOut(getDateFromDateString(sp.checkOutDate), pdata.hotelPolicies.checkOutTime);

    EventHandler.one(EventHandler.UNAP.updated, () => BookingSummary.handleUNAP());
    let phoneNumber = pdata.phone1;
    if (property_country_code && (property_country_code === 'CN' || property_country_code === 'TW' || property_country_code === 'HK' || property_country_code === 'MO')) {
      if (phoneNumber.indexOf('+') !== 0) {
        phoneNumber = '+' + phoneNumber;
      }
    }
    setUNAP({
      name: pdata.hotelName,
      address: pdata.address1 + (pdata.address2 ? ' ' + pdata.address2 : '') + (pdata.address3 ? ' ' + pdata.address3 : ''),
      city: pdata.city,
      state: pdata.state,
      country: pdata.country,
      postalCode: pdata.postalCode,
      phone: phoneNumber
    });
  }

  populateView() {

    let roomsrates = this._data.roomsAndRates,
      sp = this._params,
      nights = getNumDays(sp.checkInDate, sp.checkOutDate);

    window.checkInDate = sp.checkInDate; // Expose check-in date for MyCheck Barclays integration

    if (roomsrates && roomsrates.rooms && roomsrates.rooms.length > 0) {
      let reqRatePlan = req_rate_plan,
        reqRoomType = req_room_type,
        reqRateCC = req_rate_cc || '',
        directBillSupport = direct_bill_supported || false,
        rooms = roomsrates.rooms,
        roomMatch = false;
      //populate the values
      let roomsArr = rooms.slice(0),
        room;
      while (roomsArr.length && !(roomMatch)) {
        room = roomsArr.pop();
        if (room.roomTypeCode == req_room_type) {
          roomMatch = true;
          break;
        }
      }

      if (roomMatch) {
        let rates = room.rates.slice(0),
          rate, rateMatch = false;
        BookingSummary.handleRoomType(room.description);
        BookingSummary.handleRoomDescription(room.shortName);

        while (rates.length && !(rateMatch)) {
          rate = rates.pop();
          if (rate.ratePlanId == req_rate_plan) {
            rateMatch = true;
            break;
          }
        }
        if (rateMatch) {
          this._data.room = room;
          this._data.rate = rate;
          if (rate.pacRatePlan) {
            this._data.bookingPoints = parseInt(rate.pacPoints);
            BookingSummary.handleGoFast(rate, nights);
           // RateDetails.fillTotal(rate.totalBeforeTax, rate.totalAfterTax, rate.currencyCode, rate.pacPoints * nights);
            RateDetails.updateRateBreakdown(rate.dateRateMap, rate.currencyCode);
            RateDetails.fillTaxes(rate.taxList, rate.currencyCode, null, getBrand(true),rate,'summary');
          } else if (rate.fnsRatePlan) {
            this._data.bookingPoints = parseInt(rate.totalFnsPoints);
            BookingSummary.handleGoFree(rate.totalFnsPoints);
            BookingSummary.handleTotalTax((rate.totalAfterTax - rate.totalBeforeTax), rate.currencyCode);
            RateDetails.updateRateBreakdown(rate.dateRateMap, rate.currencyCode);
            RateDetails.fillTaxes(rate.taxList, rate.currencyCode, null, getBrand(true),rate,'summary');
            let fees = 0,
              currency;
            if (rate.taxList[0]) {
              fees = parseFloat(rate.taxList[0] ? rate.taxList[0].taxPercent : 0);
              fees *= getCriteria().nights;
              currency = rate.taxList[0].taxPercent.replace(/[0-9\W]/g, '');
            }
            RateDetails.handleGoFree(formatNumber(parseInt(rate.totalFnsPoints)), fees, currency);
            $('.credit-card-promo').remove();
            $('.card-disclaimer').remove();
          } else {
            BookingSummary.handleCashRP();
            BookingSummary.handleSubtotal(rate.totalBeforeTax, rate.currencyCode);
            //BookingSummary.handleTotal(rate.totalAfterTax, rate.currencyCode);
            BookingSummary.handleTotalTax((rate.totalAfterTax - rate.totalBeforeTax), rate.currencyCode);
            RateDetails.fillTaxes(rate.taxList, rate.currencyCode, null, getBrand(true),rate,'summary');
            //RateDetails.fillTotal(rate.totalBeforeTax, rate.totalAfterTax, rate.currencyCode, null);
            RateDetails.updateRateBreakdown(rate.dateRateMap, rate.currencyCode);
          }

          //Display Components allowed by a FlexibleRate
          this.displayConfidenceMessaging(rate.guranteeConfigFlag, rate.cancelConfigFlag, this._data.overview.id, rate.fnsRatePlan);
          if ($('.payment-method__form').length) {
            $('#totalBeforeTax').val(rate.totalBeforeTax);
            $('#totalAfterTax').val(rate.totalAfterTax);
            let pub_key = $('.payment-method__form').data('pubkey');
            if (UserHandler.isWRLoggedIn() || this._isInstantRateMatch) {
              if (this.refresh_token) {
                if(ConfigsUtils.getMychecktV3Enabled()){
                  this.loadMyCheckV3Script(rate, pub_key);
                }
              } else {
                EventHandler.one('tokenRetrieved', () => {
                  if(ConfigsUtils.getMychecktV3Enabled()){
                    this.loadMyCheckV3Script(rate, pub_key);
                  }
                });
              }
            } else {
              if(ConfigsUtils.getMychecktV3Enabled()){
                this.loadMyCheckV3Script(rate, pub_key);
              }
            }
          }

          let ratePlansArr = roomsrates.ratePlans.slice(0),
            ratePlan;
          while (ratePlansArr.length) {
            ratePlan = ratePlansArr.pop();
            if (ratePlan.RatePlanCode == rate.ratePlanId) {
              break;
            }
          }

          BookingSummary.handleRatePlanDescription(ratePlan.RatePlanName);
          BookingSummary.handleRatePlanRibbon(rate.ratePlanId);

          Analytics.populateBookingRoomInfoAnalytics(room, rate, ratePlan);

          if (rate.depositPolicy) {
            BookingSummary.handleCancelPolicy(rate.cancelPolicy + '<br/><br/>' + rate.depositPolicy);
          } else {
            BookingSummary.handleCancelPolicy(rate.cancelPolicy);
          }

          var srcBrand = brandMap[window.digitalData.page.pageInfo.referringBrand] || brandMap[CookieHandler.readCookie(Analytics.referrerCookieName)] || $('#referringBrand').val() || (['WY', 'FE'].indexOf(overview_brandId) > -1 ? overview_brandTier : overview_brandId);
          var refBrand = (srcBrand === undefined) ? 'ALL' : srcBrand.toUpperCase();
          ReservationHandler.setBookData('refBrand', refBrand);
          ReservationHandler.setBookData('directBillSupported', directBillSupport);
          ReservationHandler.setBookData('rp', reqRatePlan);
          ReservationHandler.setBookData('rt', reqRoomType);
          ReservationHandler.setBookData('cc', reqRateCC);
          ReservationHandler.setBookData('curr', rate.currencyCode);
          ReservationHandler.setBookData('totbtax', toMoney(rate.averageBeforeTax));
          ReservationHandler.setBookData('tottax', toMoney(rate.totalAfterTax - rate.totalBeforeTax));
          ReservationHandler.setBookData('totatax', toMoney(rate.totalAfterTax));
          ReservationHandler.setBookData('rpname', ratePlan.RatePlanName);
          ReservationHandler.setBookData('nights', getCriteria().nights);
          ReservationHandler.setBookData('autoEnroll', rate.autoEnroll);
          ReservationHandler.setBookData('pacRate', rate.pacRatePlan);
          ReservationHandler.setBookData('qualPointsEarned', formatNumber(rate.qualPointsEarned));
          EventHandler.send('bookdata.complete');
        }
      } else {
        this.noMatchTrigger();
      }
    } else {
      this.noMatchTrigger();
    }
  }

  displayConfidenceMessaging(guranteeConfigFlag, cancelConfigFlag, propertyId, isRatePlan) {
    if (!isBookMessagingForcedToHide(propertyId)) {
      if (hasPolicyFlag(guranteeConfigFlag)) {
        $('.pay-at-hotel').removeClass('hidden');
        if(!isRatePlan) {
          this.checkCancellationMessage();
        }
      }
      if (hasPolicyFlag(cancelConfigFlag)) {
        $('.rs-book-with-conf').removeClass('hidden');
        $('.bb-total-for-stay').removeClass('hidden');
        $('.bb-icons .booking-page-icon-calendar').closest('.bb-icons')
          .removeClass('hidden');
      }
    }
  }

  myCheckInit(rate, pub_key) {
    $('[id=myc-wallet-modal]').each((i,e)=>{
      e.remove();
    });

    if(!isGoFreeRate(req_rate_plan) &&  !isSMORate(req_rate_plan) && !isBNPLEX(this._data.overview)){
      BNPLUplift.handleConfig({
        currencyCode: rate.currencyCode,
        subtotal: rate.totalAfterTax,
        noOfRooms:this._params.rooms,
        room: this._data.rate,
        bookingconfirm : (()=>this.performBooking()),
        propertyName: getName(),
        checkinDate: formatDateForUplift(this._params.checkInDate),
        checkoutDate: formatDateForUplift(this._params.checkOutDate),
        checkout: true
      });
    } else {
      BNPLUplift.trackBNPLBrandStatus(rate.currencyCode);
    }
  }

  myCheckInitV3(rate, pub_key){

    let myCheckLang;
    if (_LANGUAGE_ === _REGION_ || _LOCALE_ === 'ko-kr') {
      myCheckLang = ConfigsUtils.getMycheckV3LanguageValue(_LANGUAGE_.toLowerCase().split('_')[0]);
    } else if(_LOCALE_ === '') {
      myCheckLang = ConfigsUtils.getMycheckV3LanguageValue((_LANGUAGE_ + _REGION_).toLowerCase().split('-')[0]);
    }else{
      myCheckLang =  ConfigsUtils.getMycheckV3LanguageValue(_LOCALE_.split('-')[0]);
    }
    if (UserHandler.isWRLoggedIn() && !isGoFreeRate(req_rate_plan) && !this._isInstantRateMatch) {
      $.ajax({
        url: getServiceUrl('myCheckToken'),
        type: 'GET',
        contentType: 'application/json',
        success: (res) => {
          this.refresh_token = res.refreshToken;
          window.refresh_token = res.refreshToken;
          if(res.refreshToken){
            window.mycheck.auth.connect({
              publishableKey: pub_key,
              refreshToken: res.refreshToken
            });

            window.mycheck.wallet.wallet.init({
              'settings': {
                'locale' : myCheckLang,//optional-default is en (language code in ISO 639-1)
                'merchant_id':'', //required
                'view': 'CHECKOUT' //required - can be either CHECKOUT or MANAGE
              },
              'payment_details' : { // required when loading with view=CHECKOUT
                'currency': rate.currencyCode, // required
                'amount' : rate.totalAfterTax, // required
              }
            });
            if(this._data.overview.creditCards) {
              let acceptedCCs = '';
              $.each(this._data.overview.creditCards, (index, cc) => {
                acceptedCCs += ConfigsUtils.getCardValue(cc.ccid) + ',';
              });
              if (acceptedCCs.indexOf('tr') < 0 && acceptedCCs.indexOf('vi') >= 0) {
                acceptedCCs =  ConfigsUtils.getCardValue('tr') + ',' + acceptedCCs;
              }
              window.mycheck.wallet.setAcceptedCreditCards(acceptedCCs.split(','));
            }
          }
          EventHandler.send('tokenRetrieved');
        },
        error: (res) => {
          EventHandler.triggerEvent('brands-error', res);
        },
        complete: (res) => {
          if(res.status !== 200){
            $.ajax({
              url: this.MyCheckConfig.MyCheckRefreshTokenUrl,
              type: 'POST',
              contentType: 'application/json',
              data: JSON.stringify({
                "publishableKey": pub_key
              }),
              dataType: 'json',
              success: (res) => {
                window.mycheck.auth.connect({
                  publishableKey: pub_key,
                  refreshToken: res.refreshToken
                });

                window.mycheck.wallet.wallet.init({
                  'settings': {
                    'locale' : myCheckLang,//optional-default is en (language code in ISO 639-1)
                    'merchant_id':'', //required
                    'view': 'CHECKOUT' //required - can be either CHECKOUT or MANAGE
                  },
                  'payment_details' : { // required when loading with view=CHECKOUT
                    'currency': rate.currencyCode, // required
                    'amount' : rate.totalAfterTax, // required
                  }
                });
                if(this._data.overview.creditCards) {
                  let acceptedCCs = '';
                  $.each(this._data.overview.creditCards, (index, cc) => {
                    acceptedCCs += ConfigsUtils.getCardValue(cc.ccid) + ',';
                  });
                  if (acceptedCCs.indexOf('tr') < 0 && acceptedCCs.indexOf('vi') >= 0) {
                    acceptedCCs =  ConfigsUtils.getCardValue('tr') + ',' + acceptedCCs;
                  }
                  //console.log('acceptedCCs',acceptedCCs);
                  window.mycheck.wallet.setAcceptedCreditCards(acceptedCCs.split(','));
                }
              },
              error: (res) => {
                EventHandler.triggerEvent('brands-error', res);
              }
            });
          }
        },
      });
    } else {
      $.ajax({
        url: this.MyCheckConfig.MyCheckRefreshTokenUrl,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          "publishableKey": pub_key
        }),
        dataType: 'json',
        success: (res) => {
          console.log({
            publishableKey: pub_key,
            refreshToken: res.refreshToken
          });

          window.mycheck.auth.connect({
            publishableKey: pub_key,
            refreshToken: res.refreshToken
          });

          window.mycheck.wallet.wallet.init({
            'settings': {
              'locale' : myCheckLang,//optional-default is en (language code in ISO 639-1)
              'merchant_id':'', //required
              'view': 'CHECKOUT' //required - can be either CHECKOUT or MANAGE
            },
            'payment_details' : { // required when loading with view=CHECKOUT
              'currency': rate.currencyCode, // required
              'amount' : rate.totalAfterTax, // required
            }
          });
          if (this._data.overview.creditCards) {
            let acceptedCCs = '';
            $.each(this._data.overview.creditCards, (index, cc) => {
              acceptedCCs += ConfigsUtils.getCardValue(cc.ccid) + ',';
            });
            if (acceptedCCs.indexOf('tr') < 0 && acceptedCCs.indexOf('vi') >= 0) {
              acceptedCCs =  ConfigsUtils.getCardValue('tr') + ',' + acceptedCCs;
            }
            window.mycheck.wallet.setAcceptedCreditCards(acceptedCCs.split(','));
          }
        },
        error: (res) => {
          EventHandler.triggerEvent('brands-error', res);
        }
      });
    }

    window.mycheck.wallet.on("addCardFormReady",() =>{
      console.log('form is ready');
    });

    window.mycheck.wallet.on("checkoutReady", (status, paymentDetails)=>{
      if(status){
        window.mycheck.wallet.getPaymentMethod().then(res=>{
          $('#isTokenBooking').val(true);
          $('#ccToken').val(res.cc_token);
          if(res.cc_token){
            $('#isTokenBooking').val(true);
          } else {
            $('#isTokenBooking').val(false);
          }
        });
      }
    });
    window.mycheck.wallet.on("ready",() =>{
      $('#mycheck-wallet-v3').css({'max-height':'none'});
    });

    if(!isGoFreeRate(req_rate_plan) &&  !isSMORate(req_rate_plan) && !isBNPLEX(this._data.overview)){
      BNPLUplift.handleConfig({
        currencyCode: rate.currencyCode,
        subtotal: rate.totalAfterTax,
        noOfRooms:this._params.rooms,
        room: this._data.rate,
        bookingconfirm : (()=>this.performBooking()),
        propertyName: getName(),
        checkinDate: formatDateForUplift(this._params.checkInDate),
        checkoutDate: formatDateForUplift(this._params.checkOutDate),
        checkout: true
      });
    } else {
      BNPLUplift.trackBNPLBrandStatus(rate.currencyCode);
    }

  }

  noMatchTrigger() {
    $('.booking-wrapper,.hotel-info-section,.cancel-policy-pop-link').hide();
    let nomatchmsg = 'We apologize that the room is no longer available. <br>Please click on change search to enjoy a different selection.';
    $('.booking-wrapper').html(nomatchmsg);
  }

  displayRoomTaxInfo() {
    $('.room-tax-popup-container').modal('show');
  }

  performPreBooking() {
    $_PAGE_.addClass('loading');
    //Validate CC for manually entered CC, skip for visa and master checkout
    let paymentMethod = document.querySelector('input[name="paymentmethodradio"]:checked');
    if(paymentMethod && paymentMethod.value){
      if(paymentMethod.value == 'uplift-monthly-payment'){
        window.Uplift.Payments.getToken();
        setTimeout(() => {
          if($('#upToken').val() !== ''){
            this.performBooking();
          }else{
            scrollToAnimated($('#up-pay-monthly-container').offset().top - getElTrueHeight($('.globalnavigation')) - 20);
            $_PAGE_.removeClass('loading');
          }
        }, 20000);


      }else if(paymentMethod.value == 'card-payment'){
        if (($('#directBillNumber').length > 0 && $('#directBillNumber').val().length == 8) || (isVisaCheckout === true) || ($('#ccToken').val() !== '') || (isGoFreeRate(req_rate_plan)) || (this._isInstantRateMatch)) {
          this.performBooking();
        }else{
          this.validateCCInfo(() => {
            this.performBooking();
          });
        }
      }else if(paymentMethod.value == 'b-card-payment'){
        if (($('#directBillNumber').length > 0 && $('#directBillNumber').val().length == 8) || (isVisaCheckout === true) || ($('#ccToken').val() !== '') || (isGoFreeRate(req_rate_plan)) || (this._isInstantRateMatch)) {
          this.performBooking();
        }else{
          this.validateCCInfo(() => {
            this.performBooking();
          });
        }
      }
    }else{
      if (($('#directBillNumber').length > 0 && $('#directBillNumber').val().length == 8) || (isVisaCheckout === true) || ($('#ccToken').val() !== '') || (isGoFreeRate(req_rate_plan)) || (this._isInstantRateMatch)) {
        this.performBooking();
      }else{
        this.validateCCInfo(() => {
          this.performBooking();
        });
      }
    }
  }



  getmycheckPaymentMethod(callback){
    window.mycheck.wallet.getPaymentMethod().then(res=>{
      if(!isGoFreeRate(req_rate_plan) && !this._isInstantRateMatch && res.cc_token){
        $('#isTokenBooking').val(true);
        $('#ccToken').val(res.cc_token);
        if(res.cc_token){
          $('#isTokenBooking').val(true);
        } else {
          $('#isTokenBooking').val(false);
        }
        callback();
      }else{
        if(!isGoFreeRate(req_rate_plan) && !this._isInstantRateMatch){
          this.getmycheckPaymentMethod(callback);
        } else {
          $('#isTokenBooking').val(false);
          $('#ccToken').val('');
          $_PAGE_.removeClass('loading');
        }
      }
    });
  }

  validateCCInfo(callback) {
    if(ConfigsUtils.getMychecktV3Enabled()){
      window.mycheck.wallet.submitCardForm().then(res=>{
        if(res === CARD_SUBMITTING){
          window.mycheck.wallet.getPaymentMethod().then(res=>{
            if(!isGoFreeRate(req_rate_plan) && !this._isInstantRateMatch && res.cc_token){
              $('#isTokenBooking').val(true);
              $('#ccToken').val(res.cc_token);
              if(res.cc_token){
                $('#isTokenBooking').val(true);
              } else {
                $('#isTokenBooking').val(false);
              }
              callback();
            }else{
              this.getmycheckPaymentMethod(callback);
            }
          });
        } else if(res === CHECKOUT_READY) {
          window.mycheck.wallet.getPaymentMethod().then(res=>{
            if(!isGoFreeRate(req_rate_plan) && !this._isInstantRateMatch && res.cc_token){
              $('#isTokenBooking').val(true);
              $('#ccToken').val(res.cc_token);
              if(res.cc_token){
                $('#isTokenBooking').val(true);
              } else {
                $('#isTokenBooking').val(false);
              }
              callback();
            } else {
              $('#isTokenBooking').val(false);

              $('#ccToken').val('');
              $_PAGE_.removeClass('loading');
            }
          });
        }else {
          $('#isTokenBooking').val(false);
          $('#mycheck-wallet-v3').css('box-shadow', '0 0 5px 2px #e70404')
            .attr('aria-invalid', true);
          scrollToAnimated($('#mycheck-wallet-v3').offset().top - getElTrueHeight($('.globalnavigation')) - 20);
          $_PAGE_.removeClass('loading');
          console.error('MyCheck token is empty: ' + ($('#ccToken').val() === ''));
          this.trackPaymentOptionsInteraction('MyCheck error: ' + res);
          return false;
        }
      });
    }
  }

  performBooking() {
    let sp = this._params,
      pdata = this._data.overview,
      rate = this._data.rate,
      nights = getNumDays(sp.checkInDate, sp.checkOutDate),
      totPoints = rate.pacRatePlan ? this._data.bookingPoints * nights : this._data.bookingPoints;

    // If form has invalid fields, do not submit
    if (this._bookTrigger || $('form.booking-form .parsley-error').length > 0) {
      $_PAGE_.removeClass('loading');
      return false;
    }
    // Parsley validation can't check CC info. Check it manually here.
    if ((!(document.querySelector('input[name="paymentmethodradio"]:checked').value == 'uplift-monthly-payment')) && ($('#directBillNumber').length > 0 && $('#directBillNumber').val().length != 8) && $('#ccToken').val() === '' && $('#giftCardAmount').val() < $('#totalBeforeTax').val() && !(rate.fnsRatePlan) && !this._isInstantRateMatch) {
      $('.myc-wallet').css('box-shadow', '0 0 5px 2px #e70404')
        .attr('aria-invalid', true);
      scrollToAnimated($('.myc-wallet').offset().top - getElTrueHeight($('.globalnavigation')) - 20);
      $_PAGE_.removeClass('loading');
      console.error('MyCheck token is empty while performing booking: ' + ($('#ccToken').val() === ''));
      this.trackPaymentOptionsInteraction('MyCheck error: token is empty while performing booking');
      return false;
    }
    if(document.querySelector('input[name="paymentmethodradio"]:checked').value == 'uplift-monthly-payment' && $('#upToken').val() === ''){
      $('#up-pay-monthly-container').css('box-shadow', '0 0 5px 2px #e70404')
        .attr('aria-invalid', true);
      scrollToAnimated($('#up-pay-monthly-container').offset().top - getElTrueHeight($('.globalnavigation')) - 20);
      $_PAGE_.removeClass('loading');
      this.trackPaymentOptionsInteraction('Uplift error: token is empty');
      return false;
    }else if(document.querySelector('input[name="paymentmethodradio"]:checked').value == 'uplift-monthly-payment'){
      $('#ccToken').val($('#upToken').val());
    }

    this._bookTrigger = true;
    let bookData = serializeObject($('#booking-req-user-form'));
    //setting paymentmethodradio empty for InstantHold
    if(this._isInstantRateMatch){
      bookData.paymentmethodradio = null;
    }
    //CreditDetails will exists only for MyCheck V5 version
    if (bookData.CreditDetails) {
      delete bookData.CreditDetails;
    }

    if(document.querySelector('input[name="paymentmethodradio"]:checked').value == 'uplift-monthly-payment' && $('#ccToken').val() && $('[name="splRequest"]').attr('data-special-requests-BNPL-Prefix')){
      bookData['ccToken'] = $('#upToken').val();
      bookData['splRequest'] = $('[name="splRequest"]').attr('data-special-requests-BNPL-Prefix') + rate.currencyCode + rate.totalAfterTax + ' ' + $('[name="splRequest"]').val();
    }
    if (bookData.customerAddress2) {
      bookData.customerAddress = (bookData.customerAddress + ' ' + bookData.customerAddress2).trim();
    }
    bookData.iata = bookData.iata || window.digitalData.search.searchInfo.iataCode;
    bookData.hotelBrand = overview_brandId;

    bookData.ratePlanCode = req_rate_plan;
    bookData.roomTypeCode = req_room_type;
    if (_isNotNull(req_rate_cc)) {
      bookData.corpCode = req_rate_cc;
    } else {
      bookData.corpCode = sp.corpCode;
    }

    /*** Start: Fix for defect: 64844 ***/
    if (!(rate.pacRatePlan || rate.fnsRatePlan) && sp.groupCode) {
      bookData.groupCode = sp.groupCode;
    }
    if ($('#directBillNumber').length > 0 && $('#directBillNumber').val().length == 8) {
      bookData.dbNo = bookData.directBillNumber;
      bookData.dbPrj = bookData.directBillProjectNumber;
      Analytics.confirmationSetPaymentType('Direct Bill');
      Analytics.confirmationSetDirectBillNumber($('#directBillNumber').val());
    }
    delete bookData.directBillNumber;
    delete bookData.directBillProjectNumber;

    /*** End: Fix for defect: 64844 ***/
    bookData.checkInDate = sp.checkInDate.replace(/-/g, '/');
    bookData.checkOutDate = sp.checkOutDate.replace(/-/g, '/');
    bookData.noOfRooms = sp.rooms;
    bookData.noOfAdults = sp.adults;
    bookData.noOfChildren = sp.children;
    bookData.childAge = sp.childAge;
    bookData.teens = 0;
    bookData.propertyID = overview_brandId + overview_propertyId;
    bookData.hotelId = overview_propertyId;
    bookData.enroll = ($('.booking-form input[name="enroll"]').is(':checked')) ? 'enroll' : 'next';
    if (this._isInstantRateMatch) {
      bookData.enroll = 'next';
      bookData.customerEmail = bookData.customerEmail || '';
      bookData.customerAddress = bookData.customerAddress || '';
      bookData.customerCity = bookData.customerCity || '';
      bookData.customerPostalCode = bookData.customerPostalCode || '';
    }
    bookData.wrLoginFlag = (bookData.enroll === 'enroll') ? 'Y' : 'N';
    if (overview_brandId === 'WY') {
      bookData.hotelId = overview_orsId;
    }
    Analytics.confirmationSetReward($('.booking-form input[name="enroll"]').is(':checked'));
    Analytics.analyticsConfirmSubscribeEstatements($('input#wr-checkbox-mktg').is(':checked'));
    Analytics.analyticsConfirmWyndhamRewardsPartnerOptIn($('input#wr-tparties-checkbox-mktg').is(':checked'));
    Analytics.confirmationSetWynOptIn($('input[name="partner-2"]').is(':checked'));
    Analytics.confirmationSetBrandOptIn($('input#whg-checkbox-mktg').is(':checked'));
    Analytics.analyticsConfirmBrandPartnerOptIn($('input#whg-tparties-checkbox-mktg').is(':checked'));
    if (rate.fnsRatePlan) {
      Analytics.confirmationSetPaymentType('Go Free');
    }
    bookData.customerCCName = bookData.customerFirstName + ' ' + bookData.customerLastName;
    bookData.channelId = 'responsive';
    bookData.optIn = ($('input[name="whg-checkbox-mktg"]').is(':checked')) ? 'Y' : 'N';
    bookData.optInOther = ($('input[name="whg-tparties-checkbox-mktg"]').is(':checked')) ? 'Y' : 'N';
    bookData.optInOffers = ($('input[name="wr-checkbox-mktg"]').is(':checked')) ? 'Y' : 'N';
    bookData.optInMarketing = ($('input[name="wr-tparties-checkbox-mktg"]').is(':checked')) ? 'Y' : 'N';
    bookData.agreeToTerms = ($('input[name="privacyterms"]').is(':checked')) ? 1 : 0; //(bookData.agreeToTerms === 'on') ? 1 : 0;
    bookData.isTokenBooking = $('#isTokenBooking').val();
    bookData.isGiftCardBooking = $('#isGiftCardBooking').val();
    if (!this._isInstantRateMatch) {
      let stateVal = bookData.customerStateCode.split('_');
      if (stateVal.length === 2) {
        bookData.customerStateCode = stateVal[1];
      }
    }
    //Set values for FNS booking
    if (rate.pacRatePlan || rate.fnsRatePlan) {
      let mo = UserHandler.getWRObject();
      bookData.propertyTier = pdata.tierNum;
      bookData.propertyTierPoints = this._data.bookingPoints;
      bookData.customerWRN = mo.wrNo;
      if (rate.pacRatePlan) {
        bookData.pac = 'Y';
      } else {
        bookData.wynFNSChecked = 'Y';
      }
    } else {
      //NON FNS functionalities
      bookData.customerWRN = bookData.customerWRNumber;
      if (UserHandler.isWRLoggedIn()) {
        bookData.customerWRN = UserHandler.getWRObject().wrNo;
      }
    }
    if (!UserHandler.isWRLoggedIn()) {
      if (bookData.customerWRN !== '') {
        Analytics.setEnteredRewardsID(bookData.customerWRN);
      } else {
        Analytics.setEnteredRewardsID('not entered');
      }
    }

    //Bonus rate plan auto enroll for domestic users
    if (isBonusRP(req_rate_plan) && bookData.customerCountryCode && bookData.customerCountryCode.match(/US|CA/)) {
      bookData.wrLoginFlag = 'Y';
    }

    // Channel mapping
    bookData.srcBrand = brandMap[window.digitalData.page.pageInfo.referringBrand] || brandMap[CookieHandler.readCookie(Analytics.referrerCookieName)] || $('#referringBrand').val() || (['WY', 'FE'].indexOf(overview_brandId) > -1 ? overview_brandTier : overview_brandId);

    if (bookData.srcBrand === undefined) {
      bookData.srcBrand = 'ALL';
      bookData.refBrand = 'ALL';
    } else {
      bookData.srcBrand = bookData.srcBrand.toUpperCase();
      bookData.refBrand = bookData.srcBrand.toUpperCase();
    }

    //DAI-294 | Channel Mapping : Invalid Ids passed to confirmReservation
    if (bookData.srcBrand.indexOf('?') !== -1) {
      let brandsrc = bookData.srcBrand.split('?')[0];
      (brandMap[brandsrc] > 0) ? bookData.srcBrand = brandsrc : getBrand();
    } else {
      (brandMap[bookData.srcBrand] > 0) ? bookData.srcBrand = bookData.srcBrand.toUpperCase() : getBrand();
    }

    if ($('#referringTier').val()) {
      bookData.brandTier = ($('#referringTier').val() || getBrandTier()).toUpperCase();
    } else if (getBrandTier() !== '') {
      bookData.brandTier = getBrandTier().toUpperCase();
    }

    bookData.language = _LOCALE_;

    // DAI-1413: SMS Updates
    bookData['Mobile_Number_Indicator'] = $('input[value="phone-mobile"]').is(':checked') || '';
    bookData['Other_Phone_Number_Indicator'] = $('input[value="phone-other"]').is(':checked') || '';
    bookData['SMS_Opt_In_Status'] = $('input[id="mobile-sms-updates"]').is(':checked') || false;

    // DAI-37296: phone value
    const phoneNumber = $('input[name="customerPhone"]').val();
    bookData['Guest_Phone_Number'] = PhoneInput.getPhoneNumberWithCountryCode(phoneNumber, 0);

    // DAI-35908: SMS allow skip sending CP_WR_SMS when condition is met
    const marketingSmsUpdates = $('input[id="marketing-sms-updates"]');
    if (!marketingSmsUpdates.data('skip')) {
      bookData['CP_WR_SMS'] = marketingSmsUpdates.is(':checked') || false;
    }

    // Caesars Rewards
    const caesarsEnrollFlag = $('input[name="caesarsEnrollFlag"]').is(':checked');
    if(caesarsEnrollFlag) {
      bookData['caesarsEnrollFlag'] = caesarsEnrollFlag;
      const dobField =  $('#dateOfBirth');
      const dateFormat = getDateFormat(_LOCALE_);

      let dobNormalized = '';

      //Shortcut if already in that format
      if('MM/dd/yyyy' === dateFormat) {
        dobNormalized = dobField.val();
      } else {
        const dobLocal = DateTime.fromFormat(dobField.val(), dateFormat);
        dobNormalized = dobLocal.toFormat('MM/dd/yyyy');
      }

      bookData['dateOfBirth'] = dobNormalized || '';
      sessionStorage.setItem('caesarsEnrollFlag', caesarsEnrollFlag);
    }

    let addedPackages = getSessionStorage('added-package') || [];
    if(Object.keys(addedPackages).length > 0){
      let serviceInventoryCodes = [];
      let quantities = [];
      $.each(addedPackages, (counter, addedpackage)=>{
        serviceInventoryCodes.push(addedpackage.packageAddons.serviceInventoryCode);
        quantities.push(addedpackage.qty);
      });
      bookData['serviceInventoryCodes'] = serviceInventoryCodes.join('|');
      bookData['quantities'] = quantities.join('|');
    }

    // conditional check for translated pages; if we are loading a partially translated page,
    // we are informing Services so the confirmation email will suppress untranslated fields
    if (typeof window.is_fully_translated !== 'undefined') {
      bookData.suppressRoomDesc = window.is_fully_translated === 'null' ? true : !window.is_fully_translated;
    }

    callService('confirmReservation', bookData, 'POST').then((res) => {
      if (res && (res.status === 'OK') && ((res.booking && res.booking.confirmation) || (res.modify && res.modify.confirmation))) {
        //TEMPOPARY enable rebook
        this._bookTrigger = false;

        let confNumbers = '',
          itineraryNumber = '',
          dateRateMap = (_isNotNull(res.booking.rates.RoomRate.Rates.Rate.Base.dateRateMap)) ? (JSON.stringify(res.booking.rates.RoomRate.Rates.Rate.Base.dateRateMap)) : '',
          dateTaxAmountMap = (_isNotNull(res.booking.rates.RoomRate.Rates.Rate.Base.dateTaxAmountMap)) ? (JSON.stringify(res.booking.rates.RoomRate.Rates.Rate.Base.dateTaxAmountMap)) : '',
          dateRateFeeMap = (_isNotNull(res.booking.rates.RoomRate.Rates.Rate.Base.dateRateFeeMap)) ? (JSON.stringify(res.booking.rates.RoomRate.Rates.Rate.Base.dateRateFeeMap)) : '',
          bookData = ReservationHandler.getBookData();


        if (_isNotNull(res.roomDetails)) {
          let confNumberArray = res.roomDetails;
          for (let i = 0; i < confNumberArray.length; i++) {
            confNumbers += confNumberArray[i].confirmation;
            if (i !== (confNumberArray.length - 1)) {
              confNumbers += ',';
            }
          }
        } else {
          confNumbers = res.booking.confirmation;
        }
        if (_isNotNull(res.booking.itineraryNumber)) {
          itineraryNumber = res.booking.itineraryNumber;
        } else {
          itineraryNumber = res.booking.confirmation;
        }
        let confirmNum = '',
          dtmParams = '';
        if (res.booking && res.booking.confirmation) {
          confirmNum = res.booking.confirmation;
          dtmParams = this.getDTMParamDetails(res.booking);
        } else if (res.modify && res.modify.confirmation) {
          confirmNum = res.modify.confirmation;
          dtmParams = this.getDTMParamDetails(res.modify);
        }
        /*if(document.querySelector('input[name="paymentmethodradio"]:checked').value == 'uplift-monthly-payment' && $('#ccToken').val()){
          window.Uplift.Analytics.orderResponse({
            //user’s mode of payment
            "mode_of_payment": 'pay monthly',
            //pass the confirmation number
            "order_id": confNumbers
          });
          window.Uplift.Payments.confirm(confNumbers);
        }*/
        let shouldHideProvinceCountry = HideProvinceCountryConfig.getHideProvinceCountryConfig(getSessionCriteria().propertyId);
        let totalAfterTaxPackage = parseFloat(res.booking.amountAfterTax)
        + (res.booking.services ? res.booking.services.map((service)=> service.prices)
          .flat(1)
          .map((price)=>price.amountAfterTax && parseFloat(price.amountAfterTax))
          .reduce((amount,total)=>amount + total,0) : 0);

        let aemDataObj = {
          // Params from legacy code
          ratePlan: req_rate_plan,
          roomType: req_room_type,
          rateCorpCode: req_rate_cc,
          propertyId: overview_propertyId,
          orsId: overview_orsId,
          brandId: overview_brandId,
          referringBrand: bookData.refBrand,
          brandTier: overview_brandTier,
          roomImg: req_roomImg,
          redirect: confirmation_redirect_url,
          confirmationNum: confirmNum,
          dtmParamDetails: dtmParams,
          // AEM post rewrite
          propertyName: getName(),
          propertyAddress: shouldHideProvinceCountry ? getAddressWithoutProvinceCountry() : getAddress(),
          propertyPhone: (_LANGUAGE_ !== 'en' ? getUNAP('phone') : $('.res-summary-container .property-number').text()),
          confirmationNumber: res.booking.confirmation,
          itineraryNumber: itineraryNumber,
          confirmationNumbers: confNumbers,
          firstName: res.booking.userInfo.firstName,
          lastName: res.booking.userInfo.lastName,
          roomDescriptionLong: this._data.room.description,
          rooms: res.booking.nRooms,
          nights: nights,
          adults: sp.adults,
          children: sp.children,
          checkInDateFormatted: formatDateForPrinting(res.booking.checkinDate, 'weekdayCompact'),
          checkOutDateFormatted: formatDateForPrinting(res.booking.checkoutDate, 'weekdayCompact'),
          checkInDate: res.booking.checkinDate,
          checkOutDate: res.booking.checkoutDate,
          ratePlanName: res.booking.ratePlan,
          checkInTime: pdata.hotelPolicies.checkInTime ? formatTimeShow(pdata.hotelPolicies.checkInTime) : '',
          checkOutTime: pdata.hotelPolicies.checkOutTime ? formatTimeShow(pdata.hotelPolicies.checkOutTime) : '',
          cancellable: res.booking.cancellable,
          modifiable: res.booking.modifiable,
          roomDescriptionShort: res.booking.roomName,
          subtotal: handlePostSubtotal(res.booking.totalBeforeTax, $('#giftCardAmount').val(), res.booking.currencyCode, req_rate_plan),
          taxTotal: !(rate.fnsRatePlan) ? getCurrencyMapping(res.booking.currencyCode, true) + parseFloat(res.booking.tax).toFixed(2) + ' ' + res.booking.currencyCode : null,
          taxList: JSON.stringify(this._data.rate.taxList),
          totalCash: !(rate.fnsRatePlan) ? getCurrencyMapping(res.booking.currencyCode, true) + parseFloat(totalAfterTaxPackage).toFixed(2) + ' ' + res.booking.currencyCode : null,
          totalPoints: formatNumber(totPoints) || '',
          cancellationPolicy: res.booking.rateCancel,
          depositPolicy: res.booking.depositPolicy,
          currencyCode: res.booking.currencyCode,
          rateCode: req_rate_plan,
          roomCode: req_room_type,
          currencySymbol: res.booking.currencySymbol,
          giftCard: ($('#giftCardAmount').val() ? getCurrencyMapping(res.booking.currencyCode, true) + parseFloat($('#giftCardAmount').val()).toFixed(2) + ' ' + res.booking.currencyCode : null),
          cancelled: null,
          email: res.booking.userInfo.email,
          comments: (res.booking.userInfo.comments && res.booking.userInfo.comments.includes('BNPL')) ? (res.booking.userInfo.comments).split(' ').slice(1)
            .join(' ') : res.booking.userInfo.comments,
          customerCity: res.booking.userInfo.city,
          customerState: res.booking.userInfo.state,
          customerCountry: res.booking.userInfo.country,
          customerZip: res.booking.userInfo.postalCode,
          customerPhoneNum: res.booking.userInfo.contactPhone,
          customerAddress: res.booking.userInfo.street,
          directBillNumber: res.booking.directBill,
          directBillProjectNumber: res.booking.directBillProject,
          directBillVCCExpiryDate: res.booking.directBillVCCExpireDate,
          dateRateMap: dateRateMap, //daily breakup of rate for more than two days of booking
          dateTaxAmountMap : dateTaxAmountMap,
          dateRateFeeMap: dateRateFeeMap,
          packages: this.packagesFilter(res.booking)
        };

        if(document.querySelector('input[name="paymentmethodradio"]:checked').value == 'uplift-monthly-payment'){
          aemDataObj.bnplComments = 'BNPL';
        }

        //DAI-16776
        sessionStorage.setItem('lcp', true);
        ReservationHandler.postReservationDataToAEM(aemDataObj, $('#confirmationSubmit'), 'book');

        // Find the active payment method radio button within the payment-method-select container
        const activePaymentMethodRadioButton = document.querySelector('.payment-method-select input[name="radio"]:checked');

        // Check if the radio button exists before accessing its value
        if (activePaymentMethodRadioButton) {
          let activePaymentMethod = activePaymentMethodRadioButton.value;

          if (activePaymentMethod === 'uplift-monthly-payment') {
            // Fire BNPL event
            BnplAnalytics.satelliteTracker('validateBnplAndTCSubmit');
          }
        }

      } else {
        this._bookTrigger = false;

        let errmsg = '';
        if (res.errmsg) {
          errmsg = res.errmsg;
        } else if (res.responseJSON && res.responseJSON.errmsg) {
          errmsg = res.responseJSON.errmsg;
        } else {
          errmsg = 'Our reservation system is undergoing maintainence.';
        }
        if ((res.errorCode == '322' || res.responseJSON.errorCode == '322') && errmsg.length > 0) {
          let firstmsg = '',
            linkmsg = '';
          EventHandler.triggerEvent('brands-error', res);
          if (errmsg.indexOf('. ') > -1) {
            firstmsg = errmsg.slice(0, errmsg.indexOf('. ') + 2);
            linkmsg = errmsg.slice(errmsg.indexOf('. ') + 2);
            if (roomrate_redirect_url && overview_propertyId) {
              let rrUrl = roomrate_redirect_url;
              errmsg = firstmsg + `<a class="rrlink" data-hotelid="${overview_propertyId}" data-href="${rrUrl}" href="#">${linkmsg}</a>`;
            }
          } else {
            if (roomrate_redirect_url && overview_propertyId) {
              let rrUrl = roomrate_redirect_url;
              errmsg = `<a class="rrlink" data-hotelid="${overview_propertyId}" data-href="${rrUrl}" href="#">${errmsg}</a>`;
            }
          }
        }
        $('.booking-error').html(errmsg)
          .show();
        if ((res.errorCode == '322' || res.responseJSON.errorCode == '322') && errmsg.length > 0) {
          $('.booking-error a').on('click', (e) => {
            let url, href;
            e.preventDefault();
            removeRoomsRates();
            url = buildSearchQuery();
            href = $('.booking-error a').data('href');
            //hotel_id = $('.booking-error a').data('hotelid');
            window.location = href + url;
          });
        }
        scrollToAnimated($('.booking-form').offset().top - getElTrueHeight($('.globalnavigation')));
        $_PAGE_.removeClass('loading');
      }
    });
  }

  trackPaymentOptionsInteraction(paymentErrorMsg) {
    Analytics.resetErrorMessages();
    window.digitalData.error.errorInfo.errorFormMessage = [paymentErrorMsg];
    document.body.dispatchEvent(new CustomEvent('brands-form-error'));
  }

  packagesFilter(data){
    return data.services ? JSON.stringify(Object.values(data.services.reduce((servicesA, servicesB) => {
      if (servicesA[servicesB.comments.title] && servicesA[servicesB.comments.title].comments.title === servicesB.comments.title){
        servicesB.prices[0]['effectiveDate'] = servicesB.startTime;
        servicesA[servicesB.comments.title].prices = [ ...servicesA[servicesB.comments.title].prices, ...servicesB.prices];
        // servicesA[servicesB.comments.title].prices[0].amountAfterTax = parseFloat(servicesA[servicesB.comments.title].prices[0].amountAfterTax) + parseFloat(servicesB.prices[0].amountAfterTax);
        // servicesA[servicesB.comments.title].prices[0].amountBeforeTax = parseFloat(servicesA[servicesB.comments.title].prices[0].amountBeforeTax) + parseFloat(servicesB.prices[0].amountBeforeTax);
        servicesA[servicesB.comments.title].quantity = parseFloat(servicesA[servicesB.comments.title].quantity) + parseFloat(servicesB.quantity);
      }else{
        servicesB.prices[0]['effectiveDate'] = servicesB.startTime;
        servicesA[servicesB.comments.title] = servicesB;
      }
      return servicesA;
    }, []))) : null;
  }

  /**
   * This method is used to form all DTM parameters required for confirmation page.
   */
  getDTMParamDetails(booking) {
    let dtmParam = '',
      totalCost = booking.amountAfterTax,
      roomRate = $('.booking-table span.book-room-rate-avg-before-tax').text()
        .replace(/[^0-9.]/g, ''),
      products = '',
      roomCnt = booking.nRooms,
      nightsCnt = $('.booking-table span.search-nights-cnt').text(),
      currencyCd = booking.currencyCode,
      bookRoomName = booking.roomName,
      isWRLoggedIn = false,
      isLoyalty = false,
      redeemWRPoint = false;
    try {
      isLoyalty = isLoyaltyRP(req_rate_plan);
      isWRLoggedIn = UserHandler.isWRLoggedIn();
      redeemWRPoint = $('#redeemWRPoint').is(':checked');
    } catch (e) {
      console.log('Try catch failed');
    }
    dtmParam += 'eVar8::' + (redeemWRPoint ? 'Y' : 'N');
    try {
      if (currencyCd && 'USD' != currencyCd.toUpperCase()) {
        dtmParam += '##currencyCode::' + currencyCd;
        currencyCd = ' ' + currencyCd;
      } else {
        currencyCd = '';
      }
      roomCnt = roomCnt ? roomCnt : 1;
      nightsCnt = nightsCnt ? nightsCnt : 1;
      if (isLoyalty) {
        dtmParam += '##eVar19::Reward';
        dtmParam += '##eVar22::' + totalCost;
        products = overview_brandId + overview_propertyId + ';' + bookRoomName + ';' + (roomCnt * nightsCnt) + ';;';
      } else {
        products = overview_brandId + overview_propertyId + ';' + bookRoomName + ';' + (roomCnt * nightsCnt) + ';' + totalCost + currencyCd + ';' + roomRate + currencyCd;
      }
      dtmParam += '##products::' + products;
      dtmParam += '##eVar12::' + ((!isWRLoggedIn) ? 'N' : 'Y');
      dtmParam += '##prop3::' + ((!isWRLoggedIn) ? 'Guest' : 'Member');
    } catch (e) {
      console.log('Try catch failed');
    }
    return dtmParam;
  }

  showMarketingOptWR() {
    $('#enroll').on('change', (e) => {
      $(e.currentTarget).is(':checked') ? $('.mktg-wr').show() : $('.mktg-wr').hide();
    });
    $('.terms #checkbox10').on('change', (e) => {
      let $mktgWhg = $('.mktg-whg'),
        isVariantUS = _LOCALE_ === 'en-us',
        isSelectCountryUS = (($('.country select').val() === 'US') || ($('.country select').val() === '') || ($('.country select').val() === null));

      if (isSelectCountryUS && isVariantUS) {
        $mktgWhg.addClass('hide');
        $mktgWhg.find('input[type="checkbox"]').prop('checked', true);
      } else {
        $mktgWhg.removeClass('hide');
        $mktgWhg.find('input[type="checkbox"]').prop('checked', false);
      }
      $(e.currentTarget).is(':checked') ? $mktgWhg.show() : $mktgWhg.hide();
      if ($(e.currentTarget).is(':checked')) {
        if (this._isInstantRateMatch) {
          $mktgWhg.addClass('hide');
          $mktgWhg.hide();
          $mktgWhg.find('input[type="checkbox"]').prop('checked', false);
        }
      }
    });
  }

  populateTermsAndConditions(header, disclaimer) {
    let $header = $(header),
      $disclaimer = $(disclaimer),
      $ps = $disclaimer.find('p');
    // Check that there is more than 1 paragraph if not, there is no reason to hide anything.
    if ($ps.length > 1) {
      // Move first paragraph from disclaimer to the header (visible area)
      $header.prepend($ps.first());
    } else {
      // If we get here that means that we do not have 2 paragraphs then use all the text as disclaimer
      // Remove the arrow so there is no confusion to the user.
      $header.html($disclaimer.html());
      $header.addClass('no-read-more');
    }
  }

  // below functions need to be called right away
  bookingFormPopulateTermsAndConditions() {
    this.populateTermsAndConditions('.terms-legal-bttn .marketing-preferences:not(.china-pipl__description--mobile) .rm-header', '.terms-legal-bttn #whg-read-more-terms');
  }

  removeValidationIfAuthored() {
    // Only validate for validation types with authored error messages.
    $('.booking-form-content input, .booking-form-content select, .booking-form-content textarea').each((index, el) => {
      let $el = $(el);
      if (!$el.data('parsley-required-message')) {
        $el.removeAttr('required');
      }
      if (!$el.data('parsley-pattern-message')) {
        $el.removeAttr('data-parsley-pattern');
      }
      if (!$el.data('parsley-minlength-message')) {
        $el.removeAttr('data-parsley-minlength');
      }
      if (!$el.data('parsley-equalto-message')) {
        $el.removeAttr('data-parsley-equalto');
      }
      if (!$el.data('parsley-postal-code-message')) {
        $el.removeAttr('data-parsley-postal-code');
      }
    });
  }

  onReadMoreCollapse() {
    $('#wr-read-more, #whg-read-more-terms').on('hide.bs.collapse show.bs.collapse', (e) => {
      $(e.currentTarget).siblings('.rm-header')
        .attr('aria-expanded', $(e.currentTarget).attr('aria-expanded'));
    });
  }

  onGetCriteriaSuccess() {
    if ($('.booking-form-content, .contact-form, .email-preference-form').length) {
      EventHandler.one(EventHandler.criteria.init, () => {
        window.digitalData.search.updateSearchData = false;
        Analytics.handleSearchAnalytics();
      });
    }
  }

  onFullProfileFetched() {
    if ($('.booking-form-content, .contact-form, .email-preference-form').length) {
      EventHandler.one(EventHandler.fullProfile.fetched, () => {
        populateForm(UserHandler.getWRUserInfo());
        if (UserHandler.isWRLoggedIn()) {
          $('.wyndham-rewards .checkbox').remove();
        }
      });
    }

    if (this.isCaesarsBrand()) {
      EventHandler.one(EventHandler.fullProfile.fetched, () => {
        const user = UserHandler.getWRUserInfo();
        if (!('caesarsMemberNumber' in user)) {
          this.showCaesarsRewardsSignup(false);
        }
      });

    }
  }

  onBookingFormSubmit() {
    $('form.booking-form').submit((e) => {
      e.preventDefault();
      if ($('button.confirm-booking').hasClass('disabled-booking')) {
        //
      } else {
        this.performPreBooking();
      }
      return false;
    });
  }

  onCCPromoClick() {
    let ccPromoButton = $('.btn-secondary-credit-card');
  
    if (ccPromoButton.length > 0 && $('.promo-col-cta.credit-card').css('display') === 'block') {
      ccPromoButton.click(() => {
        let barclayBrandId = (overview_brandTier || overview_brandId || brand_id).toLowerCase();
        let queryString = '/content/whg-ecomm-responsive/' + _LOCALE_ + '/' + barclayBrandId + '/home.barclay.html';

        queryString += '?brandId=' + (overview_brandId || brand_id).toLowerCase();
        if (overview_brandTier) {
          queryString += '&brandTier=' + overview_brandTier.toLowerCase();
        }
        this.preScreenShowed = true;

        if (!UserHandler.isWRLoggedIn() || (UserHandler.isWRLoggedIn() && this.refresh_token !== '')) {
          this.initBarclayWin(queryString);
        } else {
          // wait until token is avaliable before initiating barclays window popup
          EventHandler.one('tokenRetrieved', () => {
            this.initBarclayWin(queryString);
          });
        }
      });
    }
  }

  initBarclayWin(url) {
    let winbarclay = window.open(url, 'barclaywinpop', 'height=620,width=815,location=0,scrollbars=1');
    if (UserHandler.isWRLoggedIn() && window.digitalData.user) {
      let userInfo = UserHandler.getWRUserInfo();
      let profileInfo = {profile: userInfo};
      winbarclay.digitalData = {user: profileInfo};
    }
  }

  onDirectBillLinkClick() {
    let directRoom = this._params;
    let directBill = $('.direct-bill-link');
    directBill.click((e) => {
      e.preventDefault();
      $('#direct-bill-body').show();
      if(_isNotNull(MaxAllowedRoomConfig.getMaximumAllowedRoomCount())){
        directRoom.rooms > Number(MaxAllowedRoomConfig.getMaximumAllowedRoomCount()) ? $('.direct-rooms-error-message').removeClass('hide') : $('.direct-bill.dropdown.input-field-container').removeClass('hide');
      }else{
        $('.direct-bill.dropdown.input-field-container').removeClass('hide');
      }
      $('.direct-bill-label.plus-sign').removeClass('plus-sign');
    });
  }

  validateDirectBillNumber() {
    window.Parsley.addValidator('wyndirectid', {
      requirementType: 'string',
      validateString(value, requirements) {
        return value.toUpperCase().startsWith('WD');
      },
      priority: 300
    });
  }

  initStorageListener() {
    const barclaysKey = 'barclays';
    EventHandler.on(barclaysKey, (e, data) => this.initBarclaysCallback(data));
    window.onstorage = function(eventStorage) {
      const tokenFlag = eventStorage.newValue; // previous value at e.oldValue
      if (eventStorage.key === barclaysKey && tokenFlag !== null) {  // listen to only when a new local storage item is set, which populates newvalue
        if (tokenFlag) { // card added to MyCheck Wallet
          EventHandler.send('barclays', null, tokenFlag);
        }
      }
    };
  }

  initBarclaysCallback(data) {
    let pubKey = $('.payment-method__form').data('pubkey');
    let objRate = this._data.rate;
    if (UserHandler.isWRLoggedIn()) {
      this.loadMyCheckV3Script(objRate, pubKey);
    }
  }

  ifRateDetailsLinkAuthored() {
    if (exists($('.rate-details-link-authored'))) {
      $('.rate-details-link-authored').attr('data-toggle', 'modal')
        .attr('data-target', '#rateSummaryDetail');
    }

    // Dynamic changing of state field based on country chosen
    $('.country select').change((e) => {
      let stateSelect = $('.state-province select');
      let country = $(e.currentTarget).val();
      countryChange(stateSelect, country);
      this.checkCaesarsState($(stateSelect).val());
    });

    $('.state-province select').change((e) => {
      let countrySelect = $('.country select');
      let state = $(e.currentTarget).val();
      stateChange(countrySelect, state);
      this.checkCaesarsState(state);
    });

    $('.group-country select').change((e) => {
      let stateSelect = $('.group-state-province select');
      let country = $(e.currentTarget).val();
      countryChange(stateSelect, country);
      this.checkCaesarsState($(stateSelect).val());
    });

    $('.group-state-province select').change((e) => {
      let countrySelect = $('.group-country select');
      let state = $(e.currentTarget).val();
      stateChange(countrySelect, state);
      this.checkCaesarsState(state);
    });

    $('#enroll').is(':checked') ? $('.mktg-wr').show() : $('.mktg-wr').hide();
    $('.terms #checkbox10').is(':checked') ? $('.mktg-whg').show() : $('.mktg-whg').hide();

    // Populate billing address modal with a copy of the credit card promo if authored.
    if ($('.billing-address').length) {
      if ($('.credit-card-promo').length) {
        $('#preScreenOffer .modal-body').html($('.credit-card-promo').clone());
      } else {
        // Hide the modal if there is no content for it.
        $('#preScreenOffer').remove();
      }
    }
  }

  setMarketingSMSCheckboxVisibility(visible) {
    let communicationsHeader = $('.communications-label'),
      smsUpdates = $('.sms-updates'),
      mobileSmsUpdates = $('.mobile-sms-updates'),
      marketingSmsUpdates = $('.marketing-sms-updates'),
      marketingSmsUpdatesChk = marketingSmsUpdates.find('[type=checkbox]');

    if (!marketingSmsUpdates.length) return;

    if (visible) {
      marketingSmsUpdatesChk.prop('disabled', false);
      marketingSmsUpdates.removeClass('hidden');
    } else {
      marketingSmsUpdatesChk.prop('disabled', true);
      marketingSmsUpdatesChk.prop('checked', false);
      marketingSmsUpdates.addClass('hidden');

      if (UserHandler.isWRLoggedIn()) {
        marketingSmsUpdatesChk.prop('checked', UserHandler.getWRObject().enrolledSMS == 'true');
      }
    }

    if (mobileSmsUpdates.hasClass('hidden') && marketingSmsUpdates.hasClass('hidden')) {
      communicationsHeader.addClass('hidden');
      smsUpdates.addClass('hidden');
    } else {
      communicationsHeader.removeClass('hidden');
      smsUpdates.removeClass('hidden');
    }
  }

  suppressSMSUpdates() {
    const smsPersonalizationConfigs = ConfigsUtils.getSMSPersonalizationDisabled();
    const isLegalLanguageEmpty = $('.marketing-sms-disclaimer');
    let includesLocale = false,
      includesBrand = false,
      includesChannel = false;

    if (smsPersonalizationConfigs) {
      includesLocale = smsPersonalizationConfigs.locales &&
        (smsPersonalizationConfigs.locales.includes(window._LOCALE_) || smsPersonalizationConfigs.locales.includes('all'));
      includesBrand = smsPersonalizationConfigs.brands &&
        (smsPersonalizationConfigs.brands.includes(window.brand_id) || smsPersonalizationConfigs.brands.includes('all'));
      includesChannel = smsPersonalizationConfigs.channels &&
        (smsPersonalizationConfigs.channels.includes(window.pageProperties.systemEnv) || smsPersonalizationConfigs.channels.includes('all'));
    }

    // sms suppressed if config exists or legal language is missing
    if (includesLocale || includesBrand || includesChannel || !isLegalLanguageEmpty.children().length) {
      this.setMarketingSMSCheckboxVisibility(false);
    }
  }

  setSMSCheckboxByAuthentication(eligibleCountries, countryVal) {
    const userInfo = UserHandler.getWRUserInfo(),
      marketingSmsUpdates = $('#marketing-sms-updates');

    if (userInfo) {
      if (userInfo.enrolledSMS == 'true') {
        this.setMarketingSMSCheckboxVisibility(false);
        marketingSmsUpdates.prop('checked', true);
      } else {
        this.setMarketingSMSCheckboxVisibility(eligibleCountries.includes(countryVal));
      }
    } else {
      this.setMarketingSMSCheckboxVisibility(true);
    }
  }

  smsUpdatesByCountry() {
    let country = $('.country select'),
      countryVal = country.val(),
      smsUpdates = $('.sms-updates'),
      smsUpdatesChk,
      mobileSmsUpdates = $('.mobile-sms-updates'),
      smsPersonalizationConfigs = ConfigsUtils.getSMSPersonalizationDisabled(),
      eligibleCountries = [];

    if (smsPersonalizationConfigs) {
      eligibleCountries = smsPersonalizationConfigs.attributes['sms-personalization-eligible-countries'];
    }

    if (smsUpdates.length) {
      smsUpdatesChk = smsUpdates.find('[type=checkbox]');
      if (countryVal === 'US' || this._isInstantRateMatch) {
        smsUpdatesChk.prop('disabled', false);
        mobileSmsUpdates.removeClass('hidden');
      } else {
        smsUpdatesChk.prop('disabled', true);
        smsUpdatesChk.prop('checked', false);
        mobileSmsUpdates.addClass('hidden');
      }

      if (eligibleCountries && UserHandler.isWRLoggedIn) {
        this.setSMSCheckboxByAuthentication(eligibleCountries, countryVal);
      }
    }
    // Phone type reset
    let phoneTypeRadio = $('.phonetype-radio input[name=phonetype-radio]');
    if (phoneTypeRadio.length) {
      phoneTypeRadio
        .filter(function() {
          return $(this).prop('value') === 'phone-mobile';
        })
        .prop('checked', true);
    }
    this.suppressSMSUpdates();
  }

  smsUpdatesInit() {
    // On country change
    $('.country select').change((e) => {
      this.smsUpdatesByCountry();
    });
    // On phone type change
    $('.phonetype-radio input[name=phonetype-radio]').change((e) => {
      let radio = $(e.currentTarget),
        mobileSmsUpdates = $('.mobile-sms-updates'),
        smsUpdatesChk,
        country = $('.country select'),
        countryVal = country && country.length ? country.val() : '',
        smsPersonalizationConfigs = ConfigsUtils.getSMSPersonalizationDisabled(),
        eligibleCountries = [];
      if (
        smsPersonalizationConfigs &&
        smsPersonalizationConfigs.attributes &&
        smsPersonalizationConfigs.attributes['sms-personalization-eligible-countries']
      ) {
        eligibleCountries = smsPersonalizationConfigs.attributes['sms-personalization-eligible-countries'];
      }

      if (mobileSmsUpdates.length && !mobileSmsUpdates.hasClass('.hidden')) {
        smsUpdatesChk = mobileSmsUpdates.find('[type=checkbox]');

        if (radio.prop('value') === 'phone-mobile' && radio.is(':checked')) {
          smsUpdatesChk.prop('disabled', false);
          if (eligibleCountries.includes(countryVal)) {
            if (UserHandler.isWRLoggedIn) {
              this.setSMSCheckboxByAuthentication(eligibleCountries, countryVal);
            } else {
              this.setMarketingSMSCheckboxVisibility(true);
            }
          }
        } else {
          smsUpdatesChk.prop('disabled', true);
          smsUpdatesChk.prop('checked', false);
          this.setMarketingSMSCheckboxVisibility(false);
        }
      }
    });
    // Change sms view depending on country in user profile
    this.smsUpdatesByCountry();
    this.suppressSMSUpdates();
    // Phone handler when phone is different
    const userInfo = UserHandler.getWRUserInfo();
    if (UserHandler.isWRLoggedIn()) {
      const phonenumberInput = $('#customerPhone'),
        marketingSmsUpdates = $('#marketing-sms-updates');

      phonenumberInput.on('change', e => {
        const hasChangedPhone = userInfo ? userInfo.phone != e.currentTarget.value : false;

        if (hasChangedPhone && userInfo.enrolledSMS == 'true') {
          marketingSmsUpdates.data('skip', true);
        } else {
          marketingSmsUpdates.data('skip', false);
        }
      });
    }
  }

  handleCaesarsRewards() {
    if ((this.isCaesarsBrand() && (this._isInstantRateMatch === false)) || Cookie.getCookie('caesarsAmenity')) {
      const dateOfBirthField = $('#dateOfBirth');

      const dateExplain = $('.dob-input label').first();
      const oldExplainText = $(dateExplain).text();
      $(dateExplain).text(this.replaceDateToken(oldExplainText, getDateDisplay(_LOCALE_)));

      const dateOfBirthFieldError = $(dateOfBirthField).attr('data-parsley-caesarsdateformat-message');
      $(dateOfBirthField).attr('data-parsley-caesarsdateformat-message', this.replaceDateToken(dateOfBirthFieldError, getDateDisplay(_LOCALE_)));

      $('.caesars-form').hide();
      $('#caesarsEnrollFlag').click(function() {
        $('.caesars-form').toggle(this.checked);
        if (this.checked) {
          dateOfBirthField.attr('data-parsley-required', 'true');

          window.Parsley.addValidator('underage', {
            requirementType: 'integer',
            validateString(value, requirements) {
              const ofAge = parseInt(requirements, 10);
              const dateFormat = getDateFormat(_LOCALE_);
              const birthDate = DateTime.fromFormat(value, dateFormat);

              const age = Math.floor(Math.abs(birthDate.diffNow('years').years));
              return (age >= ofAge);
            },
            priority: 30
          });

          window.Parsley.addValidator('caesarsdateformat', {
            requirementType: 'string',
            validateString(value, requirements) {
              const dateFormat = getDateFormat(_LOCALE_);
              const dateTime = DateTime.fromFormat(value,dateFormat);
              return dateTime.isValid;
            },
            priority: 300
          });
        } else {
          dateOfBirthField.removeAttr('data-parsley-required');

          window.Parsley.removeValidator('underage');
          window.Parsley.removeValidator('caesarsdateformat');
        }
      });
      this.addLightboxLinks();

      dateOfBirthField.parsley().on('field:success', function() {
        $('#caesars-dob-description').show();
      });
      dateOfBirthField.parsley().on('field:error', function() {
        $('#caesars-dob-description').hide();
      });
      this.decideShowCaesarsRewards();

    } else {
      this.showCaesarsRewardsSignup(false);
    }

  }

  checkCaesarsState(state) {
    if (this.isCaesarsBrand()) {
      if (state === 'US_MO') {
        this.showCaesarsRewardsEnroll(false);
        this.showCaesarsRewardsEnrollProhibit(true);
        // Clear flag and date field
        const dateOfBirthField = $('#dateOfBirth');
        const caesarsEnrollFlag = $('#caesarsEnrollFlag');
        dateOfBirthField.val('');
        dateOfBirthField.removeAttr('data-parsley-required');
        dateOfBirthField.parsley().reset();
        $(caesarsEnrollFlag).prop('checked', false);

      } else {
        this.showCaesarsRewardsEnroll(true);
        this.showCaesarsRewardsEnrollProhibit(false);
      }
    }
  }

  isCaesarsBrand() {
    return (getBrand(true) === 'CE');
  }

  showDobDescription(show) {
    const caesarsDobDescription = $('#caesars-dob-description');
    if (show) {
      caesarsDobDescription.show();
    } else {
      caesarsDobDescription.hide();
    }
  }

  showCaesarsRewardsSignup(show) {
    const caesarsRewards = $('.caesars-rewards');
    if (show) {
      caesarsRewards.css('display','block');
    } else {
      caesarsRewards.css('display','none');
    }
  }

  showCaesarsRewardsEnroll(show) {
    const caesarsRewards = $('.caesars-allow-enroll');
    if (show) {
      caesarsRewards.show();
    } else {
      caesarsRewards.hide();
    }
  }

  showCaesarsRewardsEnrollProhibit(show) {
    const caesarsRewards = $('.caesars-prohibit-enroll');
    if (show) {
      caesarsRewards.show();
    } else {
      caesarsRewards.hide();
    }
  }

  decideShowCaesarsRewards() {
    const user = UserHandler.getWRObject();
    // Check is already a member
    if ((user !== null) && ('caesarsMemberNumber' in user)) {
      this.showCaesarsRewardsSignup(false);
    } else {
      this.showCaesarsRewardsSignup(true);
    }
  }

  addLightboxLinks() {
    $('.lightbox-links a').each(function() {
      const link = $(this).attr('href');
      const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');
      // Only for local links
      if(! RgExp.test(link)) {
        $(this).attr('href', `?lightbox=${link}`);
        $(this).attr('data-lightbox-trigger', '');
        $(this).attr('data-toggle', 'modal');
        $(this).attr('data-remote', 'false');
        $(this).attr('data-target', '#genericLightbox');
        $(this).attr('data-lightbox-path', link);
      }
    });
  }

  replaceDateToken(text, value) {
    return text && text.replace('DATE_FORMAT',value);
  }

  checkCancellationMessage() {
    const cancellationMsg = $('.payment-method__cancellation-message');
    if (cancellationMsg.length > 0) {
      const hasRTE = cancellationMsg.find('.has-rte');
      if (hasRTE.length > 0) {
        const separator = $('.payment-method__separator');

        separator.removeClass('hidden');
        cancellationMsg.removeClass('hidden');
      }
    }
  }

  reduceCCPromoHeaderLetterSpacing(){
    if (brand_id == 'gn'){
      if (document.getElementsByClassName('promo-content')[0] && document.getElementsByClassName('promo-content')[0].getElementsByClassName('heading')[0]){
        if (1400 > window.innerWidth && window.innerWidth >= 1320){
          document.getElementsByClassName('promo-content')[0].getElementsByClassName('heading')[0].style.letterSpacing = '-1px';
        } else if (1320 > window.innerWidth && window.innerWidth >= 1240){
          document.getElementsByClassName('promo-content')[0].getElementsByClassName('heading')[0].style.letterSpacing = '-2px';
        } else if ((1240 > window.innerWidth && window.innerWidth >= 1200) || window.innerWidth == 720){
          document.getElementsByClassName('promo-content')[0].getElementsByClassName('heading')[0].style.letterSpacing = '-3px';
        } else {
          document.getElementsByClassName('promo-content')[0].getElementsByClassName('heading')[0].style.letterSpacing = '';
        }
      }
    }
  }

  handleCCPromoImageSpacing(){
    if (document.getElementsByClassName('credit-card-promo')[0] && document.getElementsByClassName('credit-card-promo')[0].getElementsByClassName('promo-col')[0] && document.getElementsByClassName('credit-card-promo')[0].getElementsByClassName('promo-col')[0].getElementsByClassName('promo-img')[0] && document.getElementsByClassName('credit-card-promo')[0].getElementsByClassName('promo-col')[0].getElementsByClassName('promo-content')[0]){
      if (1400 > window.innerWidth && window.innerWidth >= 1200){
        document.getElementsByClassName('credit-card-promo')[0].getElementsByClassName('promo-col')[0].getElementsByClassName('promo-content')[0].style.marginRight = '-20px';
      } else {
        document.getElementsByClassName('credit-card-promo')[0].getElementsByClassName('promo-col')[0].getElementsByClassName('promo-content')[0].style.marginRight = '0';
      }
      window.addEventListener('resize', () => {
        this.reduceCCPromoHeaderLetterSpacing();
        if (window.innerWidth >= 1400){
          document.getElementsByClassName('credit-card-promo')[0].getElementsByClassName('promo-col')[0].getElementsByClassName('promo-content')[0].style.marginRight = '0';
        } else if (1360 > window.innerWidth && window.innerWidth >= 1200){
          document.getElementsByClassName('credit-card-promo')[0].getElementsByClassName('promo-col')[0].getElementsByClassName('promo-content')[0].style.marginRight = '-20px';
        } else if (1200 > window.innerWidth && window.innerWidth >= 720){
          document.getElementsByClassName('credit-card-promo')[0].getElementsByClassName('promo-col')[0].getElementsByClassName('promo-content')[0].style.marginRight = '0';
        }
      });
    }
  }
}

export default new BookingForm();

// this cookie is only needed in booking page and RR page
if (!$_PAGE_.is('.packages-page') && !$_PAGE_.is('.booking-page') && !$_PAGE_.is('.rooms-rates-page')) {
  CookieHandler.eraseCookie('caesarsAmenity');
}

$(document).ready(function() {
  let iframeHasToken = '';

  $(document).on('iframeStatusChange', function(e, eventInfo) {
    iframeHasToken = eventInfo;
    /*if (document.querySelector('input[name="paymentmethodradio"]:checked').value == 'uplift-monthly-payment') {
      if (this.hasToken) {
        $('button.confirm-booking').removeClass('disabled-booking');
      } else {
        $('button.confirm-booking').addClass('disabled-booking');
      }
    }*/
  });


  $('.open-modal-links').click((e)=>{
    $('.wyn-right-col').toggle();
  });

  if($('#directBillNumber').length > 0){
    $('.payment-method-select-option').show();
    $('#directBillPayment').show();
    $('#direct-bill-body-payment').hide();
    $('.direct-bill-label').hide();
    $('.my-wallet-title').hide();
  }

  $('#booking-req-user-form input[type=text],select,radio').blur((e) =>{
    if (['customerFirstName', 'customerLastName', 'customerPostalCode', 'customerCity',
      'customerCountryCode', 'customerStateCode', 'customerAddress', 'customerPhone', 'customerEmail'].includes(e.target.name) && window.Uplift) {
      window.Uplift.Payments.load(BNPLUplift.buildOrderInfo());
    }
    const paymentMethodRadio = document.querySelector('input[name="paymentmethodradio"]:checked');
    if (paymentMethodRadio && paymentMethodRadio.value == 'uplift-monthly-payment') {
      //Disabled button
      $('button.confirm-booking').addClass('disabled-booking');
    }
  });


  let confirmButton = $('.submit-btn-container > button.confirm-booking');
  let paymentRadioButton = $('.payment-radio-button');
  if($_PAGE_.is('.booking-page')) {
    window.digitalData.confirmation.confirmInfo.paymentType = 'Credit Card';
  }
  paymentRadioButton.change(handleChangeRadioButton);
  function handleChangeRadioButton(e) {
    if(e.target.value === 'uplift-monthly-payment'){
      if(!e.target.classList.contains('disabled')){
        $('#directBillNumber').removeAttr('required');
        $('#directBillNumber').val('');
        $('#uplift-monthly-payment-tab').show();
        $('#card-payment-tab, .card-disclaimer-text').hide();
        $('#rate-summary-uplift-disclaimer').show();
        $('.direct-bill-label').hide();
        $('#direct-bill-body-payment').hide();         //Build orderInfo Object
        confirmButton.text(confirmButton.data('upliftbuttontext'));
        $('.pay-at-hotel.caption').hide();

        if(window.Uplift){
          //Uplift Select
          window.Uplift.Payments.select();
        }
        // update digital data payment method based on the selected method
        window.digitalData.confirmation.confirmInfo.paymentType = 'Uplift Monthly Payments';

        //Disabled button
        if (iframeHasToken) {
          $('button.confirm-booking').removeClass('disabled-booking');
        } else {
          $('button.confirm-booking').addClass('disabled-booking');
        }
      }
    }else if(e.target.value === 'card-payment'){
      $('#directBillNumber').removeAttr('required');
      $('#directBillNumber').val('');
      $('#card-payment-tab, .card-disclaimer-text').show();
      $('#uplift-monthly-payment-tab').hide();
      $('#rate-summary-uplift-disclaimer').hide();
      $('.direct-bill-label').hide();
      $('#direct-bill-body-payment').hide();
      $('.pay-at-hotel.caption').show();
      confirmButton.text(confirmButton.data('buttontext'));


      if(window.Uplift){
        //Uplift Deselect
        window.Uplift.Payments.deselect('full');
        window.Uplift.Analytics.selectPayment('full');
      }
      // update digital data payment method based on the selected method
      window.digitalData.confirmation.confirmInfo.paymentType = 'Credit Card';

      //Disabled button
      $('button.confirm-booking').removeClass('disabled-booking');
    }else if(e.target.value === 'b-card-payment'){
      $('#direct-bill-body').show();
      $('#directBillNumber').attr('required', 'true');
      $('.direct-bill-link').trigger('click');
      $('.direct-bill-label').hide();
      $('#direct-bill-body-payment').show();
      $('#card-payment-tab, .card-disclaimer-text').hide();
      $('#uplift-monthly-payment-tab').hide();
      $('#rate-summary-uplift-disclaimer').hide();
      $('.pay-at-hotel.caption').hide();
      confirmButton.text(confirmButton.data('buttontext'));
      if(window.Uplift){
        //Uplift Deselect
        window.Uplift.Payments.deselect('other');
        window.Uplift.Analytics.selectPayment('other');
      }

      // update digital data payment method based on the selected method
      window.digitalData.confirmation.confirmInfo.paymentType = 'Corporate Accounts';

      //Disabled button
      $('button.confirm-booking').removeClass('disabled-booking');
    }
  }
});
