//** ETag Test **//
import BookNowModal from './book-now-modal.js';
import {
  isDesktopWidth,
  isMobileWidth,
  isTabletWidth,
  getWindowHeight,
  ResizeHandler,
  exists,
  isElementInViewport,
  simpleLightbox,
  getElTrueHeight,
  getElTrueWidth,
  isVisible,
  stickify,
  getCSSPropertyAsInt,
  isHomepageExtendedHeroException
} from '../base/dom-utils.js';
import BNPLUplift from '../components/bnpl-uplift.js';
import Dropdown from './dropdown.js';
import GoogleAPIHandler from '../base/google-api-handler.js';
import {
  getCriteria,
  getSessionCriteria,
  setCriteria,
  getLocationDetails,
  normalizeSearchCriteria,
  prepareBookingBarValues,
  setLocationDetails,
  setIntlLocationInfo,
  getSearchOverview,
  getBrand,
  getBrandTier,
  goToRoomsRates,
  handleNonTextSearch,
  navigateToSearchResults,
  getUrlPathFromLocationDetails
} from '../base/session-handler.js';
import {
  _isNotNull,
  _isNull,
  _isWeakTrue,
  formatDateForBWS,
  formatDateForBWSAvailability,
  formatDateForPrinting,
  formatDateForUplift,
  isBNPLEX,
  getLocaleUrlToken,
  isLoyaltyRP,
  uppercaseFirstLetter,
  _isDateType,
  _isNumber,
  _isWeakFalse,
  getDateFromDateString,
  getNumDays,
  getToday,
  getXDays,
  isPriorDate,
  getHeaderHeight,
  getRetailBannerHeight,
  hasRetailBanner,
  isIE,
  getCurrencyMapping,
  replaceToken,
  once,
  sortObjectList,
  getQueryString,
  getQueryParameter,
  updateQueryParameter,
  CookieHandler,
  callService,
  getName,
  isAllInPriceRegion,
  isiOS,
  EventHandler,
  convertToCurrency,
  getSelectedCurencyCode,
  currencyRate,
  updateCurrencySelectorCode,
  isFeesInclude,
  getUpdatedTPDTypeValue,
  manageSearchfavourite,
  manageFavoriteHotels
} from '../base/utils.js';
import {
  $_BOOKING_BAR_MAIN_,
  $_BOOKING_BAR_MINI_FORM,
  $_BOOKING_BAR_MINI_NAV,
  $_HEADER_,
  $_PAGE_,
  _LOCALE_,
  overview_propertyName,
  overview_propertyId,
  property_city_name,
  ONEDAY,
  scrollEvents,
  transitionEvents,
  countryCodeList,
  brand_id,
  WR_AUTHENTICATED
} from '../base/vars.js';

import BookingConfig from '../base/aem-configs/booking-config.js';

window.wynPerf.mark('wyn-booking-bar-start');
let bookingBarInit = window.booking_bar_init || '0';

/**
 * BBLoad
 * Initializes booking bar after header has been loaded
 */
class BBLoad {
  constructor() {
    EventHandler.on(EventHandler.header.load, () => this.bookingBarLoad());
    ResizeHandler.addResizeEndFn(() => this.postResizeHandler());
    if (isMobileWidth()) {
      this.loadRTPAwarenessModifier();
      this.loadCarouselModifier();
    }
  }
  loadRTPAwarenessModifier() {
    if (exists(".rtp-awareness")) {
      $_BOOKING_BAR_MAIN_.addClass("booking-bar--rtp-awareness");
    }
  }
  loadCarouselModifier() {
    if (!exists(".promo-banner") && !exists(".rtp-awareness")) {
      $('.pll-image-container-placeholder').addClass('pll-image-container-placeholder--no-promo-no-rtp');
    }
  }
   calculateTopWithAlertHeight() {
       let top;
       let alertHeight = 0;
       if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
           alertHeight = $('.alert-component').height();
       }
       if (isMobileWidth() && exists('#uuCarousel')) {
           if (exists('.mob-prop-details')) {
               top = alertHeight + $('#uuCarousel').height() + $('.uu-umbrella-promo-banner').outerHeight() + $('.mob-prop-details').outerHeight(true);
           } else {
               top = alertHeight + $('#uuCarousel').height() + $('.uu-umbrella-promo-banner').outerHeight();
           }
       } else {
           top = alertHeight + getHeaderHeight() + $_HEADER_.position().top;
       }
       return top;
   }
  bookingBarLoad() {
    if (exists($_BOOKING_BAR_MAIN_)) {
      if ($_PAGE_.is('.uu-overview') || ($_PAGE_.is('.uu-property') && isMobileWidth())) {
        if (isMobileWidth()) {
          let $temp = $('#miniBookingHeight'),
            $newParent = exists('.property-page-hero-carousel-component') ? $('.property-page-hero-carousel-component') : $temp.parent().parent();

          $temp = $temp.detach();
          $temp.appendTo($newParent).show();
        } else if(isTabletWidth() && $('#pageHeadernew .top-nav').is(':visible')){
          $_BOOKING_BAR_MINI_NAV.parent().parent()
            .css({
              position: 'absolute',
              top: getRetailBannerHeight() + getHeaderHeight(),
              width: '100%'
            })
            .show();
            EventHandler.on('retailbanner:loaded', () => {
              $_BOOKING_BAR_MINI_NAV.parent().parent()
              .css({
                top: getRetailBannerHeight() + getHeaderHeight()
              });
            });

        } else {
          $_BOOKING_BAR_MINI_NAV.parent().parent()
            .css({
              position: 'absolute',
              top: this.calculateTopWithAlertHeight(),
              width: '100%'
            })
            .show();
             EventHandler.on('retailbanner:loaded', () => {
                 const top = this.calculateTopWithAlertHeight();
                 if (getRetailBannerHeight() == 0) {
                     $_BOOKING_BAR_MINI_NAV.parent().parent()
                         .css({
                             top: top + getRetailBannerHeight()
                         });
                 } else {
                     $_BOOKING_BAR_MINI_NAV.parent().parent()
                         .css({
                             top: top,
                         });
                 }
             });
        }
      }

      $('form.booking-bar-form.hide-on-load').removeClass('hide-on-load');
      let $_bookingBarCollapsed = $('#bookingBarCollapsed');
      if (isDesktopWidth() && !$('form.booking-bar-form').hasClass('display-none')) {
        if (!$_PAGE_.is('.collapsed')) {
          if (getBrand() !== 'WR') {
            let alertHeight = 0;
            if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
              alertHeight = $('.alert-component').height();
            }
            $_BOOKING_BAR_MAIN_.css({
              top: getHeaderHeight() + alertHeight
            });
          }
        }

        let height = ($_PAGE_.is('.search-results-page') || !($_PAGE_.is('.collapsed'))) ? $_BOOKING_BAR_MAIN_.outerHeight() : $_bookingBarCollapsed.outerHeight();

        // IE miscalculated the height
        if (isIE()) {
          height += 1;
        }
        $('#bookingBarHeight').height(height);
      } else {
        $('#bookingBarHeight').height(0);
      }

      let bookingInput = $('.booking-bar input.destination');
      let clearInput = $('.booking-bar .destination-container__clear-input');

      $(clearInput).on('click', () => {
        bookingInput.val('');
        $(clearInput).css({
          display: 'none'
        });
        clearInput.attr('aria-hidden', true);
        if(isDesktopWidth()) {
          $(this).prev().focus();
        }
      });

      $(clearInput).keypress(function(event){
        let keycode = (event.keyCode ? event.keyCode : event.which);
        if(keycode == '13') {
          bookingInput.val('');
          $(clearInput).css({
            display: 'none'
          });
          clearInput.attr('aria-hidden', true);
          if(isDesktopWidth()) {
            $(this).prev().focus();
          }
        }
      });

      $(bookingInput).focus(() => {
        $(clearInput).css({
          display: 'block'
        });
        clearInput.attr('aria-hidden', false);
      });

      $(bookingInput).blur(() => {
        if (bookingInput.val() === '') {
          $(clearInput).css({
            display: 'none'
          });
        }
      });

      EventHandler.on('retailbanner:loaded', () => {
        let retailBannerHeight = $('.retail-banner-component').length > 0 ? $('.retail-banner-component').height() : 0;
        if (retailBannerHeight > 0) {
          $('#bookingBarCollapsed').css({
            top: $('#pageHeader').outerHeight() + retailBannerHeight,
          });
        }

        if($_PAGE_.is('.homepage')){
          $('.retail-banner-component').css({position: 'static'});
        }

      });
      if($_PAGE_.is('.homepage')){
        $('.retail-banner-component').css({position: 'static'});
      }
    }

    EventHandler.send('bookingBar.isPositioned');
  }
  postResizeHandler() {
    this.bookingBarLoad();

    if (isDesktopWidth()) {
      if (!($_PAGE_.is('.collapsed'))) {
        if (getBrand() !== 'WR') {
          $_BOOKING_BAR_MAIN_.css({
            top: getHeaderHeight()
          });
        }
      }
    }
  }
}

/**
 * BBExpandCollapse
 * Handles expansion/collapsing of the international booking bar
 * rows. Secondary dropdowns expand when the user interacts with the
 * booking bar and collapse when the user scrolls away.
 */
class BBExpandCollapse {
  constructor() {
    this.isIntlBooking = false;
  }

  setIntlBooking(isIntl, intlSearchHandler) {
    this.isIntlBooking = isIntl;
    this.intlSearchHandler = intlSearchHandler;
  }

  collapse(cb) {
    if (this.isIntlBooking) {
      this.intlSearchHandler.collapseIntlBookingBar(cb);
    } else {
      if (typeof cb === 'function') {
        cb();
      }
    }
  }

  expand() {
    if (this.isIntlBooking) {
      this.intlSearchHandler.expandIntlBookingBar();
    }
  }
}

/**
 * BBForm
 * Primary booking bar business logic. Maintains user criteria
 * and receives input from other booking bar subclasses to update
 * criteria information.
 */
class BBForm {
  constructor() {
    this.$0 = $('form.booking-bar-form');
    this.$mini = $_BOOKING_BAR_MINI_NAV;
    this.$form = null;
    this.$destination = $('input.destination');
    this.$submitButton = $('.booking-bar .search-button-container button');
    this.$from = $('#from');
    this.$to = $('#to');
    this.$rooms = $('#numOfRooms');
    this.$adults = $('#numOfAdults');
    this.$children = $('#numOfChildren');
    this.$childAges = $('#childAges');
    this.$specialRateDrop = $('#special-rate-drop');
    this.$corpCodeData = $('#corp-code-data');
    this.$groupCodeData = $('#group-code-data');
    this.$rateCodeData = $('#rate-code-data');
    this.$promotionalCodeData = $('#promotional-code-data');
    this.$redeemWRPoint = $('#redeemWRPoint');
    this.$currentLocation = this.$0.find('button.location,a.location');
    this.location = {};
    this.destinationValue = null;
    this.allowedRegex = /[^\\\/#!$%\^&\*;:{}=\_`~()]/g;
    this.searchByGeoLocation = false;
    this._isReady = false;
    this._isDestinationDropdown = exists($_BOOKING_BAR_MAIN_.find('.destination-dropdown'));
    this._isIntlSearch = $_BOOKING_BAR_MAIN_.is('.intl-search');
    this.arExclusionList = null;
    this.arLastTerm = null;
    this.arUseAllLocationsTypes = false;
    this.arMaxResults = 5;
    this.itemARSelected = false;
    this.rates = {
      aaa: {
        plan: 'S3A',
        code: 1000008323
      },
      governmentPerDiem: {
        plan: 'SGV',
        code: 1000011084
      },
      veteranMilitary: {
        plan: 'SML',
        code: 1000022313
      },
      senior: {
        plan: 'SSR',
        code: 1000011086
      },
      aarp: {
        plan: 'AARP',
        code: 8000000046
      }
    };
    $(() => {
      if (exists($('form.booking-bar-form'))) {
        if (!($_PAGE_.is('.modify-page'))) {
          this.init(true);
        }
      }
    });
  }
  setChildBBInstances(bbMini, bbDatePicker, bbMoreOptions, bbRoomsGuests, bookNowModal) {
    this.bbMini = bbMini;
    this.bbDatePicker = bbDatePicker;
    this.bbMoreOptions = bbMoreOptions;
    this.bbRoomsGuests = bbRoomsGuests;
    this.bookNowModal = bookNowModal;
  }
  setChildAvailabilityCalendar(bbAvailabilityCalendar) {
    this.bbAvailabilityCalendar = bbAvailabilityCalendar;
  }
  getSearchByGeoLocation() {
    return this.searchByGeoLocation;
  }
  setSearchByGeoLocation(g) {
    this.searchByGeoLocation = g;
  }
  getForm() {
    return this.$form;
  }
  getDestinationDropdownPath() {
    return this.destDropdownHandler.getPath();
  }
  getIntlSearchPath() {
    let localePrefix = getLocaleUrlToken();
    return window.location.origin + localePrefix + (this.intlSearchHandler.getSearchUrl().charAt(0) === '/' ? (this.intlSearchHandler.getSearchUrl() || '') : ('/' + this.intlSearchHandler.getSearchUrl()) || '');
  }
  getSearchPath() {
    if (this._isIntlSearch) {
      return this.getIntlSearchPath();
    } else if (this._isDestinationDropdown) {
      return this.getDestinationDropdownPath();
    }
  }
  getDestination() {
    return this._isDestinationDropdown && this.destDropdownHandler.getVal() !== ' ' ? this.destDropdownHandler.getVal() : this.$destination.val();
  }
  setDestination(val, _andNullLocation, cb) {
    if (typeof val === 'string') {
      this.destinationValue = val;
      this.$destination.val(val.trim());

      this.$0.find('.destination').val(val.trim());

      if (_andNullLocation === true) {
        this.setLocation({});
      }

      if (typeof cb === 'function') {
        cb();
      }
    }
  }
  getFrom() {
    return formatDateForBWS(this.$from.val());
  }
  getCheckInDate() {
    return formatDateForPrinting(getCriteria().checkInDate, 'textMonth');
  }
  setFrom(val) {
    this.$from.val(val);
  }
  getTo() {
    return formatDateForBWS(this.$to.val());
  }
  getCheckOutDate() {
    return formatDateForPrinting(getCriteria().checkOutDate, 'textMonth');
  }
  setTo(val) {
    this.$to.val(val);
  }
  getRooms() {
    return this.$rooms.val() * 1;
  }
  setRooms(val) {
    this.$rooms.val(val);
  }
  getAdults() {
    return this.$adults.val() * 1;
  }
  setAdults(val) {
    this.$adults.val(val);
  }
  getChildren() {
    return this.$children.val() * 1;
  }
  setChildren(val) {
    this.$children.val(val);
  }
  buildChildAges() {
    let num = this.getChildren(),
      ages = [];

    for (let i = 1; i <= num; i++) {
      ages.push(this.$form.find('.child-age-' + i + ' select.child-age').val());
    }

    this.setChildAges(ages.join('-'));
  }
  getChildAges() {
    return this.$childAges.val();
  }
  setChildAges(val) {
    this.$childAges.val(val);
  }
  getSpecialRate() {
    if (this.getSpecialRateDrop()) {
      if (this.getSpecialRateDrop() != 'promotional') {
        return {
          type: this.getSpecialRateDrop(),
          code: this.getCorpCodeData()
        };
      } else {
        return {
          type: this.getSpecialRateDrop(),
          code: this.getRateCodeData()
        };
      }
    } else if (this.getCorpCodeData()) {
      return {
        type: 'corpCode',
        code: this.getCorpCodeData()
      };
    } else if (this.getGroupCodeData()) {
      return {
        type: 'groupCode',
        code: this.getGroupCodeData()
      };
    } else if (this.getRateCodeData()) {
      return {
        type: 'rateCode',
        code: this.getRateCodeData()
      };
    } else if (this.getPromotionalCodeData()) {
      return {
        type: 'promotional',
        code: this.getPromotionalCodeData()
      };
    }

    return null;
  }
  setSpecialRate(code, type) {
    if (typeof (code) !== 'undefined') {
      // Only one of these should be set at a time, so reset them all before updating one
      this.setSpecialRate();
    }

    if (type == 'corpCode') {
      return this.setCorpCodeData(code);
    } else if (type == 'groupCode') {
      return this.setGroupCodeData(code);
    } else if (type == 'rateCode' || type == 'promotional') {
      if (type == 'promotional') {
        this.setSpecialRateDrop(type);
        this.setPromotionalCodeData(code);
      }

      return this.setRateCodeData(code);
    } else if (code) {
      // If type isn't one of the above, type should be set to a specific corporate code
      return this.setSpecialRateDrop(code, type);
    } else {
      // Calling this function with an empty val or empty type will reset all values
      this.setCorpCodeData('');
      this.setGroupCodeData('');
      this.setRateCodeData('');
      this.setPromotionalCodeData('');
      this.setSpecialRateDrop('');
    }
  }
  getSpecialRateDrop() {
    return this.$specialRateDrop.val();
  }
  setSpecialRateDrop(val, code) {
    if (code) {
      this.setCorpCodeData(code);
    }
    this.$specialRateDrop.val(val);
  }
  getCorpCodeData() {
    return this.$corpCodeData.val();
  }
  setCorpCodeData(val) {
    this.$corpCodeData.val(val);
  }
  getGroupCodeData() {
    return this.$groupCodeData.val();
  }
  setGroupCodeData(val) {
    this.$groupCodeData.val(val);
  }
  getRateCodeData() {
    return this.$rateCodeData.val();
  }
  setRateCodeData(val) {
    this.$rateCodeData.val(val);
  }
  getPromotionalCodeData() {
    return this.$rateCodeData.val();
  }
  setPromotionalCodeData(val) {
    this.$promotionalCodeData.val(val);
    this.$rateCodeData.val(val);
  }
  getRedeemWRPoint() {
    return this.$redeemWRPoint.is(':checked');
  }
  setRedeemWRPoint(val) {
    this.$redeemWRPoint.prop('checked', val);

    if (val === true) {
      if (this.bbAvailabilityCalendar && this.bbAvailabilityCalendar.isAvailabilityCalendarSetUp()) {
        this.bbAvailabilityCalendar.toggleAvailabilityCalendarLinks(true);
      }
      this.setSpecialRate();
    }
  }
  getLocation() {
    return this.location;
  }
  setLocation(l) {
    this.location = l;
  }
  isIntlSearch() {
    return this._isIntlSearch;
  }

  buildFromSessionData() {
    const searchCriteria = getCriteria();
    let searchCriteriaAdults = searchCriteria.adults;
    if (_isNotNull(searchCriteria.searched)) {
      this.setDestination(searchCriteria.searched);
    } else if (_isNotNull(searchCriteria.location)) {
      this.setDestination(searchCriteria.location);
    }
    this.setLocation(getLocationDetails());

    if (searchCriteria) {
      if (searchCriteria.checkInDate) {
        this.setFrom(searchCriteria.checkInDate);
      }
      if (searchCriteria.checkOutDate) {
        this.setTo(searchCriteria.checkOutDate);
      }

      this.setRooms(searchCriteria.rooms);

      if (parseInt(this.$adults.data('default')) > 0 && !getSessionCriteria().updated) {
        searchCriteriaAdults = parseInt(this.$adults.data('default'));
      }

      this.setAdults(searchCriteriaAdults);

      if (searchCriteria.children > 0) {
        this.setChildren(searchCriteria.children);
      }
      if (searchCriteria.childAge) {
        this.setChildAges(searchCriteria.childAge);
      }

      if (searchCriteria.ratePlan && searchCriteria.ratePlan != 'BAR') {
        this.setSpecialRateDrop(searchCriteria.ratePlan, searchCriteria.corpCode);
      }

      if (searchCriteria.rateCode && searchCriteria.rateCode.length > 0) {

        if (this.getSpecialRateDrop() == 'promotional') {
          this.setPromotionalCodeData(searchCriteria.rateCode);
        } else {
          this.setRateCodeData(searchCriteria.rateCode);
        }
      }

      if (searchCriteria.groupCode && searchCriteria.groupCode.length > 0) {
        this.setGroupCodeData(searchCriteria.groupCode);
      }

      if (searchCriteria.corpCode && searchCriteria.corpCode.length > 0) {
        this.setCorpCodeData(searchCriteria.corpCode);
      }

      if (searchCriteria.useWRPoints && _isWeakTrue(searchCriteria.useWRPoints)) {
        this.setRedeemWRPoint(true);
      }
    }

    let $content = $('.page-content');
    if (exists($content)) { // Deals page logic - get special rate from page data-attribute
      if ($content.data('corporate-code')) {
        this.setCorpCodeData($content.data('corporate-code'));
      } else if ($content.data('rate-code')) {
        this.setRateCodeData($content.data('rate-code'));
      }
    }

    if (this._isReady === false) {
      this._isReady = true;
      initBBSubPieces();

      if (this._isDestinationDropdown) {
        this.destDropdownHandler = new BBDestination(this, this.bbMini);
      } else if (this._isIntlSearch) {
        this.intlSearchHandler = new BBInternational(this, this.bbMini, _LOCALE_);
        bbExpandCollapseInst.setIntlBooking(this._isIntlSearch, this.intlSearchHandler);
      }

      this.bbRoomsGuests.init();

      EventHandler.send(EventHandler.BookingBarLoad);

      this.$0.find('button').removeAttr('disabled');
      // Mini booking mobile dates is moved outside the form
      $('.mini-booking-container .mobile-dates').find('button')
        .removeAttr('disabled');

      if (this._isIntlSearch) {
        this.intlSearchHandler.disableDropdowns();
      }
    }
  }
  addFindNearMeItem(items) {
    if (!items) {
      items = [];
    }
    items.unshift({
      label: ''
    });
    return items;
  }
  matchesExclusionList(term) {
    let normalized = term.toLowerCase();
    if (term && this.arExclusionList && this.arExclusionList.length > 0) {
      for (let i = 0; i < this.arExclusionList.length; i++) {
        if (normalized.indexOf(this.arExclusionList[i]) >= 0) {
          return true;
        }
      }
    }
    return false;
  }
  matchesChinaList(term) {
    let chinaList = ['hong kong', 'macao', 'macau', 'taiwan', 'tibet'];
    return (chinaList.indexOf(term.value.toLowerCase()) >= 0);
  }
  processData(data) {
    let results = [],
    arMaxResults = Math.floor(this.arMaxResults / Object.keys(data).length),
    isProxy = (CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() === 'CN') ? true : false;
    let citiesResults = this.processDataPerSection(data, '(cities)', window.autoCompleteLabels.cities, isProxy);
    let allResults = this.processDataPerSection(data, 'all', window.autoCompleteLabels.pointsOfInterest, isProxy);
    results = results.concat(citiesResults.slice(0, arMaxResults));
    results = results.concat(allResults.slice(0, arMaxResults));
    return results;
  }

  processDataPerSection(data, sectionId, sectionLabel, isProxy) {
    let sectionList = [];
    if (data[sectionId] && data[sectionId].length > 0) {
      $.each(data[sectionId], (index, item) => {
        if (sectionId == '(cities)' || !this.matchesExclusionList(item.description)) {
          let value = {
            placeId: item.place_id,
            label: item.description,
            terms: item.terms,
            structuredFormatting: item.structured_formatting,
            category: sectionLabel
          };

          if (isProxy && this.matchesChinaList(value.terms[value.terms.length - 1])) {
            value.terms.push({offset: parseInt(value.label.length - value.terms[value.terms.length - 1].length + 2), value: 'China'});
            value.label += ', China';
          }
          sectionList.push(value);
        }
      });
    }
    return sectionList;
  }

  getAutocompleteList(term, callback) {
    let types = ['all', '(cities)'];
    if (this.arUseAllLocationsTypes) {
      types = ['all'];
    }
    GoogleAPIHandler.placeAutoCompleteTypes(term, types).then((data) => {
      callback(this.addFindNearMeItem(this.processData(data)));
    }, () => {
      callback(this.addFindNearMeItem([]));
    });
  }
  enableAutoComplete() {
    let autocomplete = this.$0.find('#destAutocomplete'),
      arExclusionListEnc = autocomplete.data('exclusion-list') || false;

    this.arUseAllLocationsTypes = autocomplete.data('all-location-types') || false;
    /* Dev Only */
    if (getQueryParameter('allLocationTypesEnabled')) {
      let isEnabled = _isWeakTrue(getQueryParameter('allLocationTypesEnabled'));
      this.arUseAllLocationsTypes = isEnabled;
      autocomplete.attr('all-location-types', isEnabled);
      autocomplete.data('allLocationTypes', isEnabled);
    }

    this.arMaxResults = autocomplete.data('max-results') || 5;

    if (arExclusionListEnc) {
      try {
        this.arExclusionList = atob(arExclusionListEnc).split(';');

        for (var i = 0; i < this.arExclusionList.length; i++) {
          this.arExclusionList[i] = this.arExclusionList[i].toLowerCase();
        }
      } catch (ignore) {
        this.arExclusionList = null;
      }
    } else {
      this.arExclusionList = null;
    }
    let thisInput = this.$0.find('input.destination');
    const context2 = this;
    let sab = $('#smartbanner'), alc = $('.alert-component'),alch = 0,sabh = 0, bannerHeight = 0;
    let headerHeight = $('#pageHeader').is('.old-navigation-hide') ? $('#pageHeadernew .top-nav').outerHeight() : $('#pageHeader').outerHeight(),
    WrheaderHeight = $('header.mobile-nav .top-nav').outerHeight();
    bannerHeight = $('.promo-banner').outerHeight();

    let isApiOccured = false;
    const isAuthenticated = CookieHandler.readCookie(WR_AUTHENTICATED) === 'true';
    $('input.destination').on('blur', (e) => {
      if(!$(e.target).parents().is('#pageHeadernew')) {
      $(e.currentTarget).attr('placeholder', this.locationPlaceholder);
      if(isMobileWidth()){
        $('.mobile-booking-dropdown .book-redeem-title').css('display','block');
        $('nav.booking-bar .wr-refine.refine-points-checkbox').css('display','block');
        $('.mobile-booking-dropdown .book-redeem-wrapper').css('padding-top', 35 + 'px');
        $('header.mobile-nav > .dropdown[data-dropdown="book"]').css({
          'z-index': 10,
          'marginTop': exists($('.retail-banner-component')) ? getRetailBannerHeight() : $('header.mobile-nav > .dropdown[data-dropdown="book"]').css('marginTop')
        });
        $('html,body').animate({
              scrollTop: 0
        });
      }
      if(isMobileWidth() && isiOS() && $_PAGE_.hasClass('homepage') && !$('#bookingBar__main').hasClass('open')){
        $('html,body').animate({
              scrollTop: 0
        });
      }
      if($('#bookingBar__main').hasClass('open') && isMobileWidth()) {
        if (sab.length > 0 || alc.length > 0) {
          if (sab.length > 0) {
            sab.hide();
          }
          if (alc.length > 0) {
            alc.hide();
          }
        }
        $('#pageHeader').css({top: 0});
        if (exists('.retail-banner-component') && $_PAGE_.is('.homepage')) {
          $('#bookingBar__main').css({top: 0, marginTop: $('#pageHeader').outerHeight()});
        } else {
          $('.outer-wrapper.page').css('margin-top', 0);
          $('.promo-banner').css('top', headerHeight + 'px');
          $('#bookingBar__main').css('top', headerHeight);
        }
      }
      if(!$('#bookingBar__main').hasClass('open') && isMobileWidth()){
        if(sab.length > 0) {
          sab.show();
          sabh = $('#smartbanner').outerHeight();
        }
        if(alc.length > 0 ) {
          alc.show();
          alch = $('.alert-component').outerHeight();
        }
        if(alch == undefined || alch == null) {
              alch = 0;
        }

        $('#pageHeader').css('top',0);
        $('#pageHeadernew').css('top',0);
        if(!$_PAGE_.hasClass('homepage')){
          $('.outer-wrapper.page').css('margin-top',(sabh + alch) + 'px');
          $('#pageHeader').css('top',(sabh + alch) + 'px');
          $('#pageHeadernew').css('top',(sabh + alch) + 'px');
        }

        if (exists('.retail-banner-container') && brand_id == 'wr' ) {
          if($_HEADER_.is('#pageHeader')){
            $('header.mobile-nav > .top-nav').css({
              top : 0,
              marginTop: $('.retail-banner-container').height()
            });
          }
          $('header.mobile-nav > .dropdown[data-dropdown="book"]').css('top', 60);
          $('.page').css('marginTop', '0px');
        } else if (!exists('.retail-banner-container')  &&  brand_id == 'wr' ) {
          $('header.mobile-nav > .top-nav').css('top', (sabh + alch) + 'px');
          $('header.mobile-nav > .dropdown').css('top',(sabh + alch + 60) + 'px');
        }
      }

      if($('body').hasClass('android') && !$('#bookingBar__main').hasClass('open') && isMobileWidth()){
        $('.promo-banner').css('top', headerHeight + 'px');

        const $pageHeader = $('#pageHeader');
        const $pageHeadernew = $('#pageHeadernew');
        const $bookingBarMain = $('#bookingBar__main');
        const $retailBannerContainer = $('.retail-banner-container');
        if ($_PAGE_.is('.homepage') && brand_id !== 'wr' && exists('.retail-banner-container')) {
          if (exists('#smartbanner')) {
            $bookingBarMain.css({
              top: $pageHeader.height(),
              marginTop: 0
            });
            $pageHeader.css({
              top: $('#smartbanner').height(),
              marginTop: $retailBannerContainer.height()
            });
            $pageHeadernew.css({
              top: $('#smartbanner').height(),
              marginTop: $retailBannerContainer.height()
            });
          } else {
            $pageHeader.css({
              marginTop: $retailBannerContainer.height()
            });
            $pageHeadernew.css({
              marginTop: $retailBannerContainer.height()
            });
            $bookingBarMain.css({
              top: 0
            });
          }
        } else {
          $bookingBarMain.css('top',(headerHeight) + 'px');
        }
      }
      }
    });
    $('input.destination').on('focus', async(e) => {
      this.locationPlaceholder = $(e.currentTarget).attr('placeholder');
      $(e.currentTarget).attr('placeholder', '');
      if(!$(e.target).parents().is('#pageHeadernew')) {
      if (isMobileWidth() && brand_id === 'wr') {
        if (alc.length > 0) {
          alc.hide();
        }
        $('header.mobile-nav > .top-nav').css('top', '0');
        $('header.mobile-nav > .dropdown[data-dropdown="book"]').css({'top': 0, 'z-index': '9999'});
        $('.mobile-booking-dropdown .book-redeem-title').css('display', 'none');
        $('.mobile-booking-dropdown .book-redeem-wrapper').css('padding-top', 0);
        $('nav.booking-bar .wr-refine.refine-points-checkbox').css('display', 'none');
        $('body, html').animate({
          scrollTop: 0
        });
      }
      if (isMobileWidth() && isiOS() && $_PAGE_.hasClass('homepage') && !$('#bookingBar__main').hasClass('open')) {
        if (sab.length > 0) {
          sab.hide();
        }
        if (alc.length > 0) {
          alc.hide();
        }
        $('.outer-wrapper.page').css('margin-top', 0);
        $('#pageHeader').css('top', 0);
        $('#pageHeader').css('top', '-' + headerHeight + 'px');
        $('body, html').animate({
          scrollTop: thisInput.offset().top - 10 + 'px'
        });
      }
      if (isMobileWidth() && isiOS() && $('#bookingBar__main').hasClass('open')) {
        $('#pageHeader').css('top', '-' + headerHeight + 'px');
        if (exists('.retail-banner-component') && $_PAGE_.is('.homepage')) {
          $('#bookingBar__main').css({ top: 0, marginTop: 0 });
        } else {
          $('#bookingBar__main').css({ top: 0 });
        }
      }
      if ($('body').hasClass('android') && isMobileWidth()) {
        if (sab.length > 0) {
          sab.hide();
        }
        if (alc.length > 0) {
          alc.hide();
        }
        $('header.mobile-nav .top-nav').css('top', '-' + WrheaderHeight + 'px');
        $('.outer-wrapper.page').css('margin-top', 0);

        if ($_PAGE_.is('.homepage') && brand_id !== 'wr' && exists('.retail-banner-component')) {
          $_HEADER_.css({
            top: 0,
            marginTop: 0
          });
          $('.promo-banner').css('top', '-' + (headerHeight + bannerHeight) + 'px');
          $_BOOKING_BAR_MAIN_.css({
            top: 0,
            marginTop: $('#bookingBar__main').hasClass('open') ? 0 : $('#pageHeader').outerHeight()
          });
        } else {
          // $_HEADER_.css('top', '-' + headerHeight + 'px');
          $('.promo-banner').css('top', '-' + (headerHeight + bannerHeight) + 'px');
          $_BOOKING_BAR_MAIN_.css('top', headerHeight + 'px');
        }

        if (brand_id == 'wr'  && exists('.retail-banner-component')) {
          $('header.mobile-nav .top-nav, header.mobile-nav > .dropdown[data-dropdown="book"]').css('marginTop', 0);
        }
      }
      }
      // Fetch and render Favorites Hotels list
      if(isAuthenticated && !isApiOccured) {
        const propIdsArrayForFavourites = dataHandler();
        await searchResult(propIdsArrayForFavourites);
      }
    });

    const formatDates = (input1, input2) => {
      // Parse the input dates
      const date1 = new Date(input1);
      const date2 = new Date(input2);

      // Extract the month and day
      const month1 = date1.toLocaleString('default', { month: 'short' });
      const month2 = date2.toLocaleString('default', { month: 'short' });
      const day1 = date1.getDate();
      const day2 = date2.getDate();

      // Format the output based on whether the months are the same or different
      const output = `${month1} ${day1} - ${month2} ${day2}`;
      return output;
    };
    const renderMarkupForRecentSearch = (className, label, data) => {
      // Check if the wrapper container exists
      let $wrapperContainer = $('#destAutocomplete .wrapper-dd-recent');
      if ($wrapperContainer.length === 0) {
        $('#destAutocomplete').append('<div class="wrapper-dd-recent"></div>');
        $wrapperContainer = $('#destAutocomplete .wrapper-dd-recent');
      }

      // Check if the specific container for recent searches or favorite hotels exists
      let $favouriteHotelsContainer = $wrapperContainer.find(`.${className}`);
      if ($favouriteHotelsContainer.length === 0) {
        const templateFav = `
          <div class="auto-search-dropdown ${className}">
            <h4 class="heading-recent-searches">${label}</h4>
            <ul tabindex="0" class="favouriteHotelsList ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"></ul>
          </div>`;
        $wrapperContainer.append(templateFav);
        $favouriteHotelsContainer = $wrapperContainer.find(`.${className}`);
      }

      // Create the list items and append them to the container
      let listItems = data
        .map(
          (hotel) =>
            `<li class="ui-menu-item-wrapper" data-checkin="${hotel.checkIn || ''}" data-checkout="${hotel.checkout || ''}"
              data-location="${hotel.location ? encodeURIComponent(hotel.location) : ''}"
               data-uniqueurl="${hotel.uniqueUrl ? hotel.uniqueUrl : ''}">
              ${hotel.location || ""}
              ${hotel.checkIn || hotel.checkout
              ? ' &#8226; ' +
              formatDates(
                formatDateForPrinting(hotel.checkIn, 'textMonth'),
                formatDateForPrinting(hotel.checkout, 'textMonth')
              )
              : ''
            }

            </li>`
        )
        .join('');
      $favouriteHotelsContainer.find('.favouriteHotelsList').empty().append(DOMPurify.sanitize(listItems)).show();

    };
    const dataHandler = () => {
      isApiOccured = true;
      let parsedUserPreferences = null;
      let propIdsArrayForFavourites = [];
      const userPreferences = sessionStorage.getItem('_userpreferences_');
      if(userPreferences) {
        parsedUserPreferences = JSON.parse(userPreferences);
      }
      // Check if FavoriteHotels exists and is an array before mapping
      if (
        parsedUserPreferences.FavoriteHotels &&
        parsedUserPreferences.RecentSearches.length > 0 &&
        Array.isArray(parsedUserPreferences.FavoriteHotels)
      ) {
        propIdsArrayForFavourites = parsedUserPreferences.FavoriteHotels.map((item) => item.PropertyId);
      }
      // Check if RecentSearches exists and is an array before mapping
      if (
        parsedUserPreferences.RecentSearches &&
        parsedUserPreferences.RecentSearches.length > 0 &&
        Array.isArray(parsedUserPreferences.RecentSearches)
      ) {
        const recentSearches = parsedUserPreferences.RecentSearches.map((obj) => ({
          location: obj.Location, checkIn: obj.CheckInDate, checkout: obj.CheckOutDate,
        }));
        renderMarkupForRecentSearch('recentSearchDropDown', 'Recent Searches', recentSearches);
      }
      return propIdsArrayForFavourites;
    };
    // Function to fetch and render Favorite Hotels
    async function searchResult(propIdsArrayForFavourites) {
      try {
        const data = await manageSearchfavourite(propIdsArrayForFavourites);
        const properties = data.data.searchProperties.properties.map((property) => {
          return {location: property.name, uniqueUrl: property.uniqueUrl};
        }).slice(0,5);
        renderMarkupForRecentSearch('favouriteHotels', 'Favorite Hotels', properties);

      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    }

    $('.ui-autocomplete').off('menufocus'); // This sets the actual input value to the "focused" list item

    thisInput
      .catcomplete({
        source: (request, response) => {
          //remove the geocookie as new search has been initiated
          if (CookieHandler.readCookie('UserGeoData') && CookieHandler.readCookie('UserGeoData').toString().length > 0) {
            CookieHandler.eraseCookie('UserGeoData');
          }
          let destinationData = $.trim(request.term.replace(/([;?*+^$![\]\\(){}])/g, ''));
          // check and remove non pritable characters from search Input
          request.term = destinationData.replace(/[\u0000\u0001\u0002\u0003\u0004\u0005\u0006\u0007\u0008\u0009\u000A\u000B\u000C\u000D\u000E\u000F\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001A\u001B\u001C\u001D\u001E\u001F\u007F]/g,'');
          /*****Replace Multiple space between words****/
          destinationData = destinationData.replace(/\s{2,}/g, '0');
          /*****Removing multiple space between words end****/
          let validCharCount = destinationData.length;

          if (this.arLastTerm && this.arLastTerm.term == request.term) {
            response(this.arLastTerm.results);
          } else if (validCharCount >= 3) {
            this.getAutocompleteList(request.term, (results) => {
              this.arLastTerm = {
                term: request.term,
                results: results
              };
              response(results);
            });
            this.setLocation({});
            $('.auto-search-dropdown').hide();
          } else {
            $('.auto-search-dropdown').show();
            response(this.addFindNearMeItem([]));
          }
        },
        select: (e, ui) => {
          let $dropdownAR = $('#destAutocomplete'),
          locationInfo = {};
          context2.itemARSelected = true;

          setTimeout(() => {
            if (ui.item.placeId && ui.item.value) {
              locationInfo.placeId = ui.item.placeId;
            locationInfo.loc_temp = ui.item.placeId;
              locationInfo.searched_temp = ui.item.value;
              this.setLocation(locationInfo);
              setLocationDetails(locationInfo);
            }

            if (isMobileWidth()) {
              $('html,body').animate({
              scrollTop: 0
            });
              thisInput.blur();
              $dropdownAR.find('ul').hide();
            } else {
              if ($_PAGE_.is('.homepage-template') && !isElementInViewport(thisInput)) {
                $('body, html').animate({
                  scrollTop: thisInput.offset().top - 85 + 'px'
                });
              }
            }
          });
          e.stopPropagation();
        },
        close: () => {
          this.enableRecentAndFavourite();
          $('.auto-search-dropdown').hide();
        },
        open: (e, ui) => {
          let autoCompContainer = $('.ui-autocomplete-container .ui-autocomplete'),
            autoCompHeight = $('.ui-autocomplete-container .ui-autocomplete').height(),
            currWinHeight = $(window).height(),
            isProxy = (CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() === 'CN') ? true : false;
          // Remove 'Find hotels nearby' for CN users
          if (isProxy) {
            $(autoCompContainer).find('.ui-autocomplete-geolocation')
            .remove();
          }
          if (!isMobileWidth() && !isElementInViewport(autoCompContainer) && $(e.currentTarget).closest('.modal').length === 0) {
            if ($_PAGE_.is('.homepage')) {
              if ((currWinHeight - 85) >= autoCompHeight) {
                $('body, html').animate({
                  scrollTop: ($(autoCompContainer).offset().top - (currWinHeight - (autoCompHeight))) + 15 + 'px'
                });
              } else {
                $('body, html').animate({
                  scrollTop: $(autoCompContainer).offset().top - 160 + 'px'
                });
              }
            } else if ($_PAGE_.is('.homepage-template') && (currWinHeight - 85) >= autoCompHeight) {
              $('body, html').animate({
                scrollTop: ($(autoCompContainer).offset().top - (currWinHeight - (autoCompHeight))) - 85 + 'px'
              });
            }
          }
        },
        focus: (event, ui) => {
          event.preventDefault();
        },
        appendTo: '#destAutocomplete',
        minLength: 0,
        delay: 300
      })
      .on('focus', (e) => {
        let instance = $(e.currentTarget).data('custom-catcomplete');
        if (instance) {
          let currentInput = $(e.currentTarget).val();
          if (currentInput.length >= 3) {
            $('.auto-search-dropdown').hide();
          } else {
            $('.auto-search-dropdown').show();
          }
          instance.search();
        } else {
          $('.auto-search-dropdown').show();
        }
      })
      .on('input', (e) => {
        let currentInput = $(e.currentTarget).val();
        if (currentInput.length >= 3) {
          $('.auto-search-dropdown').hide();
        } else {
          $('.auto-search-dropdown').show();
        }
      });
  }
  populateSearchFromLocation(position) {
    let lat = position.coords.latitude,
      long = position.coords.longitude;

    GoogleAPIHandler.reverseGeoCoding(lat, long).then((locations) => {
      let myLocation;
      this.itemARSelected = true;
      if (locations.length > 0) {
        myLocation = locations[0]; // Get always the first value
        myLocation = myLocation ? myLocation : locations[0];
        let locationString = myLocation.formatted_address;
        myLocation = GoogleAPIHandler.prepareLocationObject(myLocation);
        myLocation.latitude = lat;
        myLocation.longitude = long;
        setLocationDetails(myLocation);
        Dropdown.publicCloseDropdown();
        this.$0.find('.destination-dropdown-property.active').removeClass('active');
        this.setDestination(locationString);
        window.digitalData.search.searchInfo.currentLocation = 'Yes';
        if (this._isDestinationDropdown) {
          this.destDropdownHandler.setSelectedText(locationString);
        }
        //if the user clicks on geolocation icon on global booking widget
        if (this.searchByGeoLocation === true) {
          CookieHandler.createCookie('UserGeoData', JSON.stringify({
            latitude: lat,
            longitude: long
          }));
        }
        $('.booking-bar-form .destination').val(locationString);
        $('.booking-bar-form .destination').trigger('keyup');
        this.$currentLocation.blur();
      } else {
        console.log('Error handled your location');
      }
    }, () => {
      Dropdown.publicCloseDropdown();
      this.$currentLocation.blur();
    });
  }
  getUserGeoLocation(event) {
    event.preventDefault();
    $_PAGE_.addClass('loading');
    if (navigator.geolocation) {
      if (this._isDestinationDropdown) {
        this.destDropdownHandler.deleteVal();
      }
      this.searchByGeoLocation = true;
      navigator.geolocation.getCurrentPosition((pos) => this.populateSearchFromLocation(pos), (e) => this.locationError(e), {
        enableHighAccuracy: false,
        maximumAge: 15000,
        timeout: 10000
      });
      $_PAGE_.removeClass('loading'); // quick fix for mobile issue, need to revert back
      $_BOOKING_BAR_MAIN_.find('input.destination').catcomplete('close');
    } else {
      $_PAGE_.removeClass('loading');
      $(event.currentTarget).blur();
    }
  }
  enableUserLocation() {
    this.$currentLocation.click((e) => this.getUserGeoLocation(e));
    $_BOOKING_BAR_MAIN_.on({
      click: (e) => this.getUserGeoLocation(e)
    }, '.ui-autocomplete-geolocation');
  }
  getUserLocation() { //fetches the current location of the user from cookie
    if ((this.searchByGeoLocation === true) && CookieHandler.readCookie('UserGeoData') && CookieHandler.readCookie('UserGeoData').toString().length > 0) {
      let geoData = JSON.parse(CookieHandler.readCookie('UserGeoData'));
      if (typeof geoData === 'object' && !($.isEmptyObject(geoData))) {
        if ('location' in geoData) {
          return geoData.location;
        } else {
          return false;
        }
      } else return false;
    } else return false;
  }
  locationError(err) {
    switch (err.code) {
    case err.PERMISSION_DENIED:
      alert(window.locationErrorMsgs.permissionDenied || 'Please allow access to your location to use this feature by changing your phone settings.');
      break;
    case err.POSITION_UNAVAILABLE:
      alert(window.locationErrorMsgs.positionUnavailable || 'Location information unavailable.');
      break;
    default:
      alert(window.locationErrorMsgs.generalError || 'Could not get your location. Please try again later.');
      break;
    }
  }
  moveWRCheckbox() {
    if (exists($('.book-with-points'))) {
      $('.book-with-points').append($('.booking-bar-main .more-options-container div.wyndham-rewards-checkbox').detach());
      $('.wyndham-rewards-checkbox-prompt').remove();
    }
  }
  destinationIsValid() {

    if (exists('.destination-container input.destination')) {
      let destInput = $_BOOKING_BAR_MAIN_.find('.destination-container input.destination'),
        destInputParsley = destInput.parsley();

      destInputParsley.removeError('invalidCharacters');
      if (this.allowedRegex.test(this.getDestination())) {
        return true;
      }

      destInputParsley.addError('invalidCharacters', {
        message: destInput.data('invalid-error')
      });

      destInput.on('keyup', (e) => {
        $(e.currentTarget).off('keyup');

        this.setDestination($(e.currentTarget).val());
        this.destinationIsValid();
      });

      return false;
    } else if (this._isDestinationDropdown) {
      this.destDropdownHandler.clearDestinationDropdownError();

      if (_isNotNull(this.destDropdownHandler.getVal()) || (_isNull(this.destDropdownHandler.getVal()) && _isNotNull(this.getDestination()))) {
        return true;
      } else {
        this.destDropdownHandler.addError();
      }
    } else if (this._isIntlSearch) {
      this.intlSearchHandler.clearIntlDropdownErrors();

      this.intlSearchHandler.checkForErrors();

      return this.intlSearchHandler.getSearchUrl() || (this.$form.is('.mini-booking') ? $('input.destination').val() : false);

    } else {
      return false;
    }
  }
  hasRateCodeAndIsLoyaltyMember() {
    if (isLoyaltyRP(this.getRateCodeData().trim()
      .toUpperCase())) {
      this.bbMoreOptions.setWyndhamRewards(true);
    } else if (this.getRateCodeData()) {
      this.bbMoreOptions.setWyndhamRewards(false);
      return false;
    }
    return this.getRateCodeData();
  }
  isWRPointsChecked() {
    return (this.getRedeemWRPoint());
  }
  roomCheckPassed() {
    if ((this.hasRateCodeAndIsLoyaltyMember() || this.isWRPointsChecked()) && this.getRooms() > 1) {
      simpleLightbox(this.$rooms.data('rewards-error'), () => {
        this.bbRoomsGuests.setForm(this.$form);
        this.bbRoomsGuests.open();
        this.bbRoomsGuests.errorRooms(true);
      });

      return false;
    } else {
      return true;
    }
  }
  destinationCannotBeFound() {
    if (!(exists('.destination-container input.destination'))) {
      return false;
    }

    let destInput = $_BOOKING_BAR_MAIN_.find('.destination-container input.destination'),
      destInputParsley = destInput.parsley();

    if (!exists(destInput.siblings('.parsley-errors-list').find('li'))) {
      destInputParsley.addError('destinationNotFound', {
        message: destInput.data('location-error')
      });
    }

    destInput.on('keyup', (e) => {
      $(e.currentTarget).off('keyup');

      destInputParsley.removeError('destinationNotFound');
    });
  }

  setSessionDataAndGoToSearch() {
    if (this.roomCheckPassed()) {
      if (this.$form.is('.booking-bar-main') && !($('[name="destination"]').hasClass('hidden') && $_PAGE_.is('.rooms-rates-page'))) {
        if (this.destinationIsValid()) {
          if (this._isDestinationDropdown || this._isIntlSearch) {
            window.digitalData.search.updateSearchData = true;
            if(this._isIntlSearch || this.destDropdownHandler.getVal() !== ' '){
              handleNonTextSearch(this.getSearchPath());
            } else {
              navigateToSearchResults();
            }
          } else {
            navigateToSearchResults();
          }
        }
      } else if (this.$form.is('.mini-booking') || ($('[name="destination"]').hasClass('hidden') && $_PAGE_.is('.rooms-rates-page'))) {
        window.digitalData.search.updateSearchData = true;
        prepareBookingBarValues();

        if ($_PAGE_.is('.rooms-rates-page') && (isMobileWidth()) ) {
          if (this._isIntlSearch || (this.destDropdownHandler && this.destDropdownHandler.getVal().trim() !== '')) {
            handleNonTextSearch(this.getSearchPath());
            return;
          } else {
            goToRoomsRates();
          }
        }  
        if (!($_PAGE_.is('.modify-page'))) {
          goToRoomsRates();
        } else {
          normalizeSearchCriteria();
          EventHandler.send('modify:set', null, getCriteria());
          EventHandler.send('modify:next');
        }
      }
    }
  }


  enableRecentAndFavourite() {
    const context = this;
    // Event handler for clicking on a favourite hotel
    $(document).on('mousedown', '.favouriteHotels li', (event) => {
      window.digitalData.search.searchInfo.searchQueryType = 'Favorite hotels';
      if(window._satellite) {
        window._satellite.track('searchQueryType');
      }
      const uniqueurl = $(event.currentTarget).data('uniqueurl');
      if (uniqueurl) {
        const currentURL = DOMPurify.sanitize(window.location.href);
        const urlObject = new URL(currentURL);
        const baseURL = `${urlObject.origin}/`;

        if (currentURL.includes('wyndham-rewards')) {
          window.location.href = baseURL + uniqueurl;
        } else {
          // Adding the url as absolute path and not the relative path
          // to make it work with the base url properly
          window.location.href = '/' + uniqueurl;
        }
      }
    });

    const _formatSecondaryDatesHandler = (selectedDate) => {
      // First, let's store the result of formatDateForPrinting and split operations in a variable
      const formattedDateParts = formatDateForPrinting(selectedDate, 'textMonth').split(' ');
      // If it's an array, we proceed with the filter operation
      if (Array.isArray(formattedDateParts)) {
        return formattedDateParts.filter((_, idx) => idx !== 3).join(' ');
      } else {
        return '';
      }
    };

    // Function to return today's date and tomorrow's date in the specified format
    const getTodayAndTomorrowDates = () => {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      const options = {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
      };
      const todayFormatted = today.toLocaleDateString('en-US', options);
      const tomorrowFormatted = tomorrow.toLocaleDateString('en-US', options);

      return { checkin: todayFormatted, checkout: tomorrowFormatted };
    };
    // Function to validate check-in and check-out dates
    const validateCheckinCheckout = (checkin, checkout) => {
      const today = new Date();
      const checkinDate = new Date(checkin);

      if (checkinDate < today) {
        return getTodayAndTomorrowDates();
      } else {
        return { checkin, checkout };
      }
    };
    // RECENT SEARCH CLICK ITEMS
    $(document).on('mousedown', '.recentSearchDropDown li', (event) => {
      window.digitalData.search.searchInfo.searchQueryType = 'Recent Searches';
      if(window._satellite) {
        window._satellite.track('searchQueryType');
      }
      const checkIn = $(event.currentTarget).data('checkin');
      const checkOut = $(event.currentTarget).data('checkout');
      const location = $(event.currentTarget).data('location');
      const selectedLocation = decodeURIComponent(location);
      context.location = { ...context.location, searched_temp: selectedLocation };
      $('.destination-input-container input.destination').val(context.location.searched_temp);
      const { checkin: newCheckin, checkout: newCheckout } = validateCheckinCheckout(checkIn, checkOut);
      context.bbDatePicker.checkInDate = getDateFromDateString(newCheckin);
      context.bbDatePicker.checkOutDate = getDateFromDateString(newCheckout);
      context.bbDatePicker._checkInSecondaryVal = _formatSecondaryDatesHandler(context.bbDatePicker.checkInDate);
      context.bbDatePicker._checkOutSecondaryVal = _formatSecondaryDatesHandler(context.bbDatePicker.checkOutDate);
      context.bbDatePicker.updateInputs();
      if (context.bbDatePicker.isSecondarySrpSearch) {
        context.bbDatePicker.updateOutputs(
          context.bbDatePicker._checkInSecondaryVal,
          context.bbDatePicker._checkOutSecondaryVal
        );
      } else {
        context.bbDatePicker.updateOutputs();
      }
    });
  }

  init(_disableBookingBarForSessionDataCall) {
    this.$0 = $('form.booking-bar-form');
    this.$mini = $('#bookingBar__mini');
    this.$destination = $('input.destination');
    this.$from = $('#from');
    this.$to = $('#to');
    this.$rooms = $('#numOfRooms');
    this.$adults = $('#numOfAdults');
    this.$children = $('#numOfChildren');
    this.$childAges = $('#childAges');
    this.$specialRateDrop = $('#special-rate-drop');
    this.$corpCodeData = $('#corp-code-data');
    this.$groupCodeData = $('#group-code-data');
    this.$rateCodeData = $('#rate-code-data');
    this.$promotionalCodeData = $('#promotional-code-data');
    this.$redeemWRPoint = $('#redeemWRPoint');
    this.$currentLocation = this.$0.find('button.location,a.location');

    if (_disableBookingBarForSessionDataCall) {
      EventHandler.one(EventHandler.criteria.init, () => this.buildFromSessionData());
      this.$0.find('button').attr('disabled', 'disabled');
    } else {
      this.buildFromSessionData();
      this.$0.removeClass('hide-on-load');
    }
    EventHandler.on(EventHandler.criteria.updated, () => this.buildFromSessionData());
    this.enableRecentAndFavourite();
    this.setFrom(getCriteria().checkInDate);
    $('.booking-dates .check-in .date, .mobile-dates .start-date').html(this.getCheckInDate());

    this.setTo(getCriteria().checkOutDate);
    $('.booking-dates .check-out .date, .mobile-dates .end-date').html(this.getCheckOutDate());

    this.$0.submit(async(e) => {
      e.preventDefault();
      // Construct the payload
      const dataPayload = {
        Location: this.getDestination(),
        CheckInDate: this.getFrom(),
        CheckOutDate: this.getTo(),
        // Add other necessary data here
      };

      // Call the manageFavoriteHotels function
      try {
        await manageFavoriteHotels('ADD', dataPayload, 'RecentSearches');
      } catch (error) {
        console.error('API Error:', error);
      }
      let $dropdownAR = $('#destAutocomplete'),
        data = $dropdownAR.find('ul li:not(.ui-menu-item-wrapper)').first().data('ui-autocomplete-item'),
        locationInfo = {};

      this.$form = $(e.target).closest('.booking-bar-form');
      this.buildChildAges();
      this.setDestination($_BOOKING_BAR_MAIN_.find('.destination').val());
      if (this.itemARSelected || (_isNotNull(getCriteria().searched_temp) && getCriteria().searched_temp == $_BOOKING_BAR_MAIN_.find('.destination').val())) {
        if (_isNotNull(getCriteria().searched_temp) && _isNotNull(getCriteria().loc_temp)) {
          locationInfo.searched = getCriteria().searched_temp;
          locationInfo.loc = getCriteria().loc_temp;
        }
        setLocationDetails(locationInfo);
        this.setSessionDataAndGoToSearch();



      } else if (_isNotNull(data)) {
        locationInfo.searched = _isNotNull(data.value) ? data.value : '';
        locationInfo.loc = _isNotNull(data.placeId) ? data.placeId : '';
        setLocationDetails(locationInfo);
        this.setDestination(data.label);
        $dropdownAR.find('ul').hide();
        this.setSessionDataAndGoToSearch();
      } else if (
        $(e.target).hasClass('booking-bar-main') &&
        ($_PAGE_.is('.property-page') || $_PAGE_.is('.rooms-rates-page'))
      ) {
        GoogleAPIHandler.getLocationDetailsFromAutocomplete(this.getDestination()).then(
          (l) => {
            locationInfo = l;
            locationInfo.searched = this.getDestination();
            setLocationDetails(locationInfo);
            this.setSessionDataAndGoToSearch();
          },
          () => {
            locationInfo.searched = '';
            locationInfo.location = '';
            locationInfo.loc = '';
            setLocationDetails(locationInfo);
            this.setSessionDataAndGoToSearch();
          }
        );
      } else if (_isNotNull(getCriteria().loc) && ($_PAGE_.is('.property-page') || $_PAGE_.is('.rooms-rates-page'))) {
        this.setSessionDataAndGoToSearch();
      } else {
        GoogleAPIHandler.getLocationDetailsFromAutocomplete(this.getDestination()).then(
          (l) => {
            locationInfo = l;
            locationInfo.searched = this.getDestination();
            setLocationDetails(locationInfo);
            this.setSessionDataAndGoToSearch();
          },
          () => {
            locationInfo.searched = '';
            locationInfo.location = '';
            locationInfo.loc = '';
            setLocationDetails(locationInfo);
            this.setSessionDataAndGoToSearch();
          }
        );
      }
    });

    this.enableAutoComplete();
    this.enableUserLocation();
    this.moveWRCheckbox();

    if (isHomepageExtendedHeroException() && !isTabletWidth()) {
      $(window).scroll(() => this.extendedHeroStickyBB());
      this.extendedHeroStickyBB();
    }
  }
  extendedHeroStickyBB() {
    if (isHomepageExtendedHeroException() && !isTabletWidth()) {
      let bb = $('.booking-bar-form.booking-bar-main');
      if($(window).scrollTop() >= $('.booking-bar-form.booking-bar-main').offset().top) {
        bb.addClass('extended-hero-sticky-bb');
        $_BOOKING_BAR_MAIN_.removeClass('add-top');
        $_BOOKING_BAR_MAIN_.css('top', 0);
      } else {
        bb.removeClass('extended-hero-sticky-bb');
        $_BOOKING_BAR_MAIN_.addClass('add-top');
      }
    }
  }
  isReady() {
    return (this._isReady === true);
  }
  errorDatePickerButtons(_removeError) {
    this.bbDatePicker.errorButtons(_removeError);
  }
  minLosIsNotMet() {
    return this.bbDatePicker._minLosIsNotMet();
  }
  isAvailabilityCalendarSetUp() {
    return this.bbAvailabilityCalendar && this.bbAvailabilityCalendar.isAvailabilityCalendarSetUp() || false;
  }
  toggleAvailabilityCalendarLinks(show) {
    this.bbAvailabilityCalendar.toggleAvailabilityCalendarLinks(show);
  }
}

/**
 * BBDestination
 * Booking bar destination dropdown class. Handle fetching of destination
 * list and population of the dropdown. Update parent BookingBarForm class
 * with user selections.
 */
class BBDestination {
  /**
   * Constructor. Get references to the booking bar dropdown elements and begin initialization
   * process.
   * @param {BBForm} bbForm - Reference to the parent BBForm object. Allows setting of destination and other variables from this encapsulated module.
   * @param {BBMini} bbMini - Reference to the BBMini object on pages with a mini booking bar. Null on other pages.
   */
  constructor(bbForm, bbMini) {
    this.bbForm = bbForm;
    this.bbMini = bbMini;

    this.destinationDropdown = {};
    // Get references to DOM elements
    this.$destinationDropdown = $('.booking-bar .destination-dropdown');
    this.$destinationDropdownContent = this.$destinationDropdown.find('.dropdown-content');
    this.$destinationDropdownButton = $('.booking-bar .destination-dropdown-button-container');
    this.$destinationDropdownButtonLabel = this.$destinationDropdownButton.find('.destination-dropdown-label');

    // Determine if dropdown is meant to show cities or properties
    this.dropdownType = this.$destinationDropdownButton.data('dropdown-type');
    this._isCityDisplay = this.dropdownType ? this.dropdownType.match(/city/i) : null;

    this.initializeDestinationDropdown(this.dropdownType);

    // Destination is saved as cookie on orientation change to ensure different displays are accommodated.
    //For mobile and tablet show dolce destination on orientation change
    if (getBrand() === 'DX' && (CookieHandler.readCookie('dolceOrientationChange') === 'dolce-Orientation-Change') && $('body').is('.ios,.iphone,.ipad,.ipod,.android')) {
      let dolceDestinationCookie, dolceName, dolceCity, dolceState;
      this.$destinationDropdownButtonLabel = $('.booking-bar .destination-dropdown-button-container .destination-dropdown-label');
      dolceDestinationCookie = JSON.parse(CookieHandler.readCookie('dolce-destination'));
      if (typeof dolceDestinationCookie === 'object' && !($.isEmptyObject(dolceDestinationCookie))) {
        if ('name' in dolceDestinationCookie) {
          dolceName = dolceDestinationCookie.name;
          dolceCity = dolceDestinationCookie.city;
          dolceState = dolceDestinationCookie.state;

          this.setDestination(dolceCity + ' ' + dolceState, true, () => {
            this.$destinationDropdownButtonLabel.text(dolceName);
            $('.destination-dropdown.dropdown-container a.destination-dropdown-property').filter(
              function() {
                return $(this).data('name') == dolceName;
              }
            )
              .addClass('active');
            Dropdown.publicCloseDropdown();
          });
          CookieHandler.eraseCookie('dolceOrientationChange');
        }
      }
    }
  }

  /**
   * Make a service call to AEM to retrieve the list of properties/locations to be
   * displayed.
   * @param {string} type - Dropdown type. Should be either 'city' or 'property'
   */
  initializeDestinationDropdown(type) {
    callService('brand' + uppercaseFirstLetter(type) + 'PageListing', {
      locale: _LOCALE_,
      brandId: getBrand()
    }).then((r) => this.handleDestinationDropdown(r));
  }

  /**
   * Set text of the destination dropdown button to a given location.
   * @param {string} text - Text describing the selected location
   */
  setSelectedText(text) {
    this.$destinationDropdownButtonLabel.text(text);
  }

  /**
   * Handle population and event binding for the destination dropdown based on the service response.
   * @param {object} r - Response object from the brandPropertyPageListing or brandCityPageListing services
   */
  handleDestinationDropdown(r) {
    let totalItems = r.size;

    this.activePropertyFound = false;

    // Iterate subcategories returned from service response
    if (r && r.subcategory) {
      for (let i = 0; i < r.subcategory.length; i++) {
        let country = r.subcategory[i].name,
          properties = r.subcategory[i].properties,
          hasProperties = (_isNotNull(properties) && properties.length),
          states = r.subcategory[i].subcategory,
          hasStates = (_isNotNull(states) && states.length),
          $c = $(document.createElement('div')).addClass('destination-dropdown-country headline-g')
            .text(country);

        // Add country name to dropdown and increment number of items
        this.$destinationDropdownContent.append($c);
        ++totalItems;

        if (hasProperties) {
          // If country has a direct list of properties, get them
          this.getProperties(properties);
        } else if (hasStates) {
          // Otherwise populate state names and then get properties for the state
          for (let j = 0; j < states.length; j++) {
            let state = states[j];

            if (_isNotNull(state.properties) && state.properties.length) {
              let $s = $(document.createElement('div')).addClass('destination-dropdown-state')
                .text(state.name);

              this.$destinationDropdownContent.append($s);
              ++totalItems;

              this.getProperties(state.properties);
            }
          }
        }
      }
    }
    // if dropdown does not have any selected value on overview page/R and R page, then match the drop down with the AEM data
    if (!(this.activePropertyFound) && ($_PAGE_.is('.property-page'))) {
      // propertyInfo object is populated on AEM property pages
      if (this._isCityDisplay && window.propertyInfo && window.propertyInfo.propertyCity && _isNotNull(window.propertyInfo.propertyCity)) {
        let $ele = this.$destinationDropdownContent.find('a[data-name=\'' + window.propertyInfo.propertyCity + '\']');
        this.setDestinationDropdownValueAndClose($ele);
      }
    } else if (($_PAGE_.is('.rooms-rates-page')) && !(this.activePropertyFound) && _isNotNull(property_city_name)) {
      // property_city_name string is populated on AEM rooms and rates pages
      let $ele = this.$destinationDropdownContent.find('a[data-name=\'' + property_city_name + '\']');
      this.setDestinationDropdownValueAndClose($ele);
    }
    // For non-mobile widths, sort the dropdown contents into 3 columns. Mobile should display as a list.
    if (!isMobileWidth()) {
      let perColumn = Math.ceil(totalItems / 3),
        numProcessed = 0,
        $col1 = $(document.createElement('div')).addClass('destination-dropdown-column')
          .css('float', 'left'),
        $col2 = $(document.createElement('div')).addClass('destination-dropdown-column')
          .css('float', 'left'),
        $col3 = $(document.createElement('div')).addClass('destination-dropdown-column')
          .css('float', 'left');

      if (this.$destinationDropdownContent) {
        this.$destinationDropdownContent.children().each((i, el) => {
          // Caesars-specific logic - if the column is full and the next item is a link,
          // allow it to fit in the same column as its parent state
          if (numProcessed % perColumn === 0 && $(el).is('a')) {
            ++perColumn;
          }

          // Fill columns one at a time and tag a column full when it reaches the perColumn limit
          if ($col1.children().length < perColumn && !(_isWeakTrue($col1.data('full')))) {
            $col1.append($(el).detach());
          } else if ($col2.children().length < perColumn && !(_isWeakTrue($col2.data('full')))) {
            $col1.attr('data-full', true);

            $col2.append($(el).detach());
          } else if ($col3.children().length < perColumn) {
            $col2.attr('data-full', true);

            $col3.append($(el).detach());
          }

          ++numProcessed;
        });
        // Add all columns to dropdown
        this.$destinationDropdownContent.append($col1).append($col2)
          .append($col3);
      }

      // Find the tallest column and set the dropdown height to encapsulate it.
      let heights = this.$destinationDropdownContent.find('> div').map((i, el) => {
          return parseInt($(el).outerHeight());
        })
          .get(),
        max = Math.max.apply(null, heights);
        let alertHeight = 0;
        if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
          alertHeight = $('.alert-component').height();
        }

      $('.booking-bar .destination-dropdown').hide()
        .find('.dropdown-content,.dropdown-content > div')
        .css({
          height: max < 220 ? 'auto' : ((max > alertHeight) ? max - alertHeight : 'auto')
      });
      $('.booking-bar .destination-dropdown').find('.dropdown-overflow').css({
        'overflow-y': 'auto'
      });
    } else {
      // Height and positioning calculations for dropdown mobile view
      let contentHeight = this.$destinationDropdown.find('.dropdown-overflow').outerHeight(),
        viewportHeight = getWindowHeight() - this.$destinationDropdown.find('.save-cancel-container').outerHeight();

      this.$destinationDropdown.find('.dropdown-overflow').css({
        top: (0 - contentHeight)
      });

      if (contentHeight > viewportHeight) {
        this.$destinationDropdownButton.on('dropdown:opening', () => {
          this.$destinationDropdown.find('.dropdown-overflow').css({
            maxHeight: viewportHeight,
            overflowY: 'scroll'
          });
        });
      }
    }
    // Bind events for all links
    $('.booking-bar .destination-dropdown a').click((e) => {
      this.setDestinationDropdownValueAndClose($(e.target), e);
      this.clearDestinationDropdownError();
    });
    this.$destinationDropdown.find('.btn-cancel').click(() => {
      Dropdown.publicCloseDropdown();
    });
    // Search all hotels should redirect to root wyndhamhotels.com site
    this.$destinationDropdown.find('.search-all-hotels-link').click(function(e) {
      e.preventDefault();

      updateQueryParameter('referringBrand', getBrand(true));
      updateQueryParameter('referringTier', getBrandTier());

      window.location.replace($(e.currentTarget).attr('href') + '?' + getQueryString());
    });
    // Set destination to empty if no active selection is found.
    if (!(exists(this.$destinationDropdown.find('a.active')))) {
      this.bbForm.setDestination('');
    }
  }
  /**
   * Utility function to convert an array of properties into hotel links. Property list
   * can be either directly under a country or shown under a state subheading.
   * @param {Array[object]} propArray Array of property objects from AEM to populate into the dropdown
   * @param {*} $el Dropdown element to populate with hotel elements
   */
  getProperties(propArray, $el) {
    if (_isNull($el)) {
      $el = this.$destinationDropdownContent;
    }

    let oldName;
    // Iterate array and generate link elements for each non-null, non-duplicate entry
    for (let j = 0; j < propArray.length; j++) {
      // Skip duplicates (assumes response from AEM is sorted)
      if (_isNotNull(oldName) && oldName === propArray[j].name) {
        continue;
      }

      let prop = propArray[j];

      if (_isNotNull(prop.path)) {
        let $a = $(document.createElement('a'))
          .addClass('destination-dropdown-property')
          .text(!(this._isCityDisplay) ? prop.name : prop.city)
          .attr('href', prop.path)
          .attr('data-name', !(this._isCityDisplay) ? prop.name : prop.city)
          .attr('data-city', prop.city)
          .attr('data-state', prop.state)
          .attr('data-propId', prop.propertyId);

        $el.append($a);
        // Check if this property is the active one and set the dropdown value.
        if (!(this.activePropertyFound) && this.isActivePropertyOrCity(prop.name, prop.city, prop.propertyId)) {
          this.activePropertyFound = true;

          this.setDestinationDropdownValueAndClose($a);
        }
        // Set oldName to last rendered property. If next is duplicate, it will be skipped.
        oldName = prop.name;
      }
    }
  }
  /**
   * Determine if the provided destination entry is the currently-selected property or city.
   * @param {string} name - Property name to check
   * @param {string} city - City name to check
   */
  isActivePropertyOrCity(name, city, propId) {
    let found = false,
      sessionCity = getLocationDetails('city') || '',
      sessionName = getLocationDetails('location') || '';

    if ((!this._isCityDisplay)) {
      // For property dropdown, check if the current property variables match or if the session
      // destination value is the same as the property name
      if (_isNotNull(overview_propertyName) && name.match(overview_propertyName) && _isNotNull(overview_propertyId) && (propId == overview_propertyId)) {
        found = true;
      } else if (name === sessionName.trim()) {
        found = true;
      }
    } else {
      // For city dropdown, check if the city name matches the session's city name.
      if (city.replace(/[.-]/g, '').toLowerCase() === (sessionCity.trim()).replace(/[.-]/g, '')
        .toLowerCase()) {
        found = true;
      }
    }

    return found;
  }

  /**
   * Set the dropdown state to indicate the selected dropdown item. Also
   * update the parent BBForm with the destination information selected.
   * @param {Element} $el - jQuery element that was selected
   * @param {Event} e - Event object
   */
  setDestinationDropdownValueAndClose($el, e) {
    if (_isNotNull(e)) {
      e.preventDefault();
    }

    let path = $el.attr('href'),
      city = $el.data('city') || '',
      state = $el.data('state') || '',
      name = !(this._isCityDisplay) ? $el.data('name') : city + ', ' + state;
    // Set destination value on the parent BBForm
    this.bbForm.setDestination(city + ' ' + state, true, () => {
      // Update DOM elements to show selected location
      this.$destinationDropdownButtonLabel.text(name);
      this.$destinationDropdownButtonLabel.data('city', city);
      // Update internal destinationDropdown object for future reference
      this.destinationDropdown.val = city + ' ' + state;
      this.destinationDropdown.path = path;
      this.destinationDropdown.city = city;
      this.destinationDropdown.location = name;

      Dropdown.publicCloseDropdown();
    });
    // Remove old active link styles
    this.$destinationDropdownContent.find('a').removeClass('active');

    //save dolce destination for every selection for mobile and tablet
    if (getBrand() === 'DX' && $('body').is('.ios,.iphone,.ipad,.ipod,.android')) {
      let dolceDestination = {
        name: name,
        path: path,
        city: city,
        state: state
      };
      if (dolceDestination) {
        let oldDolceCookie = CookieHandler.readCookie('dolce-destination');
        if (oldDolceCookie && (oldDolceCookie.toString().length > 0)) {
          CookieHandler.eraseCookie('dolce-destination');
        }
        CookieHandler.createCookie('dolce-destination', JSON.stringify(dolceDestination));
      }
    }
    // Remove event handlers and tag selected link as active
    $el.off('focusout').off('mouseleave')
      .addClass('active');
  }

  /**
   * Clear any error indicators in the destination dropdown.
   */
  clearDestinationDropdownError() {
    this.$destinationDropdownButton.find('div.error-msg').remove();
    this.$destinationDropdownButton.find('button').removeClass('error');
    this.$destinationDropdownButtonLabel.removeClass('error-msg');
  }

  /**
   * Add an error message and highlight to the destination dropdown. To be called
   * if the user attempts to navigate without selecting a destination.
   */
  addError() {
    let $err = $(document.createElement('div')).addClass('error-msg')
      .text(this.$destinationDropdownButton.data('required-message'));

    this.$destinationDropdownButton
      .append($err)
      .one('click', () => this.clearDestinationDropdownError())
      .find('button')
      .addClass('error');

    this.$destinationDropdownButtonLabel.addClass('error-msg');
  }
  /**
   * Gets the destination value (typically the city or property name selected)
   */
  getVal() {
    return this.destinationDropdown.val;
  }
  /**
   * Gets the path associated with the selected destination.
   */
  getPath() {
    return ('val' in this.destinationDropdown) ? this.destinationDropdown.path : '';
  }
  /**
   * Clear the destination dropdown. Called if user selects the option to use their current location
   * or otherwise selects a destination outside of the dropdown.
   */
  deleteVal() {
    delete(this.destinationDropdown.val);
  }
}

/**
 * International booking bar handler class. Contains all logic
 * for fetching available international properties based on the current
 * language as well as population of booking bar dropdowns and outputting
 * the chosen values and search URL to other components.
 */
class BBInternational {
  /**
   * Initialize the International Booking Bar class for the
   * provided locale.
   * @param bbForm - Reference to the parent booking bar form class
   * @param locale - Locale code to populate the international booking bar with
   */
  constructor(bbForm, bbMini, locale) {
    this.bbForm = bbForm;
    this.bbMini = bbMini;
    this.locale = locale;

    // Get references to dropdown buttons and lists
    this.$intlCountryDropdownButton = $('.booking-bar .country-dropdown-button');
    this.$intlCityDropdownButton = $('.booking-bar .city-dropdown-button');
    this.$intlHotelDropdownButton = $('.booking-bar .hotel-dropdown-button');
    this.$intlCountryDropdownList = $('.booking-bar .country-dropdown ul');
    this.$intlCityDropdownList = $('.booking-bar .city-dropdown ul');
    this.$intlHotelDropdownList = $('.booking-bar .hotel-dropdown ul');

    // Data object containing selected values and search URL
    this.intlDropdowns = {};

    // Trigger service call to populate dropdowns
    this.initializeInternationalSearch();
  }

  /**
   * Initialize international search. Make service call with page
   * locale to retrieve list of properties, then populate
   */
  initializeInternationalSearch() {
    if ($_PAGE_.is('.homepage') && window.bookingBarSetTop) {
      window.bookingBarSetTop(true);
    }
    callService('intlLocations', {
      language: this.locale
    }).then((res) => {
      if (res && res.statusCode === '200' && res.locationDetails) {
        setIntlLocationInfo(res.locationDetails);
        this.handleInternationalSearch(res.locationDetails);
      } else {
        EventHandler.triggerEvent('brands-error', res);
        EventHandler.send(EventHandler.getLocByLanguage.error);
      }
    });
  }

  /**
   * Populate all dropdowns with content from the service response.
   * @param serviceResponse - JSON tree containing a list of countries, states, cities, and properties.
   */
  handleInternationalSearch(serviceResponse) {
    let locationDetails = {
      city: getCriteria().inLanguageCity,
      country: getCriteria().inLanguageCountry
    };

    // Generate dropdown items and bind events to filter next dropdown
    this.populateDropdownTree(serviceResponse, locationDetails);
    this.bindDropdownEvents();
  }
  /**
   * Expand the booking bar to show all hidden elements. Triggers on interaction
   * with the booking bar to reveal hotel list, party mix, and special rates dropdowns
   */
  expandIntlBookingBar() {
    if (isDesktopWidth() && !$_BOOKING_BAR_MAIN_.is('.expanded')) {
      // Booking bar in extended hero needs to slide upward while slideDown animation occurs
      // Simulates a "slide up" of the booking bar (bottom of bar stays in the same place)
      if (($_PAGE_.is('.homepage') || exists($_BOOKING_BAR_MAIN_.parents('.extended-hero-component'))) &&
        !$_BOOKING_BAR_MAIN_.is('.stick') && !$_BOOKING_BAR_MAIN_.data('positioned')) {
        // Set data-positioned after animation to avoid repeated movement upward
        let alertHeight = 0;
        if ($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
          alertHeight = $('.alert-component').height() + 7.5;
        }
        let calStr = 'calc(80vh - ' + (alertHeight + getHeaderHeight()) + 'px)';
        $('.booking-bar.intl-search .intl-dropdown ul').css({
          'max-height': calStr
        });
        $_BOOKING_BAR_MAIN_.animate({
          top: alertHeight + getCSSPropertyAsInt($_BOOKING_BAR_MAIN_, 'top') - $('.booking-bar .hide-desktop').height() - $('.booking-bar .search-in-en').height()
        }, 250).data('positioned', true);
      }
      // On UU pages, global booking bar overlays the mini booking bar. Need to animate its
      // height to match the full booking bar's increased height.
      if ($_PAGE_.is('.uu-property,.uu-overview')) {
        $_BOOKING_BAR_MINI_NAV.data('orig-height', getElTrueHeight($_BOOKING_BAR_MINI_NAV)).animate({
          height: getElTrueHeight($_BOOKING_BAR_MAIN_) + getElTrueHeight($('.booking-bar .hide-desktop')) + getElTrueHeight($('.search-in-en'))
        }, 250);
      }
      // On homepage, booking bar docks to the header. If the bar is in this state, need
      // to resize the header temporarily to contain the expanded booking bar.
      if ($_PAGE_.is('.homepage') && $_BOOKING_BAR_MAIN_.is('.stick')) {
        $_HEADER_.data('orig-height', $_HEADER_.height()).animate({
          height: getCSSPropertyAsInt($_BOOKING_BAR_MAIN_, 'height') +
            getElTrueHeight($('.hide-desktop')) + getElTrueHeight($('.search-in-en')) +
            getCSSPropertyAsInt($_BOOKING_BAR_MAIN_, 'top') + 10
        }, 250);
      }
      // Slide down the hidden booking bar elements and add expanded class
      $('.booking-bar .hide-desktop').slideDown(250);
      $_BOOKING_BAR_MAIN_.addClass('expanded');
    }
  }
  /**
   * Collapse the international booking bar. Typically triggered by user scrolling
   * away from the booking bar.
   * @param cb - Callback function to execute once the collapse animation has completed
   */
  collapseIntlBookingBar(cb) {
    if (isDesktopWidth() && $_BOOKING_BAR_MAIN_.is('.expanded')) {
      // Slide up elements that should be hidden, execute callback when finished
      $('.booking-bar .hide-desktop').slideUp(250, cb);
      // Remove expanded and positioned tags to indicate booking bar is collapsed
      $_BOOKING_BAR_MAIN_.removeClass('expanded').data('positioned', false);
      // Reset booking bar top position in extended hero component
      if (exists($_BOOKING_BAR_MAIN_.parents('.extended-hero-component'))) {
        $_BOOKING_BAR_MAIN_.animate({
          top: 0
        }, 250);
      }
      // Reset header height if is was adjusted
      if ($_HEADER_.data('orig-height')) {
        $_HEADER_.height($_HEADER_.data('orig-height')).data('orig-height', null);
      }
      // Reset mini booking height on UU pages if adjusted
      if ($_PAGE_.is('.uu-property,.uu-overview')) {
        $_BOOKING_BAR_MINI_NAV.animate({
          height: $_BOOKING_BAR_MINI_NAV.data('orig-height')
        }, 250);
      }
    } else {
      // Run callback for tablet/mobile as well
      if (typeof cb === 'function') {
        cb();
      }
    }
  }

  /**
   * Check that all necessary selections (country and city) have been made
   * before navigating forward. Flag invalid fields if not selected.
   */
  checkForErrors() {
    if (_isNull(this.intlDropdowns.countryText)) {
      this.$intlCountryDropdownButton.addClass('error error-msg').click(this.clearIntlDropdownErrors);
    } else if (_isNull(this.intlDropdowns.cityText)) {
      this.$intlCityDropdownButton.addClass('error error-msg').click(this.clearIntlDropdownErrors);
    }
  }
  /**
   * Clear errors from dropdown when user interacts with them.
   */
  clearIntlDropdownErrors() {
    this.$intlCountryDropdownButton.removeClass('error error-msg');
    this.$intlCityDropdownButton.removeClass('error error-msg');
  }

  /**
   * Disable dropdowns if the preceding option has not yet been selected.
   * Cities cannot be selected without a country, properties cannot be selected
   * without a city.
   */
  disableDropdowns() {
    if (!this.intlDropdowns.countryText) {
      this.$intlCityDropdownButton.attr('disabled', true);
    }

    if (!this.intlDropdowns.cityText) {
      this.$intlHotelDropdownButton.attr('disabled', true);
    }
  }

  /**
   * Return the URL for the search results or property page related to the user's search
   */
  getSearchUrl() {
    return this.intlDropdowns.searchUrl;
  }

  // ---------------------v DROPDOWN POPULATION AND EVENT BINDING v-----------------------------

  populateDropdownTree(serviceResponse, locationDetails) {
    // Generate dropdown lists, depth-first
    this.populateCountries(serviceResponse.countries, locationDetails);
  }
  /**
   * Generate a list of countries to be displayed in the booking bar country dropdown.
   * For each country, use its child state/city list to generate a list of cities.
   * @param countries - Array of country objects to convert to DOM elements
   */
  populateCountries(countries, locationDetails) {
    // Alphabetical country name display
    sortObjectList(this.locale, countries, 'countryName');

    let countryElements = countries.map((country) => {
      let countryListEl = document.createElement('li');
      countryListEl.textContent = country.countryName;
      countryListEl.dataset.key = country.key;

      if (locationDetails.country && country.countryName === locationDetails.country) {
        this.activeCountry = countryListEl;
      }

      this.populateStates(country.states, country.countryName, countryListEl, locationDetails);

      return countryListEl;
    });

    this.$intlCountryDropdownList.append(countryElements);
  }
  /**
   * Based on the provided stateList, determine whether to populate state elements
   * or not. Then proceed to populate city list with or without state names.
   * @param stateList - List of states to populate. Each state should contain a list of cities.
   * @param parentCountry - Parent country of the states list.
   * @param countryListEl - Reference to the parent country DOM element.
   */
  populateStates(stateList, parentCountry, countryListEl, locationDetails) {
    // Show state elements if there is more than 1 state or the single state is not "**"
    let showStateElements = !(stateList.length === 1 && (stateList[0].key || stateList[0].stateName).match(/\*/));
    // DAI-1496: Add exception to States-Cities grouping for DE-DE locale.
    showStateElements = !(this.locale === 'de-de');

    if (showStateElements) {
      this.populateStatesCitiesAlphabetical(stateList, parentCountry, countryListEl, locationDetails);
    } else {
      this.populateCitiesAlphabetical(stateList, parentCountry, countryListEl, locationDetails);
    }
  }

  /**
   * Generate a list of state elements and a list of cities under each state.
   * States and cities should each be listed in alphabetical order.
   * @param stateList - List of states to populate. Each state should contain a list of cities.
   * @param parentCountry - Country containing the listed states/cities.
   * @param countryListEl - Reference to the parent country DOM element.
   */
  populateStatesCitiesAlphabetical(stateList, parentCountry, countryListEl, locationDetails) {
    // skip sorting for zh-cn
    if (this.locale !== 'zh-cn') {
      // Sort states by name
      sortObjectList(this.locale, stateList, 'stateName');
    }
    $.each(stateList, (i, state) => {
      // Add a divider for each state
      let stateListEl = document.createElement('li');
      stateListEl.textContent = state.stateName;

      // Set divider data
      stateListEl.dataset.key = state.key;
      stateListEl.dataset.country = parentCountry.replace(/\s/g, '-');

      stateListEl.className = 'state-el';
      // Append divider before populating cities
      this.$intlCityDropdownList.append(stateListEl);

      // Each state's cities should be individually alphabetized
      this.populateCitiesFromList(state.cities, parentCountry, countryListEl, state, locationDetails);
    });
  }

  /**
   * Generate a list of city elements from all containing states. Cities should be
   * alphabetically sorted regardless of their parent state. No divider or other indication
   * of the parent state should be present.
   * @param stateList - List of states containing cities to be populated into the city dropdown
   * @param parentCountry - Name of the parent country
   * @param countryListEl - Reference to the parent country DOM element.
   */
  populateCitiesAlphabetical(stateList, parentCountry, countryListEl, locationDetails) {
    let cityList = [];
    // Build a unique list of cities. Some cities may be repeated across multiple states.
    // Child properties of each repeat city entry should be consolidated.
    $.each(stateList, (i, state) => {
      $.each(state.cities, (j, city) => {
        let cityReplaceIndex = cityList.findIndex((el) => {
          return el.key === city.key;
        });

        if (cityReplaceIndex === -1) {
          // City has not been added to the list
          city.stateName = state.stateName;
          cityList.push(city);
        } else if (city.cityName !== city.cityNameEN) {
          // If cityName and cityNameEN don't match, this is the translated city.
          city.stateName = state.stateName;
          city.properties = city.properties.concat(cityList[cityReplaceIndex].properties);
          cityList[cityReplaceIndex] = city;
        } else {
          // City is a repeat. Append the properties to the current city entry in the list.
          cityList[cityReplaceIndex].properties = cityList[cityReplaceIndex].properties.concat(city.properties);
        }
      });
    });
    // Populate cities in the cityList
    this.populateCitiesFromList(cityList, parentCountry, countryListEl, null, locationDetails);
  }

  /**
   * Generic city population logic. This function accepts a list of cities (either a small
   * sublist for a given state or a list of all cities in the country) and creates list items
   * in the city dropdown for them.
   * @param cityList - List of city objects to be populated
   * @param parentCountry - Name of the parent country of all cities in the list
   * @param countryListEl - Reference to the DOM element of the parent country.
   * @param state - Name of the state the cities belong to. Null for cities without a common parent state.
   */
  populateCitiesFromList(cityList, parentCountry, countryListEl, state, locationDetails) {
    // skip sorting for zh-cn
    if (this.locale !== 'zh-cn') {
      // Sort cities by name
      sortObjectList(this.locale, cityList, 'cityName');
    }
    $.each(cityList, (i, city) => {
      let cityListEl = document.createElement('li');
      let country = parentCountry.replace(/\s/g, '-');
      cityListEl.textContent = city.cityName;

      // Set city data attributes
      cityListEl.dataset.country = country;
      cityListEl.dataset.state = state ? state.stateName.replace(/\s/g, '-') : city.stateName.replace(/\s/g, '-');
      cityListEl.dataset.searchUrl = city.searchResultsURL;
      cityListEl.dataset.key = city.key;

      this.$intlCityDropdownList.append(cityListEl);
      if (locationDetails.city && this.activeCountry === countryListEl && city.cityName === locationDetails.city) {
        this.activeCity = cityListEl;
      }

      this.populateHotels(city.properties, city, country, cityListEl);
    });
  }
  /**
   * Populate hotel list for a given city.
   * @param hotelList - List of hotels to populate
   * @param parentCity - Name of the city containing these hotels
   * @param parentCountry - Name of the country containing these hotels
   * @param cityListEl - Reference to the DOM elemnt of the parent city
   */
  populateHotels(hotelList, parentCity, parentCountry, cityListEl) {
    // Sort hotels alphabetically
    sortObjectList(this.locale, hotelList, 'propertyName');
    $.each(hotelList, (i, hotel) => {
      let hotelListEl = document.createElement('li');
      hotelListEl.textContent = hotel.propertyName;

      // Set hotel data attributes
      hotelListEl.dataset.country = parentCountry;
      hotelListEl.dataset.city = parentCity.cityName.replace(/\s/g, '-');
      hotelListEl.dataset.searchUrl = hotel.uniqueURL;
      hotelListEl.dataset.key = hotel.key;

      this.$intlHotelDropdownList.append(hotelListEl);

      if (overview_propertyId && hotel.propertyId === overview_propertyId && this.activeCity === cityListEl) {
        this.activeHotel = hotelListEl;
      }
    });
  }

  /**
   * Bind click events to all dropdown items. Update user's selection object and filter
   * subsequent dropdowns as user interacts with country, city and property dropdowns.
   */
  bindDropdownEvents() {
    // All list items should set the dropdown text and close the current dropdown.
    $('.booking-bar .intl-dropdown li:not(.state-el)').click((e) => {
      $(e.currentTarget).parent()
        .data('selected', $(e.currentTarget).text());
      $($(e.currentTarget).parents('.dropdown-container')
        .data('button')).text($(e.currentTarget).text());

      Dropdown.publicCloseDropdown();
    });

    // On country select, filter cities dropdown to show only cities in the given country.
    // Enable city dropdown and set criteria with country name.
    this.$intlCountryDropdownList.find('li').click((e) => {
      let el = e.target;
      this.$intlCityDropdownButton.removeAttr('disabled');
      this.intlDropdowns.countryText = el.dataset.key;

      setCriteria({
        inLanguageCountry: el.textContent
      });

      this.filterCityList(el.textContent);
    });

    // On city select, filter hotels dropdown to show only cities in the given country.
    this.$intlCityDropdownList.find('li:not(.state-el)').click((e) => {
      let el = e.target;
      this.$intlHotelDropdownButton.removeAttr('disabled');

      this.intlDropdowns.cityText = el.dataset.key;
      setCriteria({
        inLanguageCity: el.textContent,
        inLanguageState: el.dataset.state === '**' ? '' : el.dataset.state.replace('-', ' ')
      });
      this.bbForm.setDestination(this.intlDropdowns.cityText + ', ' + this.intlDropdowns.countryText, true, () => {
        this.intlDropdowns.location = this.intlDropdowns.cityText + ' ' + this.intlDropdowns.countryText;
        $.extend(this.intlDropdowns, el.dataset);
      });

      this.filterHotelList(el.textContent, el.dataset.country);
    });

    // On hotel select, update the destination value and include the hotel in the intlDropdowns object
    this.$intlHotelDropdownList.find('li').click((e) => {
      let el = e.target;
      this.intlDropdowns.hotelText = el.dataset.key;
      this.bbForm.setDestination(this.intlDropdowns.cityText + ', ' + this.intlDropdowns.countryText, true, () => {
        $.extend(this.intlDropdowns, el.dataset);
      });
    });

    // Trigger clicks for active elements as determined during generation
    if (this.activeCountry) this.activeCountry.click();
    if (this.activeCity) this.activeCity.click();
    if (this.activeHotel) this.activeHotel.click();

    // Cancel buttons in mobile dropdown view should close the dropdown
    $('.booking-bar .intl-dropdown .btn-cancel').click(() => {
      Dropdown.publicCloseDropdown();
    });

    // On interaction with country/city buttons or calendar buttons, expand the booking bar to show all elements
    $('.booking-bar.intl-search .country-dropdown-button, .booking-bar.intl-search .city-dropdown-button, .booking-bar.intl-search .calendar-button').on('dropdown:opening', () => {
      this.expandIntlBookingBar();
    });
  }

  /**
   * Filter city list by the selected country. All states/cities that are not in the
   * parent country will be hidden from view.
   * @param selectedCountry - User-selected country name by which to filter cities.
   */
  filterCityList(selectedCountry) {
    // Hide all hotels and disable the hotel dropdown until a city is selected.
    this.filterHotelList('', '');
    this.$intlHotelDropdownButton.attr('disabled', true);
    // Hide all list items except ones within the selected country.
    let visibleElements = this.$intlCityDropdownList.find('li')
      .hide()
      .filter((i, el) => {
        return $(el).data('country') === selectedCountry.replace(/\s/g, '-');
      })
      .show();

    // Determine if any states are shown. If so, add has-states class.
    let statesVisible = this.$intlCityDropdownList.find('.state-el').filter((i, el) => {
      return $(el).css('display') !== 'none';
    }).length;
    if (statesVisible) {
      this.$intlCityDropdownList.addClass('has-states');
    } else {
      this.$intlCityDropdownList.removeClass('has-states');
    }
    // Reset city button text to default label
    this.$intlCityDropdownButton.text(this.$intlCityDropdownButton.data('unselectedName'));

    // If we reset the list, submission should be disabled.
    this.intlDropdowns.city = null;
    this.intlDropdowns.cityText = null;
    this.intlDropdowns.searchUrl = null;

    // If only one city is available, auto-select it
    if (visibleElements.not('.state-el').length === 1) {
      visibleElements.not('.state-el').click();
    }
  }

  /**
   * Filter hotel list by the selected city and country.
   * All properties not within the selected city will be hidden.
   * @param selectedCity - Name of the user-selected city
   * @param selectedCountry - Name of the user-selected country
   */
  filterHotelList(selectedCity, selectedCountry) {
    // Hide all properties except for the ones within the given city
    let visibleElements = this.$intlHotelDropdownList.find('li')
      .hide()
      .filter((i, el) => {
        return $(el).data('city') === selectedCity.replace(/\s/g, '-') &&
                $(el).data('country') === selectedCountry.replace(/\s/g, '-');
      })
      .show();
    // Reset dropdown button text to default label
    this.$intlHotelDropdownButton.text(this.$intlHotelDropdownButton.data('unselectedName'));
    this.intlDropdowns.hotel = null;
    this.intlDropdowns.hotelText = null;

    // If only one property is available, auto-select it.
    if (visibleElements.length === 1) {
      visibleElements.click();
    }
  }
}

/**
 * BBDatePicker
 * Handles date picker logic for selecting check in and check out
 * dates using the jQuery UI datepicker.
 */
class BBDatePicker {
  constructor(bbForm, bbMini) {
    this.bbForm = bbForm;
    this.bbMini = bbMini;

    this._btnGroup = '.btn-group';
    this._buttonCheckIn = '.check-in-button';
    this._buttonCheckOut = '.check-out-button';
    this._container = '.booking-dates-dropdown';
    this._datepicker = '.datepicker-container';
    this._saveCancel = '.save-cancel-container';
    this._save = '.save';
    this._cancel = '.cancel';
    this.$btnGroup = $(this._btnGroup);
    this.$buttonCheckIn = $(this._buttonCheckIn);
    this.$buttonCheckOut = $(this._buttonCheckOut);
    this.$formWas = null;
    this.$form = isVisible($_BOOKING_BAR_MAIN_) ? $_BOOKING_BAR_MAIN_.parent() : exists($_BOOKING_BAR_MINI_NAV) ? $_BOOKING_BAR_MINI_NAV.parent() : null;
    this.$container = null;
    this.$datepicker = null;
    this.$saveCancel = null;
    this.$save = null;
    this.$cancel = null;
    this.$checkIn = $('.booking-dates .check-in .date, .mobile-dates .start-date');
    this.$checkInCollapsed = $('.dates-col .check-in .date');
    this.$checkOut = $('.booking-dates .check-out .date, .mobile-dates .end-date');
    this.$checkOutCollapsed = $('.dates-col .check-out .date');
    this.$placeholder = $('#miniBookingHeight');
    this.checkInDate = getDateFromDateString(this.bbForm.getFrom());
    this.checkOutDate = getDateFromDateString(this.bbForm.getTo());
    this._initialCheckIn = this.checkInDate;
    this._initialCheckOut = this.checkOutDate;
    this._currentButton = null;
    this._autoScroll = 0;
    this.minLos = 1;
    this.checkinFlag = false;
    this.checkoutFlag = false;
    this.isSecondarySrpSearch = $('.secondary-form').find('.booking-bar-main').length > 0 || false;
    this._checkInSecondaryVal = '';
    this._checkOutSecondaryVal = '';


    this.onResizeEnd();
    // Function to update the check-in and check-out dates
    function updateSrpSecondaryObj() {
      const checkInDate = this.bbForm.getCheckInDate().split(' ');
      const checkOutDate = this.bbForm.getCheckOutDate().split(' ');

        this._checkInSecondaryVal = Array.isArray(checkInDate)
          ? checkInDate.filter((_, idx) => idx !== 3).join(' ')
          : '',
          this._checkOutSecondaryVal = Array.isArray(checkOutDate)
          ? checkOutDate.filter((_, idx) => idx !== 3).join(' ')
          : '',


        this.updateOutputs(this._checkInSecondaryVal, this._checkOutSecondaryVal);
      }

    // Function to check if check-in date is loaded
    function waitForCheckInDate(callback) {
      const interval = setInterval(() => {
        if (this.bbForm.getCheckInDate()) {
          clearInterval(interval);
          callback();
        }
      }, 100);
    }

    // Call the function once check-in date is loaded
    if (this.isSecondarySrpSearch) {
      waitForCheckInDate.call(this, updateSrpSecondaryObj.bind(this));
      // Create the span element
      const dateHyphen = $(`<span class="date-hyphen ${window.brand} hidden-sm hidden-xs">-</span>`);
      // Insert it after the check-in button
      this.$buttonCheckIn.after(dateHyphen);

      const datesContainerLarge = ['umbrella', 'microtel', 'hawthorn-extended-stay', 'wyndham-garden', 'wyndham', 'trademark', 'wyndham-grand'];
      const datesContainerMedium = ['hojo'];
      const datesContainerSmall = ['super-8', 'travelodge', 'laquinta', 'wingate', 'days-inn', 'baymont', 'americinn', 'ramada', 'tryp'];
      const datesContainerSmaller = ['tryp', 'vienna-house'];
      const datesContainerSmallest = ['esplendor', 'dazzler', 'wyndham-alltra'];

      if(datesContainerLarge.includes(window.brand)) {
        $('.secondary-form .booking-dates-container').addClass('w-190');
      } else if (datesContainerMedium.includes(window.brand)) {
        $('.secondary-form .booking-dates-container').addClass('w-185');
      } else if (datesContainerSmall.includes(window.brand)) {
        $('.secondary-form .booking-dates-container').addClass('w-175');
      } else if (datesContainerSmaller.includes(window.brand)) {
        $('.secondary-form .booking-dates-container').addClass('w-170');
      } else if (datesContainerSmallest.includes(window.brand)) {
        $('.secondary-form .booking-dates-container').addClass('w-160');
      }

      window.brand_id === 'es' &&
        $('.secondary-form .destination-input-container input[type=text]').addClass('esplendor');

      $('.booking-bar-form .search-btn.btn-primary').removeClass('btn-primary').addClass('btn-secondary');
    }

    // Click for SRP Search V2
    $('.toggle-v2').on("click", function(){

      $('.secondary-form').find('.booking-bar').slideToggle();
      $(this).toggleClass('open');

    });

    if (!(_isDateType(this.checkInDate)) || isPriorDate(this.checkInDate)) {
      this.checkInDate = getToday();
    }

    const defaultLos = getSessionCriteria().defaultLos;

    if (!(_isDateType(this.checkOutDate)) || isPriorDate(this.checkOutDate, this.checkInDate)) {
      this.checkOutDate = getXDays(defaultLos, this.checkInDate);
    } else if (isPriorDate(this.checkOutDate)) {
      this.checkInDate = getToday();
      this.checkOutDate = getXDays(defaultLos, this.checkInDate);
    }

    if (this.checkInDate) {
      this.checkInDate.setHours(0, 0, 0, 0);
    }
    if (this.checkOutDate) {
      this.checkOutDate.setHours(0, 0, 0, 0);
    }

    setCriteria({
      checkOutDate: formatDateForBWS(this.checkOutDate),
      checkInDate: formatDateForBWS(this.checkInDate)
    });

    this.setMinLos($_BOOKING_BAR_MAIN_.find('.booking-dates-container').data('min-los'), !(exists($_BOOKING_BAR_MINI_NAV)));

    this.updateInputs();
    this.updateOutputs();

    this.save();

    $(this._container)
      .on('dropdown:open', () => this.moveButtonGroupOnMobileOpen())
      .on('dropdown:opened', () => this.handlePostOpen())
      .on('dropdown:closing', () => this.handleClosing())
      .on('dropdown:closed', () => this.handlePostClose());

    this.handleOpen();
  }
  onResizeEnd() {
    ResizeHandler.addResizeEndFn(() => {
      this.setMinLos($_BOOKING_BAR_MAIN_.find('.booking-dates-container').data('min-los'), !(exists($_BOOKING_BAR_MINI_NAV)));
    });
  }
  _minLosIsNotMet() {
    return ((getNumDays(this.bbForm.getFrom(), this.bbForm.getTo())) < this.minLos);
  }
  setMinLos(n, _andUpdateDates) {
    if (_isNull(_andUpdateDates)) {
      _andUpdateDates = true;
    } else if (!(_isWeakTrue(_andUpdateDates)) && !(_isWeakFalse(_andUpdateDates))) {
      this.checkInDate = getDateFromDateString(_andUpdateDates);

      this.updateInputs();
      this.updateOutputs();

      _andUpdateDates = true;
    }

    $_PAGE_.find('.min-los-message span,.no-rates-available span').text(n);

    if (_isNumber(n) && n > 1) {
      this.minLos = n;

      $('.min-los-message').css('display', 'inline-block');

      if (this._minLosIsNotMet()) {
        if (_andUpdateDates) {
          this.checkInDate = getDateFromDateString(this.bbForm.getFrom());
          this.checkOutDate = getDateFromDateString(new Date(this.checkInDate).getTime() + (this.minLos * ONEDAY));

          this.updateInputs();
          this.updateOutputs();
        } else if ($_PAGE_.is('.property-page')) {
          this.bbMini.hidePricingAndBookNow();
          this.errorButtons();
          this.bbMini.showMinLosNotMetMessage(false);
        }
      } else if (this.bbMini && this.bbMini.getCurrentRate() > 0) {
        this.bbMini.hideMinLosNotMetMessage(false);
        this.errorButtons(true);
        this.bbMini.showPricingAndBookNow();
      }

      return true;
    }

    this.minLos = 1;

    $('.min-los-message').css('display', 'none');

    return false;
  }
  getMinLos() {
    return this.minLos;
  }
  errorButtons(_removeError) {
    if (_removeError === true) {
      $(this._buttonCheckIn).removeClass('error');
      $(this._buttonCheckOut).removeClass('error');
    } else {
      $(this._buttonCheckIn).addClass('error');
      $(this._buttonCheckOut).addClass('error');
    }
  }
  setDate(dateText) {
    let selectedDate = getDateFromDateString(dateText);
    this._autoScroll = $('.ui-datepicker-inline').scrollTop();

    if (this._currentButton == 'checkOut') {
      this.checkOutDate = selectedDate;
      // First, let's store the result of formatDateForPrinting and split operations in a variable
      const formattedDateParts = formatDateForPrinting(selectedDate, 'textMonth').split(' ');
      // If it's an array, we proceed with the filter operation
      if (Array.isArray(formattedDateParts)) {
        this._checkOutSecondaryVal = formattedDateParts
          .filter((_, idx) => idx !== 3)
          .join(' ');
      } else {
        this._checkOutSecondaryVal = '';
      }
      if (this.checkOutDate.getTime() <= this.checkInDate.getTime()) {
        this.checkOutDate = getXDays(this.minLos, this.checkInDate);
      }


      this.updateInputs();
      if (this.isSecondarySrpSearch) {
        this.updateOutputs(this._checkInSecondaryVal, this._checkOutSecondaryVal);
      } else {
        this.updateOutputs();
      }

      if (!(isMobileWidth())) {
        setTimeout(() => Dropdown.publicCloseDropdown(), 333);
      }
    } else {
      this.checkInDate = selectedDate;
      // First, let's store the result of formatDateForPrinting and split operations in a variable
      const formattedDateParts = formatDateForPrinting(selectedDate, 'textMonth').split(' ');
      // If it's an array, we proceed with the filter operation
      if (Array.isArray(formattedDateParts)) {
        this._checkInSecondaryVal = formattedDateParts
          .filter((_, idx) => idx !== 3)
          .join(' ');
      } else {
        this._checkInSecondaryVal = '';
      }

      if (!(this.checkOutDate) || this._minLosIsNotMet() || this.checkOutDate.getTime() <= this.checkInDate.getTime()) {
        const defaultLos = $_BOOKING_BAR_MAIN_.find('.booking-dates-container').data('default-los');
        if (defaultLos) {
          this.checkOutDate = getXDays(defaultLos, selectedDate);
        } else {
          this.checkOutDate = getXDays(this.minLos, selectedDate);
        }
      }

      const maxCheckOutDate = BookingConfig.getMaximumCheckOutDate(this.checkInDate);
      this.checkOutDate = this.checkOutDate > maxCheckOutDate ? maxCheckOutDate : this.checkOutDate;
      const _formatSecondaryDatesHandler = (selectedDate) => {
        // First, let's store the result of formatDateForPrinting and split operations in a variable
        const formattedDateParts = formatDateForPrinting(selectedDate, 'textMonth').split(' ');
        // If it's an array, we proceed with the filter operation
        if (Array.isArray(formattedDateParts)) {
          return formattedDateParts.filter((_, idx) => idx !== 3).join(' ');
        } else {
          return '';
        }
      };
      this._checkOutSecondaryVal = _formatSecondaryDatesHandler(this.checkOutDate);
      this.updateInputs();
      if (this.isSecondarySrpSearch) {
        this.updateOutputs(this._checkInSecondaryVal, this._checkOutSecondaryVal);
      } else {
        this.updateOutputs();
      }

      if (_isNotNull(this._currentButton)) {
        this.$buttonCheckOut.click();
      }
    }

    this.errorButtons(true);

    //update the mini booking widget for property page on change of dates
    if (this.$form.is('.mini-booking') && $_PAGE_.is('.property-page')) {
      prepareBookingBarValues();
      EventHandler.send(EventHandler.dates.updated);
    }
  }
  updateInputs() {
    this.bbForm.setFrom(formatDateForBWS(this.checkInDate));
    this.bbForm.setTo(formatDateForBWS(this.checkOutDate));
  }
  updateOutputs(_checkInSecondaryVal, _checkOutSecondaryVal) {
    if(_checkInSecondaryVal && _checkOutSecondaryVal) {
      $('.booking-input-container .booking-dates .check-in .date').html(this._checkInSecondaryVal);
      $('.booking-input-container .booking-dates .check-out .date').html(this._checkOutSecondaryVal);
    } else {
    this.$checkIn.html(formatDateForPrinting(this.checkInDate, 'textMonth'));
    this.$checkOut.html(formatDateForPrinting(this.checkOutDate, 'textMonth'));
    this.$checkInCollapsed.html(formatDateForPrinting(this.checkInDate, 'textMonth'));
    this.$checkOutCollapsed.html(formatDateForPrinting(this.checkOutDate, 'textMonth'));
    }
  }
  getCheckIn(isAvailablityCall) {
    if (isAvailablityCall) {
      return formatDateForBWSAvailability(this.checkInDate);
    } else {
      return formatDateForBWS(this.checkInDate);
    }
  }
  getCheckOut(isAvailabilityCall) {
    if (isAvailabilityCall) {
      return formatDateForBWSAvailability(this.checkOutDate);
    } else {
      return formatDateForBWS(this.checkOutDate);
    }
  }
  scrollToActive() {
    if (isMobileWidth()) {
      setTimeout(() => {
        let activeDateParent = $('.active-date-check-in').closest('.ui-datepicker-group'),
          newTop = $('.ui-datepicker-inline').scrollTop();
        if(activeDateParent.length) {
          newTop += activeDateParent.position().top;
        }
        $('.ui-datepicker-inline').scrollTop(newTop);
      }, 50);
    }
  }
  datePicker() {
    this.setMinLos(this.minLos);

    if (this.bbForm.getFrom()) {
      if(_LOCALE_.match(/(en-uk|en-ca|tr-tr|fr-ca|el-gr|de-de|pt-br|es-xl|es-es|en-gb|ko-kr)/) && $_PAGE_.is('.modify-page') && !this.checkinFlag) {
        this.checkIn = getDateFromDateString(formatDateForPrinting(this.bbForm.$from.val(),'weekdayCompact'));
        this.checkinFlag = true;
      } else {
        this.checkIn = getDateFromDateString(this.bbForm.getFrom());
      }
    }

    if (this.bbForm.getTo()) {
      if(_LOCALE_.match(/(en-uk|en-ca|tr-tr|fr-ca|el-gr|de-de|pt-br|es-xl|es-es|en-gb|ko-kr)/) && $_PAGE_.is('.modify-page') && !this.checkoutFlag) {
        this.checkOut = getDateFromDateString(formatDateForPrinting(this.bbForm.$to.val(),'weekdayCompact'));
        this.checkoutFlag = true;
      } else {
        this.checkOut = getDateFromDateString(this.bbForm.getTo());
      }
    }

    this.$datepicker.datepicker('destroy');
    this.$container.off('keyup');
    this.$container.off('keydown');

    let datePickerHandler = new BBDatePickerKeyboard(this.$container);

    // Calling new Date() to reset the default to be always today's date. Bug was causing reselection of checkin to delete previous month
    // Overriding the bugfix for the above comment because it caused an issue where if you selected a check in date months in advance, then you lose your month when we auto-switch to check out date. Cannot recreate above issue after this change.

    // Note to future devs: new Date() causes UX issues where calendar scrolls back to current month
    // even when check in date is in the future.
    // Code below (without isMobileWidth check) causes issues on mobile where the user can't scroll back to previous months.
    // Quickfix - use new Date() for mobile only, month calculation logic for other breakpoints.
    let defaultDate = new Date();

    if (this._currentButton === 'checkOut' && _isNotNull(this.checkOutDate)) {
      defaultDate = this.checkOutDate;
    } else if (_isNotNull(this.checkInDate)) {
      defaultDate = this.checkInDate;
    } else {
      defaultDate = new Date();
    }

    let monthsToShow = 2,
      mobileMonths = 1;
    let prevMonthText = $('#datepicker-prev-month-text').data('prevMonthText');
    let nextMonthText = $('#datepicker-next-month-text').data('nextMonthText');

    if (isMobileWidth()) {
      if ($('body').is('.ios,.iphone,.ipad,.android')) {
        mobileMonths = 13;
        this.$datepicker.addClass('datepicker-all-months');
      }
      monthsToShow = mobileMonths;
    }
    const maxDate = this._currentButton === 'checkOut' ? BookingConfig.getMaximumCheckOutDate(this.checkIn) : BookingConfig.getMaximumCheckInDate();

    this.$datepicker.datepicker({
      numberOfMonths: monthsToShow,
      defaultDate: defaultDate,
      minDate: 0,
      maxDate: maxDate,
      nextText: nextMonthText,
      prevText: prevMonthText,
      goToCurrent: true,

      onSelect: (dateText, picker) => {
        this.setDate($(picker.input).datepicker('getDate'));
        this.$container.removeAttr('aria-activedescendant');
      },
      beforeShowDay: (date) => {
        let selectedDate = new Date(date);

        if (selectedDate.getTime() < this.checkInDate.getTime()) {
          if (this._currentButton == 'checkOut') {
            return [false];
          }
        } else if (selectedDate.getTime() == this.checkInDate.getTime()) {
          if (this._currentButton == 'checkOut') {
            return [false, 'active-date active-date-check-in'];
          }

          return [true, 'active-date active-date-check-in'];
        } else if (selectedDate.getTime() == this.checkOutDate.getTime()) {
          return [true, 'active-date active-date-check-out'];
        } else if ((selectedDate.getTime() > this.checkInDate.getTime()) && (selectedDate.getTime() < this.checkOutDate.getTime())) {
          if (this._currentButton === 'checkOut' &&
            ((selectedDate.getTime() + (this.minLos * ONEDAY)) > this.checkInDate.getTime()) &&
            (selectedDate.getTime() < (this.checkInDate.getTime() + (this.minLos * ONEDAY)))
          ) {
            return [false, 'between-date disabled-date'];
          }

          return [true, 'between-date'];
        } else {
          return [true, ''];
        }

        return [true, ''];
      },
      onChangeMonthYear: () => {
        setTimeout(() => {
          this.disableDateTabbing();
          if (!isMobileWidth()) {
            this.$container.width(getElTrueWidth($('.ui-datepicker-group-first')) + getElTrueWidth($('.ui-datepicker-group-last')) + ($('body').is('.win') ? 17 : 2));
          }
        }, 10);
      }
    }).find('td a')
      .attr('tabindex', -1);


    this.$container.on('keydown', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });

    this.$container.on('keyup', (event) => {
      this.handleDatepickerAccessibility(event, datePickerHandler);
    });

    this.disableDateTabbing();
  }
  disableDateTabbing() {
    $('.ui-datepicker-calendar td').attr({
      role: 'gridcell'
    })
      .each((index, el) => {
        let $el = $(el),
          elDate = new Date($el.data('year'), $el.data('month'), $el.text());
        $el.attr('id', 'date' + elDate.getTime());
        $el.attr('aria-label', elDate.toLocaleString(_LOCALE_, {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        }));
      })
      .find('a')
      .attr('tabindex', -1);
    // Links should be keyboard selectable but not anchor to page top.
    $('.ui-datepicker-next, .ui-datepicker-prev').attr('href', '#')
      .click((e) => {
        e.preventDefault();
      });
  }

  handleDatepickerAccessibility(event, handler) {
    event.preventDefault();
    event.stopPropagation();
    let keyMap = $.ui.keyCode,
      currentDay = $('.current-highlight');
    if (!exists(currentDay)) {
      currentDay = (this._currentButton === 'checkIn') ? $('.active-date-check-in') : $('.active-date-check-out');
    }

    switch (event.keyCode) {
    case keyMap.UP:
      handler.previousWeek(currentDay, true);
      break;
    case keyMap.DOWN:
      handler.nextWeek(currentDay, true);
      break;
    case keyMap.LEFT:
      handler.previousDay(currentDay);
      break;
    case keyMap.RIGHT:
      handler.nextDay(currentDay);
      break;
    case keyMap.ENTER:
      handler.handleSelect(currentDay);
      break;
    case keyMap.PAGE_DOWN:
      handler.nextMonth(currentDay, false);
      break;
    case keyMap.PAGE_UP:
      handler.previousMonth(currentDay, false);
      break;
    }
  }
  cancel() {
    this.checkInDate = this._initialCheckIn;
    this.checkOutDate = this._initialCheckOut;
  }
  cancelClicked(e) {
    this.cancel();
    Dropdown.publicCloseDropdown();
  }
  handleCancel() {
    this.$cancel.one('click', () => this.cancelClicked());
  }
  save() {
    if (this.checkInDate) {
      this._initialCheckIn = this.checkInDate;
    }

    if (this.checkOutDate) {
      this._initialCheckOut = this.checkOutDate;
    }
  }
  saveClicked(e) {
    if (isMobileWidth() && $_PAGE_.is('.rooms-rates-page') && exists(this.$form) && this.$form.is('.mini-booking')) {
      this.$btnGroup.parents('form.mini-booking').submit();
    } else {
      Dropdown.publicCloseDropdown();
    }
  }
  handleSave() {
    this.$save.one('click', () => this.saveClicked());
  }
  closeThis() {
    this.updateInputs();
    if (this.isSecondarySrpSearch) {
      this.updateOutputs(this._checkInSecondaryVal, this._checkOutSecondaryVal);
    } else {
      this.updateOutputs();
    }

    this.$datepicker.datepicker('destroy');
  }
  handlePostClose() {
    this.save();
    this.closeThis();
  }
  handleClosing() {
    if (isMobileWidth()) {
      this.$btnGroup.parent().removeClass('on')
        .removeAttr('style');

      this.$datepicker.removeAttr('style');
    }
  }
  formChanged() {
    return !(this.$formWas && this.$formWas.is(this.$form));
  }
  whichForm(el) {
    this.$formWas = this.$form;
    this.$form = $(el).parents('form.booking-bar-form');

    this.$btnGroup = this.$form.find(this._btnGroup);
    this.$buttonCheckIn = this.$form.find(this._buttonCheckIn);
    this.$buttonCheckOut = this.$form.find(this._buttonCheckOut);

    this.$container = this.$form.find($(el).data('dropdown'));
    this.$datepicker = this.$container.find(this._datepicker);

    this.$saveCancel = this.$container.find(this._saveCancel);
    this.$save = this.$saveCancel.find(this._save);
    this.$cancel = this.$saveCancel.find(this._cancel);
  }
  handlePostOpen() {
    this.handleCancel();
    this.handleSave();

    if (isMobileWidth()) {
      $('.ui-datepicker-inline').css({
        'overflow-y': 'scroll',
        'max-height': ($(window).height() - 165) + 'px'
      });
    } else {
      this.$container.width(getElTrueWidth($('.ui-datepicker-group-first')) + getElTrueWidth($('.ui-datepicker-group-last')) + ($('body').is('.win') ? 17 : 2));
    }
  }
  moveButtonGroupOnMobileOpen() {
    if (isMobileWidth()) {
      this.$btnGroup.parent().addClass('on')
        .css({
          top: getElTrueHeight(this.$saveCancel),
          paddingTop: getBrand() === 'WR' ? '10px' : '1em'
        });

      let msgHeight = 0,
        $minLosMsg = this.$form.find('.min-los-message');
      if (exists($minLosMsg) && $minLosMsg.is(':visible')) {
        $minLosMsg.css({
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0
        }).parent()
          .css({
            position: 'relative'
          });

        msgHeight = $minLosMsg.outerHeight();
      }
      this.$datepicker.css({
        marginTop: this.$btnGroup.parent().outerHeight(),
        paddingTop: msgHeight
      });

      $('.ui-datepicker-inline').css({
        'overflow-y': 'scroll',
        'max-height': ($(window).height() - 165) + 'px'
      })
        .scrollTop(this._autoScroll);
    }
  }
  handlePreOpen(target, which) {
    this.whichForm(target);

    this._currentButton = which;
    this.$container.attr('data-current', which);

    this.datePicker();
  }
  handleOpen() {
    this.$buttonCheckIn.on('dropdown:opening', (e) => {
      this.handlePreOpen(e.target, 'checkIn');

      if(exists('#smartbanner')) {
        $(window).data('typeahead')
          .close({preventDefault: () => {}}); // Generate fake event object for SmartBanner
        $('.outer-wrapper.page').css('margin-top', 0);
        $('#pageHeader').css('top', 0);
      }
    });

    this.$buttonCheckOut.on('dropdown:opening', (e) => {
      this.handlePreOpen(e.target, 'checkOut');

      if (exists('#smartbanner')) {
        $(window).data('typeahead')
          .close({preventDefault: () => {}}); // Generate fake event object for SmartBanner
        $('.outer-wrapper.page').css('margin-top', 0);
        $('#pageHeader').css('top', 0);
      }

      // Scroll to active position
      this.scrollToActive();
    });
  }
}

/**
 * BBDatePickerKeyboard
 * Custom keyboard navigation logic for booking bar's datepicker.
 * jQuery UI doesn't support keyboard navigation, so custom JS
 * has to handle aria attributes and selection highlights
 */
class BBDatePickerKeyboard {
  constructor(datePicker) {
    this.$container = $(datePicker);
  }
  previousDay(currentDay) {
    let prevTd = currentDay.prev(),
      prevLink = prevTd.find('a')[0];
    if (prevTd && prevLink) {
      if (!(prevTd.is('.ui-datepicker-unselectable'))) {
        currentDay = prevTd;
        this.updateAriaValues(currentDay);
      }
    } else {
      // No previous day in current week, go to previous week
      this.previousWeek(currentDay, false);
    }
  }
  previousWeek(currentDay, maintainWeekDay) {
    let currentWeek = $(currentDay).closest('tr'),
      prevWeek = currentWeek.prev(),
      prevWeekDates = $('td:not(.ui-datepicker-unselectable)', prevWeek);

    let targetIndex = maintainWeekDay ? currentWeek.find('td').index(currentDay) : (prevWeek.find('td').length - 1);
    if (exists(prevWeek) && prevWeekDates.length > 0 && (7 - prevWeekDates.length <= targetIndex)) {
      let newDate = $(prevWeek.find('td')[targetIndex]);
      this.updateAriaValues(newDate);
    } else {
      this.previousMonth(currentDay, maintainWeekDay);
    }
  }
  previousMonth(currentDay, maintainWeekDay) {
    let currentMonth = $(currentDay).closest('.ui-datepicker-group');
    if (currentMonth.is('.ui-datepicker-group-first')) {
      if ($('.ui-datepicker-prev').is('.ui-state-disabled')) {
        // We're at the first month in the calendar. Can't go back.
        return;
      }
      $('.ui-datepicker-prev', currentMonth).click();
    }

    if (maintainWeekDay) {
      let targetIndex = $(currentDay).closest('tr')
          .find('td')
          .index(currentDay),
        targetWeek = $('.ui-datepicker-group-first').find('tr')
          .last(),
        targetDate = $(targetWeek.find('td')[targetIndex]);
      if (targetDate.is('.ui-datepicker-unselectable')) {
        targetWeek = targetWeek.prev('tr');
        targetDate = $(targetWeek.find('td')[targetIndex]);
      }
      // If last week and second last week in a month are both unselectable,
      // the entire month is disabled. Maintain the previous selection.
      if (!targetDate.is('.ui-datepicker-unselectable')) {
        currentDay = targetDate;
      }

    } else {
      currentDay = $('.ui-datepicker-group-first').find('td:not(.ui-datepicker-unselectable)')
        .last();
    }
    this.updateAriaValues(currentDay);
  }
  nextDay(currentDay) {
    let nextTd = currentDay.next(),
      nextLink = nextTd.find('a')[0];
    if (nextTd && nextLink) {
      if (!(nextTd.is('.ui-datepicker-unselectable'))) {
        currentDay = nextTd;
        this.updateAriaValues(currentDay);
      }
    } else {
      // No next day in current week, go to next week
      this.nextWeek(currentDay, false);
    }
  }
  nextWeek(currentDay, maintainWeekDay) {
    let currentWeek = $(currentDay).closest('tr'),
      nextWeek = currentWeek.next(),
      nextWeekDates = $('td:not(.ui-datepicker-unselectable)', nextWeek);

    let targetIndex = maintainWeekDay ? currentWeek.find('td').index(currentDay) : 0;
    if (exists(nextWeek) && nextWeekDates.length > 0 && nextWeekDates.length > targetIndex) {
      var newDate = $(nextWeek.find('td')[targetIndex]);
      this.updateAriaValues(newDate);
    } else {
      this.nextMonth(currentDay, maintainWeekDay);
    }
  }
  nextMonth(currentDay, maintainWeekDay) {
    let currentMonth = $(currentDay).closest('.ui-datepicker-group');
    if (currentMonth.is('.ui-datepicker-group-last')) {
      if ($('.ui-datepicker-next').is('.ui-state-disabled')) {
        // We're at the last month in the calendar. Exit without moving.
        return;
      }
      $('.ui-datepicker-next', currentMonth).click();
    }

    if (maintainWeekDay) {
      let targetIndex = $(currentDay).closest('tr')
          .find('td')
          .index(currentDay),
        targetWeek = $('.ui-datepicker-group-last').find('tbody tr')
          .first(),
        targetDate = $(targetWeek.find('td')[targetIndex]);
      if (targetDate.is('.ui-datepicker-unselectable')) {
        targetWeek = targetWeek.next('tr');
        targetDate = $(targetWeek.find('td')[targetIndex]);
      }
      // If 2 weeks are unselectable, the whole month is probably unselectable.
      // Do not move.
      if (!targetDate.is('.ui-datepicker-unselectable')) {
        currentDay = targetDate;
      }
    } else {
      currentDay = $('.ui-datepicker-group-last').find('td:not(.ui-datepicker-unselectable)')
        .first();
    }
    this.updateAriaValues(currentDay);
  }
  handleSelect(currentDay) {
    currentDay.find('a').click();
  }
  updateAriaValues(selectedDay) {
    this.$container.attr('aria-activedescendant', selectedDay.attr('id'));
    $('td', this.$container).removeClass('current-highlight')
      .attr('aria-selected', false);
    selectedDay.addClass('current-highlight').attr('aria-selected', true);
  }
}

/**
 * BBRoomsGuests
 * Handles logic for selecting the number of rooms and guests
 * to be booked, along with the ages of children included in the reservation
 */
class BBRoomsGuests {
  constructor(bbForm, bbMini) {
    this.bbForm = bbForm;
    this.bbMini = bbMini;

    this._button = '.rooms-and-guests-button';
    this._container = '.rooms-and-guests-container';
    this._rooms = '.number-picker-rooms';
    this._adults = '.number-picker-adults';
    this._children = '.number-picker-children';
    this._roomsLabel = '.rooms-label';
    this._guestsLabel = '.guests-label';
    this._save_cancel_container = '.save-cancel-container';
    this._cancel = this._save_cancel_container + ' .cancel';
    this._save = this._save_cancel_container + ' .save';
    this.$button = $(this._button);
    this.$form = null;
    this.$container = null;
    this.$rooms = null;
    this.$adults = null;
    this.$children = null;
    this.$cancel = null;
    this.$save = null;
    this.$roomsLabel = $(this._roomsLabel);
    this.$guestsLabel = $(this._guestsLabel);
    this.initialRooms = null;
    this.initialAdults = null;
    this.initialChildren = null;
  }
  errorRooms(yes) {
    let $btn = this.$form.find(this._button);

    if (yes) {
      $btn.addClass('error');
    } else {
      $btn.removeClass('error');
    }
  }
  getRooms() {
    return this.$rooms.val;
  }
  getAdults() {
    return this.$adults.val;
  }
  getChildren() {
    return this.$children.val;
  }
  updateForm() {
    this.bbForm.setRooms(this.initialRooms);
    this.bbForm.setAdults(this.initialAdults);
    this.bbForm.setChildren(this.initialChildren);
  }
  revertToOriginalValues() {
    this.$rooms.set(this.initialRooms);
    this.$adults.set(this.initialAdults);
    this.$children.set(this.initialChildren);

    this.updateForm();
  }
  cancel() {
    this.revertToOriginalValues();
  }
  cancelClicked() {
    this.cancel();
    this.closeThis();
    Dropdown.publicCloseDropdown();
  }
  handleCancel() {
    this.$cancel.click(() => this.cancelClicked());
  }
  updateOriginalValues() {
    this.initialRooms = this.$rooms.val;
    this.initialAdults = this.$adults.val;
    this.initialChildren = this.$children.val;

    this.updateForm();
  }
  save() {
    this.updateOriginalValues();
  }
  saveClicked(e) {
    this.closeThis();

    Dropdown.publicCloseDropdown();
  }
  handleSave() {
    this.$save.one('click', () => this.saveClicked());
  }
  removeErrors() {
    this.errorRooms(false);
  }
  closeThis() {
    this.removeErrors();
  }
  setForm(form) {
    this.$form = form;
  }
  whichForm(target) {
    this.setForm($(target).parents('form.booking-bar-form'));
    this.$container = this.$form.find(this._container);
    this.$cancel = this.$form.find(this._cancel);
    this.$save = this.$form.find(this._save);
  }
  handlePostOpen() {
    this.$children.handleAgeSelector();
  }
  handlePreOpen(target) {
    this.whichForm(target);
    this.handleCancel();
    this.handleSave();
    this.$container.one('dropdown:opened', () => this.handlePostOpen()).one('dropdown:closed', () => this.save());
  }
  handleOpen() {
    this.$button.on('dropdown:opening', (e) => this.handlePreOpen(e.target));
  }
  open() {
    this.$form.find(this._button).click();
  }
  init() {
    this.$rooms = new BBNumberPicker(this._rooms);
    this.$adults = new BBNumberPicker(this._adults);
    this.$children = new BBNumberPicker(this._children);

    this.$rooms.init();
    this.$rooms.updateHandler = () => {
      $('.rooms-label .number').html(this.$rooms.val);
      $('span[name=rooms]').html(this.$rooms.val);

      if (this.$rooms.val > 1) {
        $('.rooms-label .singular').addClass('hidden');
        $('.rooms-label .plural').removeClass('hidden');
      } else {
        $('.rooms-label .singular').removeClass('hidden');
        $('.rooms-label .plural').addClass('hidden');
      }

      this.bbForm.setRooms(this.$rooms.val);
    };

    this.$adults.init();
    this.$adults.updateHandler = () => {
      $('span[name=adults]').html(this.$adults.val);

      let total = this.$adults.val + this.$children.val;

      $('.guests-label .number').html(total);

      if (total > 1) {
        $('.guests-label .singular').addClass('hidden');
        $('.guests-label .plural').removeClass('hidden');
      } else {
        $('.guests-label .singular').removeClass('hidden');
        $('.guests-label .plural').addClass('hidden');
      }

      this.bbForm.setAdults(this.$adults.val);
    };

    this.$children.init(() => {
      let $selector = this.$children.$container.find('.child-age-selector-container'),
        childAgeCopy = $selector.find('input.child-age-copy').val(),
        childAgeAdaCopy = $selector.find('input.child-age-screen-reader').val(),
        childAgeMax = $selector.find('input.child-age-max').val(),
        cookie = (this.bbForm.getChildAges() != undefined && this.bbForm.getChildAges() != null) ? this.bbForm.getChildAges().split('-') : '';

      for (let i = 1; i <= this.$children.max; i++) {
        let $outer = $(document.createElement('div')).addClass('dropdown child-age-selector child-age-' + i),
          $copy = $(document.createElement('label')).addClass('child-age-label')
            .attr('for', 'childAge' + i)
            .html(childAgeCopy + ' ' + i + '<span class="sr-only">' + childAgeAdaCopy + '</span>'),
          $select = $(document.createElement('select')).addClass('child-age')
            .attr('name', 'childAge' + i)
            .attr('id', 'childAge' + i),
          $opt = $(document.createElement('option')).val(0)
            .html('&lt; 1'),
          cookieIndex = (i - 1);

        if (!(cookie[cookieIndex]) || cookie[cookieIndex] == 0) {
          $opt.attr('selected', 'selected');
        }
        $select.append($opt);

        for (var j = 1; j <= childAgeMax; j++) {
          $opt = $(document.createElement('option')).val(j)
            .html(j);
          if ((cookie[cookieIndex]) && cookie[cookieIndex] == j) {
            $opt.attr('selected', 'selected');
          }

          $select.append($opt);
        }

        $outer.append($copy).append($select);

        $selector.append($outer);
      }

      if (isMobileWidth()) {
        $('.rooms-and-guests-container .dropdown-overflow').css({
          'overflow-y': 'scroll',
          'max-height': $(window).height() - 78
        });
      }
    });
    this.$children.handleChildAges = (num, $form) => {
      let ages = [];

      for (let i = 1; i <= num; i++) {
        ages.push($form.find('.child-age-' + i + ' select.child-age').val());
      }

      this.bbForm.setChildAges(ages.join('-'));
    };
    this.$children.handleAgeSelector = (num) => {
      let $selector = this.$children.$container.find('.child-age-selector-container');

      $selector.each((i, el) => {
        num = (num) ? num : this.$children.val;
        let $prompt = $(el).find('.child-age-selector-prompt'),
          $childAge = $(el).find('.child-age-' + num),
          promptHeight = getElTrueHeight($prompt),
          h = getElTrueHeight($childAge);

        if (num > 0) {
          $(el).height(promptHeight + (h * num));
          $prompt.attr('aria-hidden', false);
        } else {
          $(el).height(0);
          $prompt.attr('aria-hidden', true);
        }
        $selector.each((i, el) => {
          $('select.child-age', $(el)).off('change');
          $('.child-age-selector', $(el)).slice(0, num)
            .attr('aria-hidden', false)
            .find('select.child-age')
            .attr('tabindex', 0)
            .on('change', () => {
              this.$children.handleChildAges(num, $(el).parents('form.booking-bar-form'));
            });
          $('.child-age-selector', $(el)).slice(num)
            .attr('aria-hidden', true)
            .find('select.child-age')
            .attr('tabindex', -1);
        });
      });
    };
    this.$children.updateHandler = () => {
      $('span[name=children]').html(this.$children.val);

      let total = this.$children.val + this.$adults.val;

      $('.guests-label .number').html(total);

      if (total > 1) {
        $('.guests-label .singular').addClass('hidden');
        $('.guests-label .plural').removeClass('hidden');
      } else {
        $('.guests-label .singular').removeClass('hidden');
        $('.guests-label .plural').addClass('hidden');
      }

      this.bbForm.setChildren(this.$children.val);
      this.$children.handleAgeSelector();
      this.$children.handleChildAges(this.$children.val, this.$lastForm || this.$form || $(this._children).parents('form.booking-bar-form'));
    };

    this.$rooms.set(this.bbForm.getRooms());
    this.$adults.set(this.bbForm.getAdults());
    this.$children.set(this.bbForm.getChildren());
    this.$children.handleChildAges(this.bbForm.getChildren(), this.$form || $(document));

    this.initialRooms = this.$rooms.val;
    this.initialAdults = this.$adults.val;
    this.initialChildren = this.$children.val;

    $('.rooms-guests-nav').each((i, el) => {
      let ariaMessage = $(el).text();
      ariaMessage = replaceToken(ariaMessage, '${numAdults}', this.$adults.max) || ariaMessage;
      ariaMessage = replaceToken(ariaMessage, '${numChildren}', this.$children.max) || ariaMessage;
      ariaMessage = replaceToken(ariaMessage, '${numRooms}', this.$rooms.max) || ariaMessage;
      $(el).text(ariaMessage);
    });
    this.$button = $(this._button);
    this.handleOpen();
  }
}

/**
 * BBNumberPicker
 * Subcomponent of BBRoomsGuests, used to select the number of
 * rooms, adults or children. Maintains the current number selected,
 * handles clicks of + and - buttons, and handles callbacks when the
 * number goes up or down.
 */
class BBNumberPicker {
  constructor(parent_class) {
    let $parent = $(parent_class);

    this.$_ = $parent;
    this.$label = $parent.find('.picker-label');
    this.$sub = $parent.find('.subtract');
    this.$add = $parent.find('.add');
    this.$input = $parent.find('span.number');

    this.$container = $parent.parent();

    this.for = '';
    this.min = 0;
    this.max = 9;
    this.val = 1;

    this.which = '';
  }
  set(v) {
    v = v * 1;

    this.val = v;
    this.$input.val(v);

    this.updateHandler();
    this.handleDisablingButtons();
  }
  updateVal(v) {
    if (v) {
      this.$input.val(v);
    } else {
      this.$input.val(this.val);
    }

    this.handleDisablingButtons();

    this.updateHandler();
  }
  handleDisablingButtons() {
    if (this.val == this.min) {
      this.$sub.addClass('disabled').attr('disabled', '');
      this.$add.removeClass('disabled').removeAttr('disabled');
    } else if (this.val == this.max) {
      this.$sub.removeClass('disabled').removeAttr('disabled');
      this.$add.addClass('disabled').attr('disabled', '');
    } else {
      this.$sub.removeClass('disabled').removeAttr('disabled');
      this.$add.removeClass('disabled').removeAttr('disabled');
    }
  }
  handleSubtract() {
    this.$sub.click((e) => {
      this.which = 'subtract';

      this.val = this.$input.val() * 1;
      this.min = this.$input.data('min') * 1;
      this.$lastForm = $(e.currentTarget).parents('form.booking-bar-form');

      if (this.val > this.min) {
        --this.val;

        this.updateVal();
      }
    });
  }
  handleAdd() {
    this.$add.click((e) => {
      this.which = 'add';

      this.val = this.$input.val() * 1;
      this.max = this.$input.data('max') * 1;
      this.$lastForm = $(e.currentTarget).parents('form.booking-bar-form');

      if (this.val < this.max) {
        ++this.val;

        this.updateVal();
      }
    });
  }
  init(callback) {
    this.for = this.$label.attr('for');
    this.val = this.$input.val() * 1;
    this.min = this.$input.data('min');
    this.max = this.$input.data('max');

    if (bookingBarInit === '0'){
       this.handleSubtract();
       this.handleAdd();
     }

    this.handleDisablingButtons();

    if (typeof (callback) == 'function') {
      callback.apply(this);
    }
  }
}

/**
 * BBMoreOptions
 * Handles the more options dropdown on the booking bar, encapsulating
 * user selections of special rates (AAA, corporate rates, etc) as well
 * as the checkbox for whether to search with WR points or merely cash.
 */
class BBMoreOptions {
  constructor(bbForm, bbMini, root, conf) {
    this.bbForm = bbForm;
    this.bbMini = bbMini;

    this.root = root;
    this.$form = $(null);
    this.$button = root.find('.more-options-button');
    this.$label = root.find('.more-options__label');
    this._container = '.more-options-container';
    this._width = null;
    this._checkboxContainer = 'div.wyndham-rewards-checkbox';
    this._checkbox = 'input[name=wyndham-rewards]';
    this._radio = 'input[name=specialty-rates]';
    this._radioContainer = '.specialty-rates-radio';
    this._save_cancel_container = '.save-cancel-container';
    this._cancel = this._save_cancel_container + ' .cancel';
    this._save = this._save_cancel_container + ' .save';
    this.$container = $(this._container, root);
    this.$checkboxContainer = $(this._checkboxContainer, root);
    this.$checkbox = $(this._checkbox, root);
    this.$radio = $(this._radio, root);
    this.$radioContainer = $(this._radioContainer, root);
    this.$cancel = $(null);
    this.$save = $(null);
    this.checkboxOriginal = this.bbForm.getRedeemWRPoint();
    this.$radioDefault = $(this._radio + '[value=default],' + this._radio + '.default', root).first();
    this.$radioOriginal = this.$radioDefault;
    this.$radioInput = this.$radioOriginal.parent().find('input.code');
    this.checkboxCurrent = false;
    this.$radioCurrent = $(this._radio + ':checked', root);
    this.$currentInput = this.$radioCurrent.parent().find('input.code');
    this.config = conf || {};
    this.$checkbox.prop('checked', this.bbForm.getRedeemWRPoint());
    this.rateCodeText = '';

    if (this.$radioCurrent.length < 1) {
      this.$radioCurrent = this.$radioOriginal;
      this.$radioCurrent.prop('checked', true);
    }

    this.reset();

    this.handleCheckboxChange();
    this.handleRadioChange();

    this.handleOpen();

    EventHandler.on('userCorporateCode', (_, eventCorpCode) => {
      const currentCorpCode = this.bbForm.getCorpCodeData();
      if (eventCorpCode && !currentCorpCode) {
          if (!currentCorpCode || currentCorpCode !== eventCorpCode) {
              this.bbForm.setCorpCodeData(eventCorpCode);
              this.reset();
          }
      }
    });
  }
  findProperRateInput(val) {
    if (val) {
      return $(this._radio + '[value="' + val + '"]', this.root);
    }
  }
  updateCurrentVals() {
    this.checkboxCurrent = this.$checkbox.is(':checked');

    this.$radioCurrent = this.$container.find(this._radio + ':checked');
    this.$currentInput = this.$radioCurrent.parent().find('input.code');
  }
  updateBookingBarFields() {
    this.bbForm.setRedeemWRPoint(this.checkboxCurrent);
    // Hide or show flexible dates link if points checkbox is active or not.
    if (this.bbMini && this.bbForm.isAvailabilityCalendarSetUp()) {
      this.bbForm.toggleAvailabilityCalendarLinks(this.checkboxCurrent);
    }

    if (this.$radioCurrent.is('.default')) {
      this.bbForm.setSpecialRate();
    } else if (this.$radioCurrent.is('.requires-code') && this.$currentInput.val()) {
      this.bbForm.setSpecialRate(this.$currentInput.val(), this.$radioCurrent.val());
    } else {
      this.bbForm.setSpecialRate(this.$radioCurrent.val(), this.$radioCurrent.data('corpcode'));
    }
  }
  collapseCodeInputs() {
    $(this._radioContainer + ' .code-container', this.root).css({
      paddingTop: 0
    })
      .height(0)
      .find('input.code')
      .attr('tabindex', -1);
  }
  openCodeInput() {
    var $codeDiv = this.$radioCurrent.parent().find('.code-container'),
      $codeInput = $codeDiv.find('input.code');

    $codeDiv.css({
      paddingTop: '1em',
      paddingLeft: '35px'
    }).height(getElTrueHeight($codeInput));
    $codeInput.attr('tabindex', 0);
  }
  checkNone() {
    $(this._radio + '.default', this.root).prop('checked', true);
  }
  setWyndhamRewards(bool) {
    $(this._checkbox, this.root).prop('checked', bool);

    this.updateCurrentVals();
    this.updateBookingBarFields();
    this.updateLabel();
  }
  handleCheckboxChange() {
    this.$checkbox.change(() => {
      this.updateCurrentVals();

      if (this.checkboxCurrent) {
        this.checkNone();

        this.collapseCodeInputs();
      }

      this.setWyndhamRewards(this.$checkbox.is(':checked'));
    });
  }
  handleRadioChange() {
    this.$radio.change(() => {
      $(this._checkbox, this.root).prop('checked', false);

      this.updateCurrentVals();

      if (this.$radioCurrent.val()) {
        $(this._radio + '[value=' + this.$radioCurrent.val() + ']', this.root).prop('checked', true);
        setCriteria({
          specialRateDrop: this.$radioCurrent.val()
        });
      } else {
        $(this._radio + '.disabled', this.root).prop('checked', true);
        setCriteria({
          specialRateDrop: null
        });
      }

      this.collapseCodeInputs();

      if (!(this.$radioCurrent.is('.default')) && (this.$radioCurrent.is('.requires-code'))) {
        this.openCodeInput();
      }

      if (this.$radioCurrent.is('.default')) {
        // Overwrite criteria option so it isn't populated from session.
        setCriteria({
          ratePlan: null,
          rateCode: null,
          groupCode: null,
          corpCode: null
        });
      }

      this.updateBookingBarFields();

      if (!this.config.updateLabelOnlyOnSave) {
        this.updateLabel();
      }
    });
  }
  updateLabel() {
    if (this.checkboxCurrent) {
      this.rateCodeText = '';
      this.$label.html(this.$checkboxContainer.data('label'));
      this.$button.removeClass('rate-selected');
    } else if (this.bbForm.getSpecialRate()) {
      this.rateCodeText = this.$radioCurrent.next().text();

      this.$label.html(this.$radioContainer.data('label').replace('[[rate]]', this.rateCodeText));
      this.$button.addClass('rate-selected');
    } else {
      this.rateCodeText = '';
      this.$label.html(this.$button.data('label'));
      this.$button.removeClass('rate-selected');
    }

    if (this.bbForm.setRateCodeText) {
      if (this.config.displayRateCodeValue && this.$currentInput && this.$currentInput.length > 0 && this.$currentInput.val()) {
        this.bbForm.setRateCodeText(this.rateCodeText += ' (' + this.$currentInput.val() + ')');
      } else {
        this.bbForm.setRateCodeText(this.rateCodeText);
      }
    }
  }
  revertToOriginalValues() {
    this.checkboxCurrent = this.checkboxOriginal;
    this.$checkbox.prop('checked', this.checkboxOriginal);

    this.$radioOriginal.prop('checked', true);
    this.$radioCurrent = this.$radioOriginal;
    this.$currentInput = this.$radioInput;

    if (!(this.$radioOriginal.is('.requires-code'))) {
      this.collapseCodeInputs();
    }
  }
  cancel() {
    this.revertToOriginalValues();
    this.updateBookingBarFields();
    this.updateLabel();
  }
  cancelClicked(e) {
    this.cancel();

    Dropdown.publicCloseDropdown();
  }
  handleCancel() {
    this.$cancel.one('click', () => this.cancelClicked());
  }
  updateOriginalValues() {
    this.checkboxOriginal = this.checkboxCurrent;
    this.$radioOriginal = this.$radioCurrent;
  }
  save() {
    if (this.$currentInput.length == 0 || this.$currentInput.val()) {
      this.updateOriginalValues();

      this.updateBookingBarFields();
      this.updateLabel();
    } else {
      this.cancel();
    }
  }
  saveClicked(e) {
    Dropdown.publicCloseDropdown();

    if (this.config.quickFireSave) {
      this.save();
    }
  }
  handleSave() {
    this.$save.one('click', () => this.saveClicked());
  }
  setForm(form) {
    this.$form = form;
  }
  whichForm(el) {
    this.$form = $(el).parents('form.booking-bar-form, .av-calendar-options');
    this.$container = this.$form.find(this._container);
    this.$checkboxContainer = this.$form.find(this._checkboxContainer);
    this.$checkbox = this.$form.find(this._checkbox);
    this.$radio = this.$form.find(this._radio);
    this.$radioContainer = this.$form.find(this._radioContainer);
    this.$cancel = this.$container.find(this._cancel);
    this.$save = this.$container.find(this._save);
    this.checkboxOriginal = this.$checkbox.is(':checked');
    this.$radioOriginal = this.$container.find('input[name=specialty-rates]:checked');
    this.$radioInput = this.$radioOriginal.parent().find('input.code');
  }
  handlePostOpen() {
    if (this._width === null) {
      this._width = getElTrueWidth(this.$container);
    } else {
      this.$container.width(this._width);
    }
    this.addScrollOption();
    this.openCodeInput();
  }
  handlePreOpen(target) {
    this.whichForm(target);
    this.updateCurrentVals();
    this.handleCancel();
    this.handleSave();
    this.$container.one('dropdown:opened', () => this.handlePostOpen()).one('dropdown:closed', () => this.save());
    this.cancel(); // reset all values when opening
  }
  addScrollOption() {
    if (isMobileWidth()) {
      $('.more-options-container .dropdown-overflow').css({
        'overflow-y': 'scroll'
      });
      $('.more-options-container .dropdown-overflow').height($(window).height() - $('.more-options-container .dropdown-overflow .save-cancel-container').outerHeight());
    }
  }
  handleOpen() {
    this.$button.on('dropdown:opening', (e) => {
      this.handlePreOpen(e.target);
    });
  }
  open() {
    this.$form.find(this._button).click();
  }
  reset() {
    let rate = Object.assign({}, this.bbForm.getSpecialRate()),
      fallbackToDefault = false;

    // special condition for promotion code
    if (rate && rate.type && !rate.code) {
      rate.code = rate.type;
      rate.type = 'promotional';
      this.bbForm.setSpecialRate(rate.code, rate.type);
    }

    // if there is an specific radio button for this corporate code
    if (rate && rate.type == 'corpCode') {
      let radio = $(this._radio + '[data-corpcode="' + rate.code + '"]', this.root);

      if (radio && radio.length > 0 && radio.val()) {
        rate.type = radio.val();
      }
    }

    this.$radioOriginal = (rate && rate.type) ? this.findProperRateInput(rate.type) : this.$radioDefault;

    if (this.$radioOriginal.length == 0) {
      fallbackToDefault = true;
      this.$radioOriginal = this.$radioDefault;
    }

    this.$radioInput = this.$radioOriginal.parent().find('input.code');

    if (this.$radioInput.length == 0 && !fallbackToDefault) {
      this.$radioInput = $(this._radio + '[value=corpCode]').parent()
        .find('input.code');
    }

    this.$radioInput.val(rate ? rate.code : '');

    this.$radioOriginal.prop('checked', true);

    this.updateCurrentVals();
    this.updateLabel();
  }
}

/**
 * BBMini
 * Mini booking bar logic for property page and rooms and rates.
 * Mini booking bar should reflect global booking bar search criteria
 * but submitting changes here causes different effects per page.
 * Property page navigates to rooms and rates, but rooms and rates simply
 * updates criteria and reloads the page.
 */
class BBMini {
  constructor(bbForm) {
    this.bbForm = bbForm;

    this.changeMiniBookingBarElement();
    this.$placeholder = $('#miniBookingHeight');
    this._currentRate = 0;
    this.stickyOpts = {
      start: null
    };

    this.init();
  }
  getCurrentRate() {
    return this._currentRate;
  }
  enableStick() {
    let isRoomsAndRatesPage = $_PAGE_.is('.rooms-rates-page'),
      isEconomyPropertyPage = $_PAGE_.is('.property-page:not(".uu-property")'),
      isUUPropertyPage = $_PAGE_.is('.uu-property,.uu-overview');

    this.$miniBooking.removeClass('stick stuck sticky');
    this.changeMiniBookingBarElement();
    this.stickyOpts = {};


    if((isRoomsAndRatesPage || isEconomyPropertyPage || isUUPropertyPage)) {
      let top;
      if (isUUPropertyPage) {
        top = 0;
      } else if (isRoomsAndRatesPage) {
        top = Math.abs(this.$miniBooking.find('.container').outerHeight() - getHeaderHeight()) / 2;
      } else {
        top = Math.abs(this.$miniBooking.outerHeight() - getHeaderHeight()) / 2;
      }

      this.stickyOpts.start = this.$miniBooking.offset().top;

      this.stickyOpts.onUnstick = () => {
        this.disableStick();
      };

      if (isMobileWidth() && isRoomsAndRatesPage) {
        this.stickyOpts.start -= 10;

        this.stickyOpts.onStick = () => {
          this.changeMiniBookingBarElement();
          this.miniBookingPlaceholder();

          if (isMobileWidth() && exists($('.mini-booking-nav .no-bookings-message-component'))) {
            $('.mini-booking-nav').css('display', 'none');
          } else {
            this.$placeholder.show();
          }
        };
      }

      if (isDesktopWidth() || isTabletWidth()) {
        this.stickyOpts.start -= top;
        if($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
          top = top + $('.alert-component').height();
        }
        if(isTabletWidth() && isEconomyPropertyPage){
          top = '-150%';
        }

        this.stickyOpts.stick = {
          css: {
            top: top
          }
        };
      }

      if (isUUPropertyPage) {
        this.stickyOpts.onStick = () => {
          this.changeMiniBookingBarElement();
          this.miniBookingPlaceholder();
          let subnavTop = this.$miniBooking.outerHeight();
          let alertHeight = 0;

          if ($('.alert-component').length && !$('.alert-component').hasClass('hidden') && $('.alert-component').css('position') != 'fixed') {
              alertHeight = $('.alert-component').height();
          }

          if (isMobileWidth()) {
            $('.info-mobile').addClass('fix-property-info');
            $('#uuCarousel').find('.property-info').css({
              top: alertHeight
            });
            $('.property-info.info-mobile').css({
              top: 0
            });
            subnavTop += $('.info-mobile').outerHeight();

            if (exists($('.mini-booking-nav .no-bookings-message-component'))) {
              subnavTop -= this.$miniBooking.outerHeight();
              $('.mini-booking-nav').css('display', 'none');
            }
          } else {
            this.$placeholder.height(this.$miniBooking.outerHeight() + $('.uu-subnav-bg').outerHeight()).show();
          }

          $('.uu-subnav-bg').addClass('stick')
              .css({
                top: subnavTop
              });

          if (isMobileWidth() && $('.uuproperty-notification-banner').length > 0) {
            $('.booking-bar-form.mini-booking').parent().find('.uuproperty-notification-banner').addClass('hidden');
            let contentTop = subnavTop + alertHeight + $('.mob-prop-details').outerHeight() + $('.uu-subnav-bg').outerHeight();
            $('.component.property-page-hero-carousel-component').next().css('marginTop', contentTop);
          }
        };
      }

      this.miniBookingSticky();
      $(window).scroll(() => this.miniBookingSticky());

      if (!isMobileWidth()) {
        bbCollapsedInst.stick(0, true);
      }
    }

  }


  miniBookingPlaceholder() {
    let placeholderHeight = $_BOOKING_BAR_MINI_NAV.outerHeight();
    if ($_PAGE_.is('.uu-property,.uu-overview')) {
      placeholderHeight += $('.uu-subnav-bg').outerHeight();
    }

    if(isMobileWidth()){
      $('.mini-booking-nav').css({
        top: 75
      });
    }
    this.$placeholder.css({
      height: placeholderHeight
    });
  }
  disableStick(){
    $(window).off('scroll', () => this.miniBookingSticky());

    this.$miniBooking.removeAttr('style');
    this.$placeholder[0].style.height = '';

    if (!($_PAGE_.is('.uu-property,.uu-overview'))) {
      this.$placeholder.hide();
    }

    $('.info-mobile').removeClass('fix-property-info');
    $('#uuCarousel').find('.property-info').css({
      top: 'auto'
    });
    $('.property-info.info-mobile').css({
      top: 'auto'
    });
    $('.uu-subnav-bg').removeClass('stick').removeAttr('style');

    if (isMobileWidth() && $_PAGE_.is('.uu-overview') && $('.uuproperty-notification-banner').length > 0) {
      $('.booking-bar-form.mini-booking').parent().find('.uuproperty-notification-banner').removeClass('hidden');
      $('.component.property-page-hero-carousel-component').next().css('marginTop', $('.booking-bar-form.mini-booking').parent().height());
    }
    if (isMobileWidth() && exists($('.mini-booking-nav .no-bookings-message-component'))){
      $('.mini-booking-nav').removeAttr('style');
    }
  }
  miniBookingSticky() {
    if (typeof (this.stickyOpts.start) === 'number') {
      let isRoomsAndRatesPage = $_PAGE_.is('.rooms-rates-page'),
        isUUPropertyPage = $_PAGE_.is('.uu-property,.uu-overview');
      let top;
      if(isMobileWidth()) {
        top = 0;
      } else {
        top = 7.5;
      }

      if (isUUPropertyPage) {
        top = 0;
      } else {
        if (isRoomsAndRatesPage) {
          top = Math.abs(this.$miniBooking.find('.container').outerHeight() - getHeaderHeight()) / 2;
        }
      }

      this.stickyOpts.stick = {
        css: {
          top: top
        }
      };
      stickify(this.$miniBooking, this.stickyOpts);
    }
  }
  showRoomsUnavailableMessage() {
    $('.mobile-dates .mini-booking_availability-calendar').toggleClass('hidden', true);
    $('.edit-dates').toggleClass('hidden', false);
    let $roomsUnavailableEl = this.$miniBooking.find('.rooms-unavailable');
    $roomsUnavailableEl.find('a').removeClass('hidden');
    $roomsUnavailableEl.find('a.edit-dates').addClass('rates-unavailable');
    $roomsUnavailableEl.show().parent().show();
    updateCurrencySelectorCode('Currency',currencyRate);
  }
  hideRoomsUnavailableMessage(_showDatesFlexibleLink) {
    if (_showDatesFlexibleLink) {
        $('.mobile-dates .mini-booking_availability-calendar').toggleClass('hidden', false);
        $('.edit-dates').toggleClass('hidden', true);
    }
    this.$miniBooking.find('.rooms-unavailable').hide()
      .parent()
      .hide();
  }
  showMinLosNotMetMessage(_andErrorButtons) {
    this.$miniBooking.find('.min-los-not-met').show()
      .parent()
      .show();

    if (_andErrorButtons) {
      this.bbForm.errorDatePickerButtons();
    }
  }
  hideMinLosNotMetMessage(_andErrorButtons) {
    this.$miniBooking.find('.min-los-not-met').hide()
      .parent()
      .hide();

    if (_andErrorButtons) {
      this.bbForm.errorDatePickerButtons(true);
    }
  }
  showPricingAndBookNow() {
    this.$miniBooking.find('.search-button-container').show();
    this.$miniBooking.find('.room-pricing-container').show();
  }
  hidePricingAndBookNow() {
    this.$miniBooking.find('.search-button-container').hide();
    this.$miniBooking.find('.room-pricing-container').hide();
  }
  handleRatesLoader(_showLoader) {
    if (_showLoader === true) {
      this.hideMinLosNotMetMessage();
      this.hideRoomsUnavailableMessage(false);
      this.hidePricingAndBookNow();

      this.$miniBooking.find('.no-rates-available').addClass('loading')
        .show();
    } else {
      this.$miniBooking.find('.no-rates-available').hide()
        .removeClass('loading');
    }
    this.$miniBooking.find('.rooms-unavailable a.edit-dates').removeClass('rates-unavailable');
  }
  resizeNotificationBanner() {
    if (isMobileWidth()) {
      $('.component.property-page-hero-carousel-component').next().css('marginTop', $('.booking-bar-form.mini-booking').parent().height());
    }
  }
  handleRatesFromAvailabilityCallError() {
    this.handleRatesLoader(false);

    this.hidePricingAndBookNow();
    this.hideMinLosNotMetMessage();
    this.showRoomsUnavailableMessage();
    this.resizeNotificationBanner();
  }
  handleRatesFromAvailabilityCallSuccess(data) {
    let availListing,
      rate;

    if(data){
      if (data.hostBrand && data.hostBrand.availability && data.hostBrand.availability[0]) {
        availListing = data.hostBrand.availability[0];
      } else if (data.altSell && data.altSell.availability && data.altSell.availability[0]) {
        availListing = data.altSell.availability[0];
      } else {
        availListing = data;
      }
    }

    rate = availListing && availListing.rate ? availListing.rate : null;

    if(rate){
      if ($_PAGE_.is('.property-page') && countryCodeList && ($('.pricing .taxes-fees').text().trim().length > 0) && isAllInPriceRegion()) {
        $('.property-page').addClass('emea-region');
        this._currentRate = _isNumber(rate.totalAfterTax) ? (rate.totalAfterTax * 1) : _isNumber(rate.displayRate) ? (rate.displayRate * 1) : 0;
        $('.pricing .taxes-fees').removeClass('hidden');
      } else if(rate.priceDisplayType){
        this._currentRate = _isNumber(rate.displayRateProcessed) ? (rate.displayRateProcessed * 1) : 0;
      } else {
        this._currentRate = _isNumber(rate.displayRate) ? (rate.displayRate * 1) : 0;
      }

      if(isFeesInclude(rate.priceDisplayType) && rate.priceDisplayType != 'CMA'){
        (rate.priceDisplayType == 'CMA') ? $('.pricing .taxes-fees').removeClass('hidden') : $('.pricing .fees').removeClass('hidden');
      }
      // for TPD analytics
      if (rate && rate.priceDisplayType) {
        try {
          const tpdExperience = sessionStorage.getItem('tpdExperience');
          console.log('in property details page: ', {sessionValue: tpdExperience,serviceValue: rate.priceDisplayType});
          // get updated TPD value. to be implemented in future (DAI-38757)
          const updatedTPDValue = getUpdatedTPDTypeValue(rate.priceDisplayType);
          // if session variable does exists or is not same as rate.priceDisplayType fire an async call
          if (!tpdExperience || tpdExperience.toUpperCase() !== updatedTPDValue.toUpperCase()) {
            sessionStorage.setItem('tpdExperience', updatedTPDValue);
            if (window.digitalData) {
              window.digitalData.totalPriceDisplay = window.digitalData.totalPriceDisplay || {};
              window.digitalData.totalPriceDisplay.experience = updatedTPDValue;
            }
            if (window._satellite && updatedTPDValue) {
              setTimeout(() => {
                console.log('in property details page fire analytics now: ', updatedTPDValue);
                window._satellite.track('totalPriceDisplayExperience');
              }, 2000);
            }
          }
        } catch (error) {
          console.error('Error when firing TPD Experience Analytics ', error);
        }
      }

      this.handleRatesLoader(false);

      if (!this.bbForm.minLosIsNotMet() && (this._currentRate * 1) > 0) {
        this.hideMinLosNotMetMessage();
        this.hideRoomsUnavailableMessage(true);
        this.showPricingAndBookNow();

        // Member-only rate should be replaced by next lowest rate if user not logged in
        let displayRate = this._currentRate,
          currencyCode = rate.currencyCode;

        //let moneySplit = Number(convertToCurrency(toMoney(rateIncludingTaxes), rateData.currencyCode)).toFixed(2).toString().split('.');
        let rateFormatted = Number(convertToCurrency(parseFloat(displayRate), currencyCode));
        //let rateFormatted = parseFloat(displayRate).toFixed(2);
        rateFormatted = getCurrencyMapping(currencyCode) + rateFormatted;
        let rateSplit = rateFormatted.toString().split('.');

        $('.mini-booking-nav .pricing .price').html((getSelectedCurencyCode(currencyCode) == 'USD' ? '$' : '') + rateSplit[0].replace('$', ''));
        var updatedRate = rateFormatted;
        if (rateSplit.length == 2) {
          if (rateSplit[1].length == 1) {
            rateSplit[1] += '0';
          }
          updatedRate = rateSplit[0] + rateSplit[1];
        } else {
          updatedRate = rateSplit[0] + '00';
          rateSplit[1] = '00';
        }
        $('.mini-booking-nav .pricing .cents').html(rateSplit[1]);
        $('.mini-booking-nav .wyn-uplift-content .wyn-uplift-price').attr('data-up-price-value', (updatedRate.replace('$', '')));

        $('.mini-booking-nav .wyn-uplift-content .wyn-uplift-currency-symbol').text(''); //remove text to prevent multiple
        $('.mini-booking-nav .wyn-uplift-content .wyn-uplift-currencyCode').text(''); //remove text to prevent multiple

        if (currencyCode === 'USD') {
          $('.mini-booking-nav .wyn-uplift-content .wyn-uplift-currency-symbol').append('$');
          $('.mini-booking-nav .wyn-uplift-content .wyn-uplift-currencyCode').append('\u00A0' + currencyCode);
        } else {
          $('.mini-booking-nav .wyn-uplift-content .wyn-uplift-currencyCode').append('\u00A0' + currencyCode);
        }

        $('.mini-booking-nav .wyn-uplift-content .wyn-uplift-price').attr({
          'data-up-details-hotel_reservations-0-property_code': availListing.roomTypeCode,
          'data-up-details-hotel_reservations-0-room_type': getName(),
          'data-up-details-hotel_reservations-0-check_in': formatDateForUplift(getCriteria().checkInDate),
          'data-up-details-hotel_reservations-0-rate_code': rate.ratePlanId,
          'data-up-details-hotel_reservations-0-reservation_type': BNPLUplift.isPrepaidCodes(rate.guranteeCode)
        });

        setTimeout(()=>{
          if(getSearchOverview() && !isBNPLEX(getSearchOverview())){
            BNPLUplift.handleConfig({
              currencyCode: rate.currencyCode,
              subtotal: rate.totalAfterTax,
              room: rate,
              noOfRooms:getCriteria().checkInDate.rooms,
              propertyName: getName(),
              checkinDate: formatDateForUplift(getCriteria().checkInDate),
              checkoutDate: formatDateForUplift(getCriteria().checkInDate),
              checkout: false
            });
          } else {
            BNPLUplift.trackBNPLBrandStatus(rate.currencyCode);
        }
        }, 1300);

        //BnplAnalytics.trackUpliftRateClick();


        if (!($('.mini-booking-nav .pricing .units').data('currencyCode')) && getSelectedCurencyCode(rate.currencyCode)) {
          $('.mini-booking-nav .pricing .units').prepend(getSelectedCurencyCode(rate.currencyCode))
            .data('currencyCode', getSelectedCurencyCode(rate.currencyCode));
        } else if (!($('.mini-booking-nav .pricing .units').data('currencyCode'))) {
          $('.mini-booking-nav .pricing .units').prepend(rate.currencyCode)
            .data('currencyCode', rate.currencyCode);
        }
        /*
        if (!($('.mini-booking-nav .pricing .units').data('currencyCode'))) {
          $('.mini-booking-nav .pricing .units').prepend(rate.currencyCode)
            .data('currencyCode', rate.currencyCode);
        }
        */
        $('.room-pricing-container').css({
          'opacity': 1
        });

        EventHandler.send(EventHandler.rates.propertyAvailability.ratesAvailable);
      } else {
        getCurrencyMapping(null);
        this.hidePricingAndBookNow();
        let error = {
          responseJSON: {
            ErrorMessage: ''
          }
        };

        if (this.bbForm.minLosIsNotMet()) {
          error.responseJSON.ErrorMessage = $('.no-rates-available .min-los-not-met').text()
            .trim();
          this.hideRoomsUnavailableMessage(false);
          this.showMinLosNotMetMessage(true);
        } else {
          error.responseJSON.ErrorMessage = $('.no-rates-available .rooms-unavailable').text()
            .trim();
          this.hideMinLosNotMetMessage();
          this.showRoomsUnavailableMessage();
        }
        EventHandler.triggerEvent('brands-error', error);
        EventHandler.send(EventHandler.rates.propertyAvailability.ratesUnavailable);
        if (_isNotNull(rate.message)) {
          console.log(rate.message);
        }
      }
    } else {
      EventHandler.send(EventHandler.rates.propertyAvailability.ratesUnavailable);
    }
    this.resizeNotificationBanner();
  }
  changeMiniBookingBarElement(){
    let isEconomyPropertyPage = $_PAGE_.is('.property-page:not(".uu-property")');

    if(isDesktopWidth()){
      if(isEconomyPropertyPage){
        this.$miniBooking = $_BOOKING_BAR_MINI_FORM;
      } else {
        this.$miniBooking = $_BOOKING_BAR_MINI_NAV;
      }
    } else {
      this.$miniBooking = $_BOOKING_BAR_MINI_NAV;
    }

    this.wrapPropertyNameText();
  }

  wrapPropertyNameText() {
    if((isDesktopWidth() || isTabletWidth()) && $(".mini-booking-new-search-btn").eq(1).length) {
      $(".mini-booking_property-title-text").eq(1).parent().css("width", $(".mini-booking_property-title-text").eq(1).parent().parent().width() - $(".mini-booking-new-search-btn").eq(1).width() - 30);
      window.addEventListener("resize", function() {
        $(".mini-booking_property-title-text").eq(1).parent().css("width", $(".mini-booking_property-title-text").eq(1).parent().parent().width() - $(".mini-booking-new-search-btn").eq(1).width() - 30);
      });
    }
  }

  init() {
    EventHandler.one(EventHandler.serviceCall.end, () => this.disableStick());
    EventHandler.one(EventHandler.serviceCall.end, () => this.enableStick());

    this.$miniBooking.find('.booking-dates-dropdown .dropdown-overflow').append($_BOOKING_BAR_MAIN_.find('.booking-dates-dropdown .min-los-message').clone());

    $_PAGE_.find('.no-rates-available a:not(.edit-dates)').attr('href', getUrlPathFromLocationDetails() + '?' + getQueryString());

    if ($_PAGE_.is('.property-page')) {
      this.changeMiniBookingBarElement();

      EventHandler.on(EventHandler.rates.propertyAvailability.start, () => {
        this.handleRatesLoader(true);
      }).on(EventHandler.rates.propertyAvailability.success, (e, data) => this.handleRatesFromAvailabilityCallSuccess(data))
        .on(EventHandler.rates.propertyAvailability.error, () => this.handleRatesFromAvailabilityCallError());
    }

    ResizeHandler.addResizeEndFn(() => {
      this.disableStick();
      this.enableStick();
    });

    EventHandler.one(EventHandler.UNAP.updated, () => {
      $('.mini-booking .prop-name, .mini-booking-nav .mini-booking_property-title-text').text(getName());
    });
  }
}

/**
 * BBCollapsed
 * Handles collapsed booking bar. This component appears on search results, property
 * pages, and rooms and rates pages. Component is fixed to the top of the screen and
 * can be expanded into the full booking bar to modify search criteria.
 */
class BBCollapsed {
  constructor() {
    this.$el = $('#bookingBarCollapsed');
    this.$openTrigger = $('#bookingBarCollapsed a.toggle,#bookingBar__mini a.mini-booking_new-search');
    this.$closeTrigger = $_BOOKING_BAR_MAIN_.find('a.global-booking_new-search');
    this.$page = $('.page');
    this.$miniBooking = $('.booking-bar-form.mini-booking');
    this.height = 0;
    this.pagePosition = 0;
    this.stickyOpts = {};

    if ($_PAGE_.is('.collapsed')) {
      this.bindOpenClick();
      this.bindCloseClick();
    } else {
      this.$el.hide();
    }
  }
  stick(start, _handleResize) {
    if (typeof (start) === 'number') {
      this.enableStick(start);

      if (_handleResize === true) {
        ResizeHandler.addResizeEndFn(() => {
          this.disableStick();
          this.enableStick(start);
        });
      }
    } else if (start === false) {
      this.disableStick();
    }
  }
  disableStick() {
    this.$el.removeClass('stick sticky stuck');
    this.$el[0].style.top = '';
    $(window).off('scroll', this.scrollCb);
  }
  enableStick(start) {
    this.stickyOpts.start = start;

    this.stickyOpts.onStick = () => {
      this.$el.css({
        top: start
      });
    };

    this.stickyOpts.onUnstick = () => {
      let top = getHeaderHeight();
      if($('.alert-component').length && !$('.alert-component').hasClass('hidden')) {
        top = top + $('.alert-component').height();
      }
      this.$el.css({
        top: top
      });
    };

    this.bookingCollapsedSticky();
    this.scrollCb = () => {
      this.bookingCollapsedSticky();
    };
    $(window).scroll(this.scrollCb);
  }
  bookingCollapsedSticky() {
    stickify(this.$el, this.stickyOpts);
  }
  show() {
    if ($_BOOKING_BAR_MAIN_.find('.dropdown-container.open').length === 0) {
      this.$page.addClass('collapsed');
      $_BOOKING_BAR_MAIN_.addClass('stop').removeAttr('style');
      bbExpandCollapseInst.collapse();
    } else {
      $(window).scrollTop(this.pagePosition);
      $(window).one(scrollEvents, () => this.show());
    }

    $_BOOKING_BAR_MAIN_.find('.destination').blur();
  }
  hide() {
    this.$page.removeClass('collapsed');

    let retailBannerHeight = hasRetailBanner() ? $('.retail-banner-component').height() : 0;
    $_BOOKING_BAR_MAIN_.removeClass('stop').addClass('fade-in').css({
      top: ($_PAGE_.is('.uu-property,.uu-overview') && $_BOOKING_BAR_MINI_NAV.is('.stick')) ? 0 : getHeaderHeight() + $_HEADER_.position().top + retailBannerHeight,
      opacity: 1
    })
      .show()
      .one(transitionEvents, once(() => {
        if (!($_BOOKING_BAR_MAIN_.is('.stop'))) {
          $_BOOKING_BAR_MAIN_.css({
            zIndex: 999
          });

          $(window).one(scrollEvents + ' resize', () => this.show());

          this.pagePosition = $(window).scrollTop();
        }
      }));
    bbExpandCollapseInst.expand();
  }
  bindOpenClick() {
    this.$openTrigger.click((e) => {
      e.preventDefault();
      this.hide();
      if (($_PAGE_.is('.uu-property,.uu-overview')) && (!isMobileWidth())) {
        this.$miniBooking.parent().attr('data-z-index', '100');
        this.$miniBooking.parent().css('z-index', '100');
      }
    });
  }
  bindCloseClick() {
    this.$closeTrigger.click((e) => {
      e.preventDefault();
      this.show();
    });
  }
}

class BBAvailabilityCalendar {
  constructor(bbForm) {
    this.bbForm = bbForm;
    this.$calendarLink = $('.mini-booking_availability-calendar');
    this.$editDates = $('.edit-dates');
    this.$calendarElements = $('.mini-booking_availability-calendar, .mini-booking_availability-calendar + .divider, .mini-booking-dates-flexible');
    this.opts = {};

    EventHandler.one(EventHandler.property.search.success, (e,data) =>  {
      this.init(data);
      this.onEditDatesClick();
    });
  }
  init(data) {
    this.bbForm.setChildAvailabilityCalendar(this);
    this.data = data;
    if (this.isAvailabilityCalendarSetUp() && !this.bbForm.getRedeemWRPoint()) {
      this.toggleAvailabilityCalendarLinks(false);
      this.$calendarLink.on('click', (event) => {
        event.preventDefault();
        this.opts.propertyId = data.id;
        this.openModal();
      });
    } else {
      this.toggleAvailabilityCalendarLinks(true);
    }
  }
  onEditDatesClick() {
    this.$editDates.on('click', (e) => {
      e.preventDefault();
      this.opts.propertyId = this.data.id;
      if (this.isAvailabilityCalendarSetUp() && !this.bbForm.getRedeemWRPoint()) {
        this.openModal();
      } else {
        $('.mini-booking .check-in-button').triggerHandler('click');
        $('.mini-booking .mini-booking-nav').css('zIndex', 999);
      }
    });
  }
  isAvailabilityCalendarSetUp() {
    return !!window.availabilityCalendar && this.$calendarLink.length > 0 &&
      this.data && this.data.synxis === true;
  }
  toggleAvailabilityCalendarLinks(show) {
    let shouldShow = !this.isAvailabilityCalendarSetUp() ? true : show;
    this.$calendarElements.toggleClass('hidden', shouldShow);
  }
  openModal() {
    isMobileWidth() ? EventHandler.send('ac-open-mobile', null, this.opts) : EventHandler.send('ac-open-modal', null, this.opts);
  }
}

let bbLoadInst = new BBLoad(),
  bbExpandCollapseInst = new BBExpandCollapse(),
  bbCollapsedInst = new BBCollapsed(),
  bbFormInst = new BBForm(),
  bbAvailabilityCalendarInst = new BBAvailabilityCalendar(bbFormInst),
  bbMiniInst, bbDatePicker, bbMoreOptions, bbRoomsGuests, bookNowModal;
if (bookingBarInit === '0') {
  window.booking_bar_init = '1';
 }

function initBBSubPieces() {
  if (exists(bbFormInst.$mini)) {
    bbMiniInst = new BBMini(bbFormInst);
    bbDatePicker = new BBDatePicker(bbFormInst, bbMiniInst);
    bbMoreOptions = new BBMoreOptions(bbFormInst, bbMiniInst, $('.booking-bar-form.booking-bar-main, .booking-bar-form.mini-booking'));
    bbRoomsGuests = new BBRoomsGuests(bbFormInst, bbMiniInst);
    bookNowModal = new BookNowModal(bbFormInst, bbMiniInst, bbDatePicker);
  } else {
    bbDatePicker = new BBDatePicker(bbFormInst);
    bbMoreOptions = new BBMoreOptions(bbFormInst, null, $('.booking-bar-form.booking-bar-main, .booking-bar-form.mini-booking'));
    bbRoomsGuests = new BBRoomsGuests(bbFormInst);
    bookNowModal = new BookNowModal(bbFormInst, null, bbDatePicker);
  }
  bbFormInst.setChildBBInstances(bbMiniInst, bbDatePicker, bbMoreOptions, bbRoomsGuests, bookNowModal);
}

export {
  initBBSubPieces,
  bbLoadInst as BBLoad,
  bbFormInst as BBForm,
  bbMiniInst as BBMini,
  bbDatePicker as BBDatePicker,
  bbMoreOptions as BBMoreOptions,
  BBMoreOptions as BBMoreOptionsClass,
  bbRoomsGuests as BBRoomsGuests,
  bookNowModal as BookNowModal,
  bbExpandCollapseInst as BBExpandCollapse,
  bbCollapsedInst as BBCollapsed,
  bbAvailabilityCalendarInst
};

window.wynPerf.mark('wyn-booking-bar-end');
