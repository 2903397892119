import Handlebars from 'handlebars';
import {callService, formatTimeShow, getServiceUrl, dateInRange, _isValidBrand, _isValidBrandTier, fetchOtherAmenities} from "../base/utils";
import {getBrand} from "../base/session-handler";
import { $_PAGE_, _LOCALE_, _LANGUAGE_ } from "../base/vars";
import FeaturedAmenityIcons from "./featured-amenity-icons.js";
import PropertyPolicies from "./property-policies.js";
import PropertyAmenities from "./property-amenities.js";
import ExpandableItem from '../wr/components/expandable-item.js';
import UUMap from './upscale/uu-wide-map';
import {exists} from "../base/dom-utils";
import RenderFavourite from './render-favourite.js';
import OverviewService from '../pages/overview-service.js';

class PropertyQuickView {
  constructor() {
    this._data = {};
    this.payload = {};
    this.hotelCollectionsData = [];
    this.amenityBadge = "";

    // Get Property Quick View modaal and template
    this._propertyQuickViewModal = $('#bb-md');
    this._propertyQuickViewTemplateElement = $('#propertyQuickViewTemplate');

    // Get Propery Policies modal and template
    this._propertyPoliciesModal = $('#hotelPoliciesLightbox');
    this._propertyPoliciesTemplateElement = $('#propertyPoliciesTemplate');

    if (
      ($_PAGE_.is('.search-results-page') || $_PAGE_.is('.collection-hotels-page') || $_PAGE_.is('.my-favorite-page')) &&
      this._propertyQuickViewModal.length &&
      this._propertyQuickViewTemplateElement.length
    ) {
      // Prepare Property Qucik View modal
      this._propertyQuickViewTemplate = Handlebars.compile(this._propertyQuickViewTemplateElement.html());
      this._propertyQuickViewContent = this._propertyQuickViewModal.find('.modal-content');

      // Prepare Property Policies modal
      this._propertyPoliciesTemplate = Handlebars.compile(this._propertyPoliciesTemplateElement.html());
      this._propertyPolicesContent = this._propertyPoliciesModal.find('.modal-content');

      ExpandableItem.bindEvents();
    }
  }
  async callCollectionAPIFeatured(propertyId = null) {
    const callCollectionEndpoint = getServiceUrl('mySearchFavourite');
    const callCollection = `
      query sample($searchParameters: SearchParameters) {
        version
        searchProperties(searchParameters: $searchParameters) {
          properties {
            id
            propertyId
            name
            headline
            additionalDetails {
              name
              values
            }
            rating
            ratingSiteStatus
            ratingCount
            subRatings {
                ratingRoomsAverage
                ratingServiceAverage
                ratingValueAverage
                ratingCleanlinessAverage
            }
            uniqueUrl
            address {
              address1
              address2
              country
              city
              countryCode
              postalCode
              state
              stateCode
            }
            brand
            brandTier
            sabreId
            otherAmenities {
                rankId
                amenityName
                otaCode
                amenityNameEn
                accessibleCode
            }
          }
        }
      }
    `;

    const callCollectionVariables = {
      searchParameters: {
        language: _LOCALE_,
        limit: 50,
        offset: 0,
        additionalDetails: ['hotelQualityScoreRequirementFlag'],
        propertyIds: propertyId ? [propertyId] : null
      },
    };

    try {
      const response = await fetch(callCollectionEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: callCollection,
          variables: callCollectionVariables,
        }),
      });

      if (!response.ok) {
        this._data.isValidPropertyInModal = false;
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseBody = await response.json();
      return responseBody;
    } catch (error) {
      this._data.isValidPropertyInModal = false;
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  async loadInitialProperties(propertyIds) {
    try {
      const apiResponse = await this.callCollectionAPIFeatured(propertyIds);
      this.hotelCollectionsData = apiResponse.data.searchProperties.properties || [];
    } catch (error) {
      console.error('Error loading properties:', error);
    }
  }
  async init(propertyId, propertyUrl, bookNowUrl, notificationData) {
    this._propertyId = propertyId;
    this._propertyUrl = propertyUrl;
    this._bookNowUrl = bookNowUrl;

    if (notificationData) {
      notificationData.visible =
        notificationData.headlineText &&
        this._dateInRange(notificationData.notificationStartDate, notificationData.notificationEndDate);
    }
    this._data.notificationData = notificationData;
    await this.loadInitialProperties(propertyId);
    const propertyInModal = this.hotelCollectionsData;
    const _isPropertyInModalExist = propertyInModal && propertyInModal.length > 0 && Array.isArray(propertyInModal);
    const amentiesArr = _isPropertyInModalExist && propertyInModal[0].otherAmenities.map((item) => item.amenityName);
    this.payload = [
      {
        language: _LANGUAGE_,
        startAmenities: amentiesArr,
        propertyId: propertyId,
      },
    ];
    const resAmenties = await fetchOtherAmenities(this.payload);

    const additionalDetails = _isPropertyInModalExist && propertyInModal[0].additionalDetails;
    if (!Array.isArray(additionalDetails)) {
      return 'All Inclusive'; // or any other default string
    }
    const detail = additionalDetails.find(
      ({ name, values }) => name === 'hotelQualityScoreRequirementFlag' && Array.isArray(values) && values.includes('Y')
    );
    this.amenityBadge = detail
      ? 'Guest Recommended'
      : resAmenties[Object.keys(resAmenties)]['amenityName']
      ? resAmenties[Object.keys(resAmenties)]['amenityName']
      : '';
      
    this._data.propertyInModal = propertyInModal[0];
    this._data.isValidPropertyInModal = _isPropertyInModalExist;
    
    this._getPropertyData();
  }
  
  _getBrandCode(brand, tier) {
    if (_isValidBrand(brand)) {
      return brand;
    } else if (_isValidBrandTier(tier)) {
      return tier;
    }
    return getBrand(false);
  }

  async _getPropertyData() {
    try {
      const overview = await OverviewService.getOverview(this._propertyId);
      this._data.propertyOverview = overview;
      const brandCode = this._getBrandCode(this._data.propertyOverview.brand, this._data.propertyOverview.brandTier);

      let hotelRequestData = {
        locale: _LOCALE_,
        hotels: [],
      };
      let hotelObject = {
        brandId: brandCode,
        hotelCode: this._propertyId,
        hotelId: this._propertyId,
        location: this._data.propertyOverview.country,
      };
      hotelRequestData.hotels.push(hotelObject);

      const result = await $.ajax({
        url: window.location.protocol + '//' + window.location.host + '/bin/propertyDataList.json',
        type: 'POST',
        data: JSON.stringify(hotelRequestData),
        dataType: 'json',
        contentType: 'application/json',
      });

      if (result && result.hotels.length > 0 && result.error === false && result.hotels[0].phone) {
        this._data.propertyOverview.phone = result.hotels[0].phone;
      }

      const params = { items: `${_LOCALE_}:${this._propertyId}:${brandCode}` };

      const imageRes = await callService(getServiceUrl('getPropertyImage'), params);
      if (imageRes && imageRes.length && imageRes[0][0].searchResponse && imageRes[0][0].searchResponse.imageList) {
        this._data.propertyOverview.propertyImages = [];

        for (let i = 0; i < imageRes[0][0].searchResponse.imageList.length; i++) {
          this._data.propertyOverview.propertyImages.push(...imageRes[0][0].searchResponse.imageList[i].imageList);
        }
      }

      try {
        const amenityIconsMap = await FeaturedAmenityIcons.getAmenityIconsMap();
        this._data.amenitiesIconMap = amenityIconsMap;
      } catch (error) {
        console.error('Error fetching amenity icons:', error);
      }

      this._tryRender();
    } catch (error) {
      console.error('Error in _getPropertyData:', error);
    }
  }


  _addViewPolicesListener() {
    $('#bb-view-policies').on('click', () => {
      this._propertyQuickViewModal.modal('hide');
      setTimeout(() => {
        this._propertyPoliciesModal.modal('show');
      }, 500);
    });
  }

  _showRatingsAndReviews() {
    if(this._data.propertyInModal.rating && this._data.propertyInModal.ratingCount && this._data.propertyInModal.ratingSiteStatus && this._data.propertyInModal.ratingSiteStatus.toLowerCase() == 'active'){
      //overall-rating-points
      $('.popup-reviews-value').text(this._data.propertyInModal.rating.toFixed(1));
      //review-count replace('${ratingCount}',this.data.ratingCount));
      $('.popup-review-counts').text('(' + this._data.propertyInModal.ratingCount + ' reviews)');
      //ratings-stars large screen
      $('.review-star-populate').children().each((i,e)=> {
        $(e).removeClass();
        if(this._data.propertyInModal.rating >= i + 1){
            $(e).addClass('amenity-icon-star');
        }else if(this._data.propertyInModal.rating >= i) {
          if((0.3 <= (Math.round((this._data.propertyInModal.rating - Math.floor(this._data.propertyInModal.rating)) * 10) / 10 ) && (Math.round((this._data.propertyInModal.rating - Math.floor(this._data.propertyInModal.rating)) * 10) / 10) <= 0.7)){
            $(e).addClass('amenity-icon-star-half');
          }else if((Math.round((this._data.propertyInModal.rating - Math.floor(this._data.propertyInModal.rating)) * 10 ) / 10) >= 0.8){
            $(e).addClass('amenity-icon-star');
          }else{
            $(e).addClass('amenity-icon-star-empty');
          }
        } else {
          $(e).addClass('amenity-icon-star-empty');
        }
      });
      //ratings-stars small screens
      $('.review-star-populate-md').children().each((i,e)=> {
        $(e).removeClass();
        if(this._data.propertyInModal.rating >= i + 1){
            $(e).addClass('amenity-icon-star');
        }else if(this._data.propertyInModal.rating >= i) {
          if((0.3 <= (Math.round((this._data.propertyInModal.rating - Math.floor(this._data.propertyInModal.rating)) * 10) / 10) && (Math.round((this._data.propertyInModal.rating - Math.floor(this._data.propertyInModal.rating)) * 10) / 10) <= 0.7)){
            $(e).addClass('amenity-icon-star-half');
          }else if((Math.round((this._data.propertyInModal.rating - Math.floor(this._data.propertyInModal.rating)) * 10) / 10) >= 0.8){
            $(e).addClass('amenity-icon-star');
          }else{
            $(e).addClass('amenity-icon-star-empty');
          }
        } else {
          $(e).addClass('amenity-icon-star-empty');
        }
      });
    } else {
      $('.quickview-rating-review').hide();
      $('.review-counts').hide();
    }
  }

  _dateInRange(start, end) {
    const startDate = start ? new Date(start) : '',
      endDate = end ? new Date(end) : '';

    if (startDate && endDate) {
      endDate.setMilliseconds(0);
      endDate.setSeconds(59);
      endDate.setMinutes(59);
      endDate.setHours(23);
      const todayDateUTC = new Date().toUTCString();
      const startDateUTC = startDate.toUTCString();
      const endDateUTC = endDate.toUTCString();
      return dateInRange(todayDateUTC, startDateUTC, endDateUTC);
    }
    return true;
  }

  _tryRender() {
    if (this._data.propertyOverview) {
      this._data.propertyOverview.propertyUrl = this._propertyUrl;
      this._data.propertyOverview.bookNowUrl = this._bookNowUrl;
      this._data.propertyOverview.checkInTime = formatTimeShow(this._data.propertyOverview.checkInTime);
      this._data.propertyOverview.checkOutTime = formatTimeShow(this._data.propertyOverview.checkOutTime);
      this._data.propertyOverview.notification = this._data.notificationData;
      this._data.propertyOverview.featuredAmenities = new PropertyAmenities(
        this._data.propertyOverview,
        this._data.amenitiesIconMap
      );
      this._propertyQuickViewContent.html(this._propertyQuickViewTemplate(this._data.propertyOverview));

      const propertyPolicies = new PropertyPolicies(this._data.propertyOverview);
      this._propertyPolicesContent.html(this._propertyPoliciesTemplate(propertyPolicies));

      // Initialize Google map
      const brandCode = this._getBrandCode(this._data.propertyOverview.brand, this._data.propertyOverview.brandTier);
      this._propertyQuickViewContent
        .find('#mapDiv')
        .append(
          UUMap.getStaticMapImg(
            brandCode,
            this._data.propertyOverview.latitude,
            this._data.propertyOverview.longitude,
            12,
            '457x191',
            'bb-md-map-img'
          )
        );

      // Add View Policies listener
      this._addViewPolicesListener();

      // Show modal
      this._propertyQuickViewModal.modal('show');
      
      // show Ratings and Reviews
      let quickviewSmallDev = $(".bb-md-carousel").find("#quickview-visiblity-md");
      let quickviewLargeDev = $(".bb-md-data-content").find("#quickview-visiblity-lg");
      if(this._data.propertyInModal && this._data.isValidPropertyInModal && this._data.isValidPropertyInModal != "") {
        if (quickviewLargeDev) {
          quickviewLargeDev.removeClass('d-none');
        }
        if (quickviewSmallDev) {
          quickviewSmallDev.removeClass('d-none');
        }
        this._showRatingsAndReviews();
      }

      //initialize mobile gallery
      if (exists('#uuCarousel')) {
        $('#bb-md-carousel .carousel').carousel({
          swipe: true,
        });

        //dots animation
        $('#uuCarousel').bind('slide.bs.carousel', function(e) {
          if (e.direction == 'left') {
            $('.carousel-dots-container').addClass('bb-next-dots');
          }
          if (e.direction == 'right') {
            $('.carousel-dots-container').addClass('bb-prev-dots');
          }
        });

        $('#uuCarousel').bind('slid.bs.carousel', function(e) {
          if (e.direction == 'left') {
            $('.carousel-dots-container').removeClass('bb-next-dots');
          }
          if (e.direction == 'right') {
            $('.carousel-dots-container').removeClass('bb-prev-dots');
          }
        });
      }

      this.insertFavIconToModal('.search-results-page', this._propertyId);
      this.insertFavIconToModal('.collection-hotels-page', this._propertyId);
      this.insertFavIconToModal('.my-favorite-page', this._propertyId);
    }
  }

  insertFavIconToModal(pageType, propdId) {
    const isPageType = document.querySelector(pageType);
    if (isPageType) {
      const linksWrapper = isPageType.querySelector('#bb-md .bb-md-header-search-container .bb-md-header-search .bb-md-content-links');
      const fceElem = document.createElement("div");
        fceElem.classList.add("featured-card-eyebrow");
        fceElem.textContent = this.amenityBadge;
      RenderFavourite.fetchFavourite(linksWrapper, propdId, 'link-open');
      this.amenityBadge && isPageType.querySelector('#bb-md .bb-md-header-search-container .bb-md-header-search').append(fceElem);
    }
  }
}

let propertyQuickViewInst = new PropertyQuickView();
window.Wyndham = window.Wyndham || {};
window.Wyndham.PropertyQuickView = propertyQuickViewInst;

export default propertyQuickViewInst;

