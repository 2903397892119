import { getBrand, UserHandler,deletePropAvailSessionData, deleteSearchOverviewSessionData,deleteRoomsRatesSessionData } from '../base/session-handler.js';
import { exists } from '../base/dom-utils.js';
import { populateForm } from '../base/form-population.js';
import { formatDateForBWS, EventHandler, getServiceUrl } from '../base/utils.js';
import { brand_id, grecaptcha } from '../base/vars.js';
import Analytics from '../base/wyn-analytics-module.js';
import {
  stateChange,
  countryChange
} from '../base/form-population.js';
import PhoneInput from '../wr/components/phone-input';

const recentstayfeedback = 'recentstayfeedback';

const billingissues = 'billingissues';

const existingcustomercarecase = 'existingcustomercarecase';

class ContactUs {
  constructor() {
    $(() => {
      this.form = $('.contact-form');
      this.bindContactForm();
    });
    if(window.location.href.includes('/contact-us/about-my-stay')) {
      PhoneInput.initializePhoneInputs();
    }
  }

  bindContactForm() {
    if (exists(this.form)) {
      this.expanded = false;
      this.multiSelectBrands = '';

      var $dateCheckin = $('#checkin');
      var $dateCheckout = $('#checkout');
      var $dateInput = $('#customerDateOfStay');

      var aboutMyStay = $('.about-my-stay');

      if (exists(aboutMyStay)) {
        this.hideComponentForm();
      }

      if($dateInput.length){
        // Dynamic placeholder based on locale. Fall back to mm/dd/yyyy for no locale.
        $dateInput.attr('placeholder', window.localeDateFormat.placeholder || 'mm/dd/yyyy');
      }

      $('#corpContactCategory > option').each((i, el) => {
        if (getBrand().toLowerCase() === $(el).val()) {
          $(el).detach()
            .insertAfter('#corpContactCategory > option:first-child');
        }
      });

      // Dynamic changing of state field based on country chosen
      $('.country select').change((e) => {
        let stateSelect = $('.state-province select');
        let country = $(e.currentTarget).val();
        countryChange(stateSelect, country);
      });

      $('.state-province select').change((e) => {
        let countrySelect = $('.country select');
        let state = $(e.currentTarget).val();
        stateChange(countrySelect, state);
      });

      $('.group-country select').change((e) => {
        let stateSelect = $('.group-state-province select');
        let country = $(e.currentTarget).val();
        countryChange(stateSelect, country);
      });

      $('.group-state-province select').change((e) => {
        let countrySelect = $('.group-country select');
        let state = $(e.currentTarget).val();
        stateChange(countrySelect, state);
      });

      if ($dateInput !== null) {
        $dateInput.datepicker({
          dateFormat: 'mm/dd/yy',
          minDate:'-6m'
        }).on("change", function(e) {
          var curDate = $(this).datepicker("getDate");
          var minDate = new Date();
          minDate.setDate(minDate.getDate() - 184);
          minDate.setHours(0, 0, 0, 0);
          if (curDate < minDate) {
            $(this).datepicker("setDate", minDate);
          }
        });
      }

      if ($dateCheckin.length) {
        $dateCheckin.datepicker({
          dateFormat: 'mm/dd/yy',
          minDate:'-6m'
        }).on("change", function(e) {
          var curDate = $(this).datepicker("getDate");
          var minDate = new Date();
          minDate.setDate(minDate.getDate() - 184);
          minDate.setHours(0, 0, 0, 0);
          if (curDate < minDate) {
            $(this).datepicker("setDate", minDate);
          }
        });
      }

      if ($dateCheckout.length) {
        $dateCheckout.datepicker({
          dateFormat: 'mm/dd/yy',
          minDate:'-6m'
        }).on("change", function(e) {
          var curDate = $(this).datepicker("getDate");
          var minDate = new Date();
          minDate.setDate(minDate.getDate() - 184);
          minDate.setHours(0, 0, 0, 0);
          if (curDate < minDate) {
            $(this).datepicker("setDate", minDate);
          }
        });
      }

      window.Parsley.addValidator("validdate", {
        fn: function(value) {
          return /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(value);
        },
        priority: 256
      });

      // Find reservation functionality
      var $noConfirmationNumberLink = $('.js-noconfirmation-number');
      var $lookUpReservationsBtn = $('.js-btn-lookup-reservation');
      var $lookUpNewReservationsBtn = $('.js-btn-new-reservation');

      // var $customerFirstNameField = $('#customerFirstName');
      // var $customerLastNameField = $('#customerLastName');
      // var $confirmationNumberField = $('#confirmationNumber');
      // var $rewardNumberField = $('#rewardNumber');

      if ($noConfirmationNumberLink !== null && $lookUpReservationsBtn !== null) {
        $noConfirmationNumberLink.on('click', () => {
          Analytics.getAboutMyStayAnalytics().trackIDontKnowMyConf();
          $noConfirmationNumberLink.addClass('hide');
          $lookUpReservationsBtn.addClass('hide');
          this.showComponentForm();
          this.resetNameForm();
          this.resetContactDetails();
          this.resetBillingAddress();
          this.resetReservationDetails();
          this.makeReservationLookupRequired(false);
          this.resetNoReservationFound();
        });

        var $findReservationFields = $('.form-findreservation input');

        // Find reservation click event
        $lookUpReservationsBtn.on('click', (e) => {
          e.preventDefault();

          // Validate all input fields.
          var isValid = true;

          $findReservationFields.each(function() {
            if ($(this).parsley().validate() !== true) isValid = false;
          });
          if (isValid) {
            // Find reservation logic and populate fields
            var reservationExists = true;
            if (reservationExists) {

              $findReservationFields.each(function() {
                $(this).prop('disabled', true);
              });

              // $customerFirstNameField.prop('disabled', true);
              // $customerLastNameField.prop('disabled', true);
              // $confirmationNumberField.prop('disabled', true);
              // $rewardNumberField.prop('disabled', true);

              $lookUpReservationsBtn.addClass('hide');
              $lookUpNewReservationsBtn.removeClass('hide');
              this.showComponentForm();
            }
          }
        });

        $lookUpNewReservationsBtn.on('click', (e) => {
          e.preventDefault();

          this.resetNameForm();
          this.resetNoReservationFound();
          this.resetReservationDetails();

          $lookUpReservationsBtn.removeClass('hide');
          $lookUpNewReservationsBtn.addClass('hide');
          this.hideComponentForm();
        });
      }

      var categoryComp = $('.category-form.category-form-about-stay');
      if (categoryComp !== null) {
        var $contactCategorySelect = $('#contactCategory');
        var $subCategorySelect = $('#subCategory');
        var $caseNumberLabel = $('#CaseNumberLabel');
        var $billingmessge = $('#billingissuemessage');

        if ($contactCategorySelect !== null && $subCategorySelect !== null) {

          $contactCategorySelect.on('change', function() {
            if (this.value == recentstayfeedback) {
              $subCategorySelect.parent().removeClass('hide');
              $caseNumberLabel.parent().addClass('hide');
              $billingmessge.parent().addClass('hide');

              $subCategorySelect.attr('required', '');
              $caseNumberLabel.removeAttr('required');

            } else if (this.value == existingcustomercarecase) {
              $subCategorySelect.parent().addClass('hide');
              $caseNumberLabel.parent().removeClass('hide');
              $billingmessge.parent().addClass('hide');

              $subCategorySelect.removeAttr('required');
              $caseNumberLabel.attr('required', '');

            } else if (this.value == billingissues) {
              $subCategorySelect.parent().addClass('hide');
              $caseNumberLabel.parent().addClass('hide');
              $billingmessge.parent().removeClass('hide');
            }
          });

        }
      }

      // If user is logged in populate form data.
      EventHandler.one(EventHandler.fullProfile.fetched, () => {
        populateForm(UserHandler.getWRUserInfo());
        if (UserHandler.isWRLoggedIn()) {
          $('#accountcheckbox').parent().parent().parent().removeClass('hide');
          $('.hold-rate-msg').removeClass('hide');
          $('input[name="accountcheckbox"]').prop('checked', true);
          $('input[name="accountcheckbox"]').change(function() {
            if ($(this).is(':checked')) {
              populateForm(UserHandler.getWRUserInfo());
            } else {
              $('input[name="customerAddress"]').val('');
              $('input[name="customerAddress2"]').val('');
              $('input[name="customerCity"]').val('');
              $('select[name="customerStateCode"]').val('defaultState').trigger('change');
              $('select[name="customerCountryCode"]').val('defaultCountry').trigger('change');
              $('input[name="customerPostalCode"]').val('');
              $('input[name="customerEmail"]').val('');
              $('input[name="confirmEmail"]').val('');
              $('input[name="customerPhone"]').val('');
            }
          });
        }
      });

      if (!UserHandler.isWRLoggedIn()) {
        $('#accountcheckbox').parent().parent().parent().addClass('hide');
        $('.hold-rate-msg').addClass('hide');
      }

      this.form.on('submit', (e) => {
        e.preventDefault();
        this.handleContactSubmit(this.form);
      });

      $('#checkboxes ul li label.name').each((i, el) => {
        if (getBrand() === ($(el).attr('name'))) {
          $(el).parent()
            .detach()
            .prependTo('#checkboxes ul');
        }
      });

      $(document).on('mouseup', (e) => {
        let container = $('#checkboxes');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          container.hide();
        }
      });

      $('.contact-form select').on('change', (e) => {
        $(e.currentTarget).css('font-style', 'normal');
      });
      /* Feedback textarea count updated as per new authorable fields */
      $('.your-feedback textarea').keyup((e) => {
        let maxFeedbackLength = $('#yourFeedback').attr('maxlength');
        if (!maxFeedbackLength) {
          maxFeedbackLength = $('#yourFeedback').data('parsley-maxlength');
        }
        $('.your-feedback .remaining-char-count').text(maxFeedbackLength - ($(e.currentTarget).val().length));
      });
      deletePropAvailSessionData();
      deleteSearchOverviewSessionData();
      deleteRoomsRatesSessionData();
    }
  }

  makeReservationLookupRequired(required) {
    const parent = $('.form-findreservation').parent();
    const confirmationNumber = $('input[name="confirmationNumber"]', parent);
    const confirmationNumberLabel = confirmationNumber.siblings('label[for="confirmationNumber"]');
    if(required) {
      confirmationNumber.attr('required');
      confirmationNumberLabel.text(confirmationNumberLabel.data('requiredLabel'));
    } else {
      $('input[name="rewardNumber"]',parent).removeAttr('required');
      confirmationNumber.removeAttr('required');
      confirmationNumberLabel.text(confirmationNumberLabel.data('optionalLabel'));
    }

    // execute parsley validation on the confirmationNumber field to update the validation/error message status
    confirmationNumber.parsley().validate();
  }

  hideComponentForm() {
    $('.nameform').hide();
    $('.text').hide();
    $('.billingaddress').hide();
    $('.contactdetails').hide();
    $('.reservationdetails').hide();
    $('.recaptcha').hide();
    $('.submit').hide();
  }

  showComponentForm() {
    $('.nameform').show();
    $('.text').show();
    $('.billingaddress').show();
    $('.contactdetails').show();
    $('.reservationdetails').show();
    $('.recaptcha').show();
    $('.submit').show();
}

  resetNameForm() {
    // clear first name, last name, and reward number field for unauthenticated users only
    if (UserHandler.isWRLoggedIn()) {
      $('input[name="customerFirstName"]').prop('disabled', false);
      $('input[name="customerLastName"]').prop('disabled', false);
      $('input[name="rewardNumber"]').prop('disabled', false);
    } else {
      $('input[name="customerFirstName"]').prop('disabled', false).val('');
      $('input[name="customerLastName"]').prop('disabled', false).val('');
      $('input[name="rewardNumber"]').prop('disabled', false).val('');
    }
    $('input[name="confirmationNumber"]').prop('disabled', false).val('');
  }

  resetBillingAddress() {
    $('input[name="customerAddress"]').val('');
    $('input[name="customerAddress2"]').val('');
    $('input[name="customerCity"]').val('');
    $('select[name="customerStateCode"]').val('');
    $('select[name="customerCountryCode"]').val('');
    $('input[name="customerPostalCode"]').val('');
    $('input[name="customerEmail"]').val('');
  }

  resetContactDetails() {
    $('input[name="customerEmail"]').val('');
    $('input[name="confirmEmail"]').val('');
    $('input[name="customerPhone"]').val('');
  }

  resetReservationDetails() {
    $('input[name="firstName"]').val('');
    $('input[name="lastName"]').val('');
    $('input[name="checkin"]').val('');
    $('input[name="checkout"]').val('');
    $('input[name="hotelBrand"]').val('');
    $('input[name="hotelcity"]').val('');
    $('input[name="hotelName"]').val('');
    $('textarea[name="yourFeedback"]').val('');
  }

  resetNoReservationFound() {
    $('.no-reservation-found','.find-reservation-component').css('display', 'none');
  }

  handleContactSubmit(form) {

    Analytics.updateFormName();
    let contactObj = {};
    let groupSellFrom = $('.group-travel');
    let corporateSellFrom = $('.corporate-category-form-component');
    let bestRateForm = $('.best-rate');
    let aboutMyStayForm = $('.about-my-stay');
    var leadSource = document.getElementById("lead_source");

    if(leadSource != null){
      leadSource = document.getElementById("lead_source").value;
    }

    let doNotSellFrom = $('.do-not-sell-info'),postURL;
    if(exists(corporateSellFrom) && (leadSource == "Corporate Rate Request")) {
      let responseToken = $('.g-recaptcha').attr('data-responsetoken');
      if(!responseToken) {
        $('.g-recaptcha > div').addClass("input-error");
        return false;
      }
      let formCorpTravel = document.createElement('form');
      var oid = document.getElementById("oid").value;
      var recordType = document.getElementById("recordType").value;
      var retURL = document.getElementById("retURL").value;
      var actionUrl = document.getElementById("actionUrl").value;
      //Changes For DAI-14322
      var orgId = document.getElementById("orgId").value;
      orgId = '{"keyname":"webtolead","fallback":"true","orgId":"' + orgId + '","ts":"' + new Date().getTime() + '"}';
      // Add form attributes
      formCorpTravel.action = actionUrl;
      formCorpTravel.method = 'post';
      formCorpTravel.classList.add('not-ajax');
      // Add hidden inputs to form
      this.addInput(formCorpTravel, 'oid', oid);
      this.addInput(formCorpTravel, 'retURL', retURL);
      this.addInput(formCorpTravel, 'recordType', recordType);
      this.addInput(formCorpTravel, 'lead_source', leadSource);
      this.addInput(formCorpTravel, 'Corporate_Form_Brand__c', $('#corpContactCategory').val());
      this.addInput(formCorpTravel, 'Group_Travel_Form_Brand_ID__c', brand_id.toUpperCase());
      this.addInput(formCorpTravel, 'first_name', $('.contact-form .first-name input').val());
      this.addInput(formCorpTravel, 'last_name', $('.contact-form .last-name input').val());
      this.addInput(formCorpTravel, 'email', $('.contact-form .email input').val());
      this.addInput(formCorpTravel, 'phone', $('.contact-form .phone input').val());
      this.addInput(formCorpTravel, 'street', $('.contact-form .street input').val());
      this.addInput(formCorpTravel, 'city', $('.contact-form .city input').val());
      this.addInput(formCorpTravel, 'state', $('.contact-form .state-province select').val());
      this.addInput(formCorpTravel, 'country', $('.contact-form .country select').val());
      this.addInput(formCorpTravel, 'zipCode', $('.contact-form .postcode input').val());
      this.addInput(formCorpTravel, 'GSOEstimatedLodgingSpend__c', $('.contact-form .estimated-Annual-Lodging-Spend input').val());
      this.addInput(formCorpTravel, 'company', $('.contact-form .comp-name input').val());
      this.addInput(formCorpTravel, 'Property_Name__c', $('.contact-form .specific-Cities-Hotels input').val());
      this.addInput(formCorpTravel, 'Group_Travel_Request_Specifications__c', $('.contact-form .your-feedback textarea').val());
      //Changes For DAI-14322
      this.addInput(formCorpTravel, 'captcha_settings', orgId);
      this.addInput(formCorpTravel, 'g-recaptcha-response', responseToken);
      // Append form
      document.body.appendChild(formCorpTravel);
      // Submit
      formCorpTravel.submit();

    } else if(exists(groupSellFrom) && (leadSource == "Group Travel Form")) {
      let responseToken = $('.g-recaptcha').attr('data-responsetoken');
      if(!responseToken) {
        $('.g-recaptcha > div').addClass("input-error");
        return false;
      }

      let formGroupTravel = document.createElement('form');
      var oidGroup = document.getElementById("oid").value;
      var recordTypeGroup = document.getElementById("recordType").value;
      var retURLGroup = document.getElementById("retURL").value;
      var actionUrlGroup = document.getElementById("actionUrl").value;
      //Changes For DAI-14322
      var orgIdGroup = document.getElementById("orgId").value;
      orgIdGroup = '{"keyname":"webtolead","fallback":"true","orgId":"' + orgIdGroup + '","ts":"' + new Date().getTime() + '"}';
      // Add form attributes
      formGroupTravel.action = actionUrlGroup;
      formGroupTravel.method = 'post';
      formGroupTravel.classList.add('not-ajax');
      // Add hidden inputs to form
      this.addInput(formGroupTravel, 'oid', oidGroup);
      this.addInput(formGroupTravel, 'retURL', retURLGroup);
      this.addInput(formGroupTravel, 'recordType', recordTypeGroup);
      this.addInput(formGroupTravel, 'lead_source', leadSource);
      this.addInput(formGroupTravel, 'Group_Travel_Form_Brand_ID__c', brand_id.toUpperCase());
      this.addInput(formGroupTravel, 'first_name', $('.contact-form .first-name input').val());
      this.addInput(formGroupTravel, 'last_name', $('.contact-form .last-name input').val());
      this.addInput(formGroupTravel, 'email', $('.contact-form .email input').val());
      this.addInput(formGroupTravel, 'phone', $('.contact-form .phone input').val());
      this.addInput(formGroupTravel, 'Group_Travel_Form_Number_of_Rooms__c', $('.contact-form .guest-room-no input').val());
      this.addInput(formGroupTravel, 'Check_In__c', $('.contact-form .arrival-date input').val());
      this.addInput(formGroupTravel, 'Check_Out__c', $('.contact-form .depart-date input').val());
      this.addInput(formGroupTravel, 'Property_City__c', $('.contact-form .group-city input').val());
      this.addInput(formGroupTravel, 'Group_Travel_Form_Property_State__c', $('.contact-form .group-state-province select option:selected').text());
      this.addInput(formGroupTravel, 'Group_Travel_Form_Property_Country__c', $('.contact-form .group-country select option:selected').text());
      this.addInput(formGroupTravel, 'Group_Travel_Form_Promo_Code__c', $('.contact-form .promo-code select').val());
      this.addInput(formGroupTravel, 'Property_Name__c', $('.contact-form .hotel-selection input').val());
      this.addInput(formGroupTravel, 'Miles_from_City_Center__c', $('.contact-form .miles input').val());
      this.addInput(formGroupTravel, 'Decision_Date__c', $('.contact-form .decision-date input').val());
      this.addInput(formGroupTravel, 'Meeting_Space_Required__c', $('.contact-form .meetSpaceReq input[name="meetSpaceReq"]:checked').val());
      this.addInput(formGroupTravel, 'Group_Travel_Request_Specifications__c', $('.contact-form .your-feedback textarea').val());
      //Changes For DAI-14322
      this.addInput(formGroupTravel, 'captcha_settings', orgIdGroup);
      this.addInput(formGroupTravel, 'g-recaptcha-response', responseToken);
      // Append form
      document.body.appendChild(formGroupTravel);
      // Submit
      formGroupTravel.submit();

    } else if(exists(bestRateForm)) {
      let responseToken = $('.g-recaptcha').attr('data-responsetoken');
      if(!responseToken) {
        $('.g-recaptcha > div').addClass("input-error");
        return false;
      }

      let bestRateForm = document.createElement('form');
      var oidBestRate = document.getElementById("orgId").value;
      var recordTypeBestRate = document.getElementById("recordType").value;
      var retURLBestRate = document.getElementById("retURL").value;
      var actionUrlBestRate = document.getElementById("actionUrl").value;
      var originBestRate = document.getElementById("origin").value;
      var externalBestRate = document.getElementById("external").value;

      // Add form attributes
      bestRateForm.action = actionUrlBestRate;
      bestRateForm.method = 'post';
      bestRateForm.classList.add('not-ajax');
      // Add hidden inputs to form
      this.addInput(bestRateForm, 'orgid', oidBestRate);
      this.addInput(bestRateForm, 'retURL', retURLBestRate);
      this.addInput(bestRateForm, 'recordType', recordTypeBestRate);
      this.addInput(bestRateForm, 'origin', originBestRate);
      this.addInput(bestRateForm, 'external', externalBestRate);
      this.addInput(bestRateForm, 'status', 'New');
      this.addInput(bestRateForm, 'CC_Web_First_Name__c', $('.contact-form .first-name input').val());
      this.addInput(bestRateForm, 'CC_Web_Last_Name__c', $('.contact-form .last-name input').val());
      this.addInput(bestRateForm, 'CC_Web_Email__c', $('.contact-form .email input').val());
      this.addInput(bestRateForm, 'CC_Web_Phone__c', $('.contact-form .phone input').val());
      this.addInput(bestRateForm, 'CC_Web_Street__c', $('.contact-form .street input').val());
      this.addInput(bestRateForm, 'CC_Web_Street_2__c', $('.contact-form .street2 input').val());
      this.addInput(bestRateForm, 'CC_Web_City__c', $('.contact-form .city input').val());
      this.addInput(bestRateForm, 'CC_Web_Postal_Code__c', $('.contact-form .postcode input').val());

      let state = '';
      // Get code for state, since it has the country too strip it off i.e. US_CO
      if($('.contact-form .state-province select').prop('selectedIndex') > 0) {
        state = this.convertStateCode($('.contact-form .state-province select option:selected').val());
      }
      this.addInput(bestRateForm, 'CC_Web_State__c', state);

      let country = '';
      if($('.contact-form .country select').prop('selectedIndex') > 0) {
        country = $('.contact-form .country select option:selected').val();
      }
      this.addInput(bestRateForm, 'CC_Web_Country__c', country);

      this.addInput(bestRateForm, 'CC_Arrival_Date__c', $('.contact-form .date-of-stay input').val());
      this.addInput(bestRateForm, 'CC_Competing_Rate__c', $('.contact-form .competing-rate input').val());
      this.addInput(bestRateForm, 'description', $('#yourFeedback').val());
      this.addInput(bestRateForm, 'CC_Confirmation_Number__c', $('.contact-form .hotel-name input').val());
      this.addInput(bestRateForm, 'CC_Competing_Website__c', $('#hotelLocation').val());
      this.addInput(bestRateForm, 'priority', 'High');



      // Append form
      document.body.appendChild(bestRateForm);
      // Submit
      bestRateForm.submit();
    } else if(exists(aboutMyStayForm)) {
      this.makeReservationLookupRequired(false);
      let responseToken = $('.g-recaptcha').attr('data-responsetoken');
      if(!responseToken) {
        $('.g-recaptcha > div').addClass("input-error");
        return false;
      }
      let phoneNumber = $('#customerPhone').val();
      const newPhoneNumber = PhoneInput.getPhoneNumberWithCountryCode(phoneNumber, 0);
      if(newPhoneNumber !== phoneNumber) {
        $('#customerPhone').val(newPhoneNumber);
      }
      
      let aboutMyStayForm = document.createElement('form');
      var oidMyStayForm = document.getElementById("orgId").value;
      var recordTypeMyStayForm = document.getElementById("recordType").value;
      var retURLMyStayForm = document.getElementById("retURL").value;
      var actionUrlMyStayForm = document.getElementById("actionUrl").value;
      var originMyStayForm = document.getElementById("origin").value;
      var externalMyStayForm = document.getElementById("external").value;
      var country = $('#customerCountryCode').val();
      if((country !== null) && (country !== '') && (country !== undefined) ) {
        const countrySplit = country.split('-');
        country = countrySplit[0];

      }
      const state = this.convertStateCode($('#customerStateCode').val());
      // Add form attributes
      aboutMyStayForm.action = actionUrlMyStayForm;
      aboutMyStayForm.method = 'post';
      aboutMyStayForm.classList.add('not-ajax');
      // Add hidden inputs to form
      this.addInput(aboutMyStayForm, 'orgid', oidMyStayForm);
      this.addInput(aboutMyStayForm, 'retURL', retURLMyStayForm);
      this.addInput(aboutMyStayForm, 'recordType', recordTypeMyStayForm);
      this.addInput(aboutMyStayForm, 'origin', originMyStayForm);
      this.addInput(aboutMyStayForm, 'external', externalMyStayForm);
      const siteId = $('#siteId').val();
      if(siteId !== '') {
        this.addInput(aboutMyStayForm, 'CC_Site_ID__c', siteId);

      }
      this.addInput(aboutMyStayForm, 'status', 'New');
      this.addInput(aboutMyStayForm, 'priority', 'Medium');

      //category section
      let category = $('#contactCategory').val();
      this.addInput(aboutMyStayForm,'CC_Category__c', this.convertCategory(category));
      this.addInput(aboutMyStayForm,'CC_Existing_Customer_Care_Case_Number__c', $('#CaseNumberLabel').val());

      let subCategory = '';
      if (category === recentstayfeedback && $('#subCategory option:selected:not([disabled])').val() != undefined) {
			subCategory = $('#subCategory option:selected:not([disabled])').val();
      }
      this.addInput(aboutMyStayForm,'CC_Feedback_Reasons__c', subCategory);

      //find my reservation section
      this.addInput(aboutMyStayForm,'CC_Web_First_Name__c', $('#customerFirstName').val());
      this.addInput(aboutMyStayForm,'CC_Web_Last_Name__c', $('#customerLastName').val());
      this.addInput(aboutMyStayForm,'CC_Confirmation_Number__c', $('#confirmationNumber').val());
      this.addInput(aboutMyStayForm,'CC_Web_Wyndham_Rewards__c', $('#rewardNumber').val());

      //contact information
      this.addInput(aboutMyStayForm, 'CC_Web_Street__c', $('#customerAddress').val());
      this.addInput(aboutMyStayForm, 'CC_Web_Street_2__c', $('#customerAddress2').val());
      this.addInput(aboutMyStayForm, 'CC_Web_City__c', $('#customerCity').val());
      this.addInput(aboutMyStayForm, 'CC_Web_State__c', state);
      this.addInput(aboutMyStayForm, 'CC_Web_Country__c', country);
      this.addInput(aboutMyStayForm, 'CC_Web_Postal_Code__c', $('#customerPostalCode').val());
      this.addInput(aboutMyStayForm, 'CC_Web_Email__c', $('#email').val());
      this.addInput(aboutMyStayForm, 'CC_Web_Phone__c', $('#customerPhone').val());

      //Reservation details
      this.addInput(aboutMyStayForm, 'CC_Reservation_First_Name__c', $('#firstName').val());
      this.addInput(aboutMyStayForm, 'CC_Reservation_Last_Name__c', $('#lastName').val());
      this.addInput(aboutMyStayForm, 'CC_Arrival_Date__c', $('#checkin').val());
      this.addInput(aboutMyStayForm, 'CC_Departure_Date__c', $('#checkout').val());
      this.addInput(aboutMyStayForm, 'CC_Hotel_Brand__c', $('#hotelBrand').val());
      this.addInput(aboutMyStayForm, 'CC_Hotel_City__c', $('#hotelCity').val());
      this.addInput(aboutMyStayForm, 'CC_Hotel_Name__c', $('#hotelName').val());
      this.addInput(aboutMyStayForm, 'description', $('#yourFeedback').val());

      // Append form
      document.body.appendChild(aboutMyStayForm);

      Analytics.getAboutMyStayAnalytics().trackAboutMyStaySubmitSuccessfull();
      // Submit
      aboutMyStayForm.submit();
    } else if(exists(doNotSellFrom)) {
      let responseToken = $('.g-recaptcha').attr('data-responsetoken');
      if(!responseToken) {
        $('.g-recaptcha > div').addClass("input-error");
        return false;
      }
      postURL = getServiceUrl('doNotSell');
      let firstName = $('.contact-form .first-name input').val(),
        lastName = $('.contact-form .last-name input').val(),
        mailingaddress = $('.contact-form .mailingaddress input').val(),
        email = $('.contact-form .email input').val(),
        wrnumber = $('.contact-form .wrnumber input').val(),
        contactPhone = $('.contact-form .phone input').val();
      contactObj.firstName = firstName;
      contactObj.lastName = lastName;
      contactObj.name = firstName + ' ' + lastName;
      contactObj.type = 'email';
      contactObj.phoneNumber = contactPhone;
      contactObj.mailAddress = mailingaddress;
      contactObj.wrNumber = wrnumber;
      contactObj.sender = email;
      contactObj.bodypart1 = '';
      contactObj.bodypart2 = '';
      contactObj.responseToken = responseToken;
      contactObj = JSON.stringify(contactObj);
      $.ajax({
        url: postURL,
        type: 'POST',
        data: contactObj,
        dataType: 'json',
        contentType: 'application/json',
        success: (result) => {
          if(result.success === "true") {
            postURL = '/WHGServices/loyalty/V4/member/sendMessage';
            this.submitToService(contactObj, postURL, form);
          } else {
            $('.g-recaptcha > div').addClass("input-error");
            grecaptcha.reset();
          }
        },
        error: (result, status, error) => {
          EventHandler.triggerEvent('brands-error', result);
          $(window).scrollTop($('.contact-form').position().top);
          grecaptcha.reset();
        }
      });
    } else {
      contactObj = this.collectFormValues();
      postURL = '/BWSServices/services/contact-service';
      this.submitToService(contactObj, postURL, form);
    }
  }
  /**
   * for adding hidden fields
   */
  addInput(form, key, value) {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  }


  /**
   * For submitting to service
   */
  submitToService(contactObj, postURL, form) {
    let formRedirectUrl = form.data('redirect-url');
    if (contactObj) {
      $.ajax({
        url: postURL,
        type: 'POST',
        data: contactObj,
        dataType: 'json',
        contentType: 'application/json',
        success: (result) => {
          if (result && (result.status || result.Status || result.success)) {
            if ((result.status || result.Status) !== 'Error') {
              window.location.replace(formRedirectUrl);
            } else {
              let formError = $('.contact-form').find('.form-error');
              let errmsg;
              if(result.ErrorMessage) {
                errmsg = result.ErrorMessage;
              } else {
                errmsg = result.errmsg;
              }
              if (formError.length > 0) {
                formError.text(errmsg);
              } else {
                $('.contact-form').prepend(`<span class="form-error">${errmsg}</span>`);
              }
              $(window).scrollTop($('.contact-form').position().top);
              let doNotSellFrom = $('.do-not-sell-info');
              if(exists(doNotSellFrom)) {
                grecaptcha.reset();
              }
            }
          }
        },
        error: (result, status, error) => {
          let doNotSellFrom = $('.do-not-sell-info');
          if(exists(doNotSellFrom)) {
            if(result.responseJSON) {
              let formError = $('.contact-form').find('.form-error');
              let errmsg;
              if(result.responseJSON.ErrorMessage) {
                errmsg = result.responseJSON.ErrorMessage;
              } else {
                errmsg = result.errmsg;
              }
              if (formError.length > 0) {
                formError.text(errmsg);
              } else {
                $('.contact-form').prepend(`<span class="form-error">${errmsg}</span>`);
              }
            }
            grecaptcha.reset();
          }
          EventHandler.triggerEvent('brands-error', result);
          $(window).scrollTop($('.contact-form').position().top);
        }
      });
    }
  }

  collectFormValues() {
    let topic = $('.contact-form .category select#contactCategory').val(),
      firstName = $('.contact-form .first-name input').val(),
      lastName = $('.contact-form .last-name input').val(),
      address1 = $('.contact-form .street input').val(),
      address2 = $('.contact-form .street2 input').val(),
      city = $('.contact-form .city input').val(),
      state = $('.contact-form .state-province select').val(),
      zipCode = $('.contact-form .postcode input').val(),
      country = $('.contact-form .country select').val(),
      groupcity = $('.contact-form .group-city input').val(),
      groupstate = $('.contact-form .group-state-province select option:selected').text(),
      groupcountry = $('.contact-form .group-country select option:selected').text(),
      contactPhone = $('.contact-form .phone input').val(),
      email = $('.contact-form .email input').val(),
      confirmEmail = $('.contact-form .confirm-email input').val() || email,
      dateOfStay = $('.contact-form .date-of-stay input').val(),
      hotelName = $('.contact-form .hotel-name input').val(),
      hotelLocation = $('.contact-form .hotel-location input').val(),
      comments = $('.contact-form .your-feedback textarea').val(),
      brands = $('#corpContactCategory').val(),
      estimatedAnnualLodgingSpend = $('.contact-form .estimated-Annual-Lodging-Spend input').val(),
      specificCitiesHotels = $('.contact-form .specific-Cities-Hotels input').val(),
      brandId = brand_id.toUpperCase(),
      wrMemberNum = $('#contact-us-wrMemberNum').val(),
      arrivalDate = $('.contact-form .arrival-date input').val(),
      departDate = $('.contact-form .depart-date input').val(),
      guestNo = $('#contact-us-guestNo').val(),
      roomType = $('#contact-us-roomType').val(),
      hotelRateQuoted = $('.contact-form .competing-rate input').val(),
      lowerRate = $('#contact-us-lowerRate').val(),
      organization = $('#contact-us-organization').val(),
      groupType = $('#contact-us-groupType').val(),
      groupName = $('#contact-us-groupName').val(),
      hotelSelection = $('.contact-form .hotel-selection input').val(),
      hotelSpecificAddress = $('#contact-us-hotelSpecificAddress').val(),
      hotelSpecificState = $('.contact-form .hotel-specific-state input').val(),
      eventPastLocs = $('#contact-us-eventPastLocs').val(),
      miles = $('.contact-form .miles input').val(),
      rateFrom = $('#contact-us-rateFrom').val(),
      rateTo = $('#contact-us-rateTo').val(),
      guestRoomNo = $('.contact-form .guest-room-no input').val(),
      decisionDate = $('.contact-form .decision-date input').val(),
      transportation = $('#contact-us-transportation').val(),
      meetSpaceReq = $('.contact-form .meetSpaceReq input[name="meetSpaceReq"]:checked').val(),
      meetFirst = $('#contact-us-meetFirst').val(),
      meetLast = $('#contact-us-meetLast').val(),
      guestNum = $('#contact-us-guestNum').val(),
      setupReq = $('#contact-us-setupReq').val(),
      multiRooms = $('#contact-us-multiRooms:checked').val(),
      moreRoomDetails = $('#contact-us-moreRoomDetails').val(),
      extraAmenities = $('#contact-us-extraAmenities').val(),
      fax = $('#contact-us-fax').val(),
      compName = $('.contact-form .comp-name input').val(),
      contactPerson = $('#contact-us-contactPerson').val(),
      title = $('#contact-us-title').val(),
      businessType = $('#contact-us-businessType').val(),
      headQuar = $('#contact-us-headQuar').val(),
      hotelNights = $('#contact-us-hotelNights').val(),
      travellerNo = $('#contact-us-travellerNo').val(),
      citiesVisited = $('#contact-us-citiesVisited').val(),
      currentHotels = $('#contact-us-currentHotels').val(),
      travelAgency = $('#contact-us-travelAgency:checked').val(),
      taName = $('#contact-us-taName').val(),
      taAddress = $('#contact-us-taAddress').val(),
      taCity = $('#contact-us-taCity').val(),
      taState = $('#contact-us-taState').val(),
      taPostal = $('#contact-us-taPostal').val(),
      taReservation = $('#contact-us-taReservation').val(),
      taPhone = $('#contact-us-taPhone').val(),
      taFax = $('#contact-us-taFax').val(),
      taIata = $('#contact-us-taIata').val();

    let selectedAme = [];
    $('.check-box-section :checked').each((i, el) => {
      selectedAme.push($(el).val());
    });
    let amenities = selectedAme.join('---');

    return this.populateContactObj(topic, firstName, lastName, address1, address2, city, state, zipCode,
      country, contactPhone, email, confirmEmail, dateOfStay, hotelName,
      hotelLocation, comments, brandId, wrMemberNum, arrivalDate, departDate,
      guestNo, roomType, hotelRateQuoted, lowerRate,
      organization, groupType, groupName, hotelSelection, hotelSpecificAddress,
      hotelSpecificState, eventPastLocs, miles, rateFrom, rateTo, guestRoomNo,
      decisionDate, transportation, amenities, meetSpaceReq,
      meetFirst, meetLast, guestNum, setupReq, multiRooms, moreRoomDetails,
      extraAmenities, fax, compName, contactPerson, title, businessType, headQuar,
      hotelNights, travellerNo, citiesVisited, currentHotels, travelAgency, taName,
      taAddress, taCity, taState, taPostal, taReservation, taPhone, taFax, taIata,
      groupcity, groupstate, groupcountry, brands, estimatedAnnualLodgingSpend, specificCitiesHotels);

  }

  populateContactObj(topic, firstName, lastName, address1, address2, city, state, zipCode,
                     country, contactPhone, email, confirmEmail, dateOfStay, hotelName,
                     hotelLocation, comments, brandId, wrMemberNum, arrivalDate, departDate,
                     guestNo, roomType, hotelRateQuoted, lowerRate,
                     organization, groupType, groupName, hotelSelection, hotelSpecificAddress,
                     hotelSpecificState, eventPastLocs, miles, rateFrom, rateTo, guestRoomNo,
                     decisionDate, transportation, amenities, meetSpaceReq,
                     meetFirst, meetLast, guestNum, setupReq, multiRooms, moreRoomDetails,
                     extraAmenities, fax, compName, contactPerson, title, businessType, headQuar,
                     hotelNights, travellerNo, citiesVisited, currentHotels, travelAgency, taName,
                     taAddress, taCity, taState, taPostal, taReservation, taPhone, taFax, taIata,
                     groupcity, groupstate, groupcountry, brands, estimatedAnnualLodgingSpend, specificCitiesHotels) {
    let contactObj = {};
    contactObj.topic = topic;
    contactObj.firstName = firstName;
    contactObj.lastName = lastName;
    contactObj.address1 = address1;
    contactObj.address2 = address2;
    contactObj.city = city;
    if (state && state.length === 5) {
      state = state.substring(3);
    }
    contactObj.state = state;
    if (topic === 'corporaterequest') {
      contactObj.zipCode = zipCode;
      contactObj.brands = brands;
      contactObj.estimatedAnnualLodgingSpend = estimatedAnnualLodgingSpend;
      contactObj.specificCitiesHotels = specificCitiesHotels;
    } else if (topic === 'bestrateclaim') {
      dateOfStay = $.datepicker.parseDate(window.localeDateFormat.dateFormat || 'mm/dd/yy', dateOfStay);
      contactObj.zipCode = zipCode;
      contactObj.wrMemberNum = wrMemberNum;
      contactObj.arrivalDate = formatDateForBWS(dateOfStay);
      contactObj.depart = departDate;
      contactObj.guestNo = guestNo;
      contactObj.roomType = roomType;
      contactObj.confNum = hotelName;
      contactObj.hotelRateQuoted = hotelRateQuoted;
      contactObj.lowerRate = lowerRate;
      contactObj.competitor = hotelLocation;
    } else {
      contactObj.postal = zipCode;
    }
    contactObj.country = country;
    contactObj.contactPhone = contactPhone;
    contactObj.email = email;
    contactObj.confirmEmail = confirmEmail;
    if (topic != 'grouptravel' && topic != 'corporaterequest' && topic != 'bestrateclaim') {
      dateOfStay = $.datepicker.parseDate(window.localeDateFormat.dateFormat || 'mm/dd/yy', dateOfStay);
      contactObj.dateOfStay = formatDateForBWS(dateOfStay);
    }
    if(topic != 'bestrateclaim') {
      contactObj.hotelName = hotelName;
      contactObj.hotelLocation = hotelLocation;
    }
    contactObj.comments = comments;
    contactObj.brandId = brandId;

    if (topic === 'grouptravel') {
      contactObj.zipCode = zipCode;
      contactObj.organization = organization;
      contactObj.groupType = topic;
      contactObj.groupName = groupName;
      contactObj.hotelSelection = hotelSelection;
      contactObj.hotelSpecificAddress = hotelSpecificAddress;
      // contactObj.hotelSpecificState = hotelSpecificState;
      contactObj.hotelSpecificState = `${groupcity}, ${groupstate}, ${groupcountry}`;
      contactObj.eventPastLocs = eventPastLocs;
      contactObj.miles = miles;
      contactObj.arrivalDate = arrivalDate;
      contactObj.departDate = departDate;
      contactObj.rateFrom = rateFrom;
      contactObj.rateTo = rateTo;
      contactObj.guestRoomNo = guestRoomNo;
      contactObj.decisionDate = decisionDate;
      contactObj.transportation = transportation;
      contactObj.amenities = amenities;
      contactObj.meetSpaceReq = meetSpaceReq;
      contactObj.meetFirst = meetFirst;
      contactObj.meetLast = meetLast;
      contactObj.guestNum = guestNum;
      contactObj.setupReq = setupReq;
      contactObj.multiRooms = multiRooms;
      contactObj.moreRoomDetails = moreRoomDetails;
      contactObj.extraAmenities = extraAmenities;
    }
    if (topic === 'grouptravel' || topic === 'longtermstay' || topic === 'corporaterequest') {
      contactObj.fax = fax;
    }
    if (topic === 'corporaterequest' || topic === 'longtermstay') {
      contactObj.compName = compName;
      contactObj.contactPerson = contactPerson;
      contactObj.title = title;
      contactObj.businessType = businessType;
      contactObj.headQuar = headQuar;
      contactObj.hotelNights = hotelNights;
      contactObj.travellerNo = travellerNo;
      contactObj.citiesVisited = citiesVisited;
      contactObj.currentHotels = currentHotels;
      contactObj.travelAgency = travelAgency;
      contactObj.taName = taName;
      contactObj.taAddress = taAddress;
      contactObj.taCity = taCity;
      if (taState && taState.length === 5) {
        taState = taState.substring(3);
      }
      contactObj.taState = taState;
      contactObj.taPostal = taPostal;
      contactObj.taReservation = taReservation;
      contactObj.taPhone = taPhone;
      contactObj.taFax = taFax;
      contactObj.taIata = taIata;
    }

    return contactObj;
  }

  /**
   * convertCategory - Converts the category drop down code to a string expected by Salesforce
   * @param category
   */
  convertCategory(category) {
    let val = category;
    switch (category) {
      case recentstayfeedback:
        val = 'Recent Stay Feedback';
        break;
      case billingissues:
        val = 'Billing Issues';
        break;
      case existingcustomercarecase:
        val = 'Existing Customer Care Case';
        break;
      default:
        val = 'Recent Stay Feedback';
        break;
    }
    return val;
  }

  /**
   * Converts the state code i.e. US_CO to CO
   * @param code
   * @returns {string}
   */
  convertStateCode(code) {
    let state = '';
    if((code !== null) && (code !== '')) {
      const stateSplit = code.split('_');
      state = (stateSplit.length > 1) ? stateSplit[1] : stateSplit[0];
    }
    return state;
  }

}


export default new ContactUs();
