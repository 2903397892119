import { exists } from '../base/dom-utils.js';
import { _LOCALE_, overview_propertyId, overview_lat, overview_long, overview_orsId, sessionId, $_PAGE_ } from '../base/vars.js';
import { getBrand, setCriteria, getCriteria } from '../base/session-handler.js';
import { formatDateForBWSAvailability, getServiceUrl, getToday, isPriorDate, getTomorrow, getSearchParameters } from '../base/utils.js';
import PropertyQuickView from '../components/property-quick-view.js';
import Analytics from '../base/wyn-analytics-module.js';
class CollectionOfHotels {
  constructor(offset = 0, limit = 50, loadHotels = false) {
    this._params = {};
    this.hotels = loadHotels ? this.callCollectionAPI(offset, limit) : null;
  }

  async callCollectionAPIFeatured(propertyId = null) {
    const callCollectionEndpoint = getServiceUrl('mySearchFavourite');
    const callCollection = `
      query sample($searchParameters: SearchParameters) {
        version
        searchProperties(searchParameters: $searchParameters) {
          properties {
            id
            propertyId
            name
            headline
            additionalDetails {
              name
              values
            }
            rating
            ratingSiteStatus
            ratingCount
            subRatings {
                ratingRoomsAverage
                ratingServiceAverage
                ratingValueAverage
                ratingCleanlinessAverage
            }
            uniqueUrl
            address {
              address1
              address2
              country
              city
              countryCode
              postalCode
              state
              stateCode
            }
            brand
            brandTier
            sabreId
            otherAmenities {
                rankId
                amenityName
                otaCode
                amenityNameEn
                accessibleCode
            }
          }
        }
      }
    `;

    const callCollectionVariables = {
      searchParameters: {
        language: _LOCALE_,
        limit: 500,
        offset: 0,
        additionalDetails: ["hotelQualityScoreRequirementFlag"],
        propertyIds: propertyId ? propertyId : null
      },
    };

    try {
      const response = await fetch(callCollectionEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: callCollection,
          variables: callCollectionVariables,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseBody = await response.json();
      return responseBody;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  async callCollectionAPI(offset, limit = 50, filterOptions = null) {
    if(exists('.listproperties')) {
      $_PAGE_.addClass('loading');
    }
    const serviceUrl = getServiceUrl('mySearchFavourite');
    const callCollectionEndpoint = serviceUrl;
    const callCollection = `
      query sample($searchParameters: SearchParameters) {
        version
        searchProperties(searchParameters: $searchParameters) {
          totalCount
          properties {
            id
            propertyId
            name
            headline
            additionalDetails {
              name
              values
            }
            ratingSiteStatus
            rating
            ratingCount
            subRatings {
                ratingRoomsAverage
                ratingServiceAverage
                ratingValueAverage
                ratingCleanlinessAverage
            }
            uniqueUrl
            address {
              address1
              address2
              country
              city
              countryCode
              postalCode
              state
              stateCode
            }
            brand
            brandTier
            sabreId
            otherAmenities {
                rankId
                amenityName
                otaCode
                amenityNameEn
                accessibleCode
            }
          }
          totalCount
        }
          }
    `;

    const callCollectionVariables = {
      searchParameters: {
        language: _LOCALE_,
        limit: limit,
        offset: offset,
        additionalDetails: [
          "hotelQualityScoreRequirementFlag"
        ]
      },
    };
    callCollectionVariables.searchParameters = getSearchParameters(callCollectionVariables.searchParameters, filterOptions);

    try {
      const response = await fetch(callCollectionEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: callCollection,
          variables: callCollectionVariables,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      $_PAGE_.removeClass('loading');
      const responseBody = await response.json();
      return responseBody;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  getImageTaggingData(requestObject) {
    const jsonData = JSON.stringify(requestObject);
    const url = '/bin/fetchImageData';
    let df = $.Deferred();
    let imgData;
    $.ajax({
      url: url,
      type: 'POST',
      data: jsonData,
      contentType: 'application/json',
      success: (data) => {
        imgData = df.resolve(data);
        return imgData;
      },
      error: (res) => {
        df.reject(res);
      },
    });
    return df.promise();
  }

  fetchDynamicProperties(crrResourcePath, lang) {
    const fcCdardData = JSON.stringify({ currentResourcePath: crrResourcePath, locale: lang });
    $.ajax({
      type: 'POST',
      url: '/bin/saveFeatureCardDeafaultData',
      data: fcCdardData,
      contentType: 'application/json',
    });
  }

  renderFcDynamicContent(ids, response) {
    let dynamicCollection = [];
    if (response && Array.isArray(response)) {
      for (let id of ids) {
        const item = response.find((item) => item.propertyId === id);
        if (item) {
          dynamicCollection.push(item);
        }
      }
    }
    return dynamicCollection;
  }

  appendQueryParamsToUrl(url, params) {
    const urlParts = url.split("?");
    const baseUrl = urlParts[0];
    let existingParams = {};
    if (urlParts.length > 1) {
      existingParams = urlParts[1].split("&").reduce((acc, item) => {
        const [key, value] = item.split("=");
        acc[key] = value;
        return acc;
      }, {});
    }
    const allParams = Object.entries(params)
      .filter(([key, value]) => value !== undefined && value !== null && value !== "")
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, existingParams);

    const encodedParams = Object.entries(allParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");

    return `${baseUrl}?${encodedParams}`;
  }

  excludeOverview(url) {
    const urlParts = url.split("/");
      if (urlParts[urlParts.length - 1] === "overview") {
        urlParts.pop();
      return urlParts.join("/");
    }
  }

  initParams() {
    let sc = getCriteria();
    let checkInDate = sc.checkInDate; 
    let checkOutDate = sc.checkOutDate;
    let isCheckInPriorToday = isPriorDate(checkInDate, getToday());
    let isCheckOutPriorToday = isPriorDate(checkOutDate, getToday());
    if (isCheckInPriorToday || isCheckOutPriorToday) {
      let today = formatDateForBWSAvailability(getToday());
      let tomorrow = formatDateForBWSAvailability(getTomorrow());
      sc.checkin_date = today;
      sc.checkout_date = tomorrow;
      setCriteria({
        checkInDate: today,
        checkOutDate: tomorrow
      });
    }
    sc = getCriteria();

    this._params.brand_id = getBrand(true) || sc.brandId;
    this._params.checkin_date = formatDateForBWSAvailability(sc.checkInDate);
    this._params.checkout_date = formatDateForBWSAvailability(sc.checkOutDate);
    this._params.useWRPoints = sc.useWRPoints;
    this._params.rate_code = sc.rateCode;
    this._params.corporate_id = sc.corpCode;
    this._params.ratePlan = sc.ratePlan;
    this._params.group_code = sc.groupCode;
    this._params.childAge = sc.childAge;
    this._params.children = sc.children;
    this._params.adults = sc.adults;
    this._params.rooms = sc.rooms;
    this._params.iata = sc.iata;
    this._params.language = sc.language;
    this._params.properties = sc.properties;
    this._params.propertyId = overview_propertyId || sc.propertyId;
    this._params.orsId = overview_orsId;
    this._params.lat = overview_lat;
    this._params.longi = overview_long;
    this._params.sessionId = sessionId;
  }

  handleQuickViewModal(selElem, targetElem) {
    this.initParams();
    let notificationData;
    const featuredCardWrapper = document.querySelector(selElem);
    const quickViewLinks = featuredCardWrapper.querySelectorAll(targetElem);
    const bbmd = $('#bb-md');

    if (quickViewLinks.length > 0 && bbmd.length > 0) {
      quickViewLinks.forEach((link) => {
        const notificationAttr = link.getAttribute('data-notification');
        const propertyUrl = link.getAttribute('href');
        const propertyUrlWithParam = this.appendQueryParamsToUrl(propertyUrl, this._params);
        const bookNowUrl = this.excludeOverview(link.getAttribute('href'));
        const bookNowUrlWithParam = this.appendQueryParamsToUrl(`${bookNowUrl}/rooms-rates`, this._params);
        link.setAttribute('href', `/${propertyUrlWithParam}`);
        link.setAttribute('data-book-now-url', `/${bookNowUrlWithParam}`);

        if (notificationAttr) {
          notificationData = JSON.parse(decodeURIComponent(notificationAttr));
        }
        link.addEventListener('click', (e) => {
          e.preventDefault();
          const propertyId = e.currentTarget.getAttribute('data-id');
          const getPropertyUrl = e.currentTarget.getAttribute('href');
          const getBookNowUrl = e.currentTarget.getAttribute('data-book-now-url');
          PropertyQuickView.init(propertyId, getPropertyUrl, getBookNowUrl, notificationData);
          Analytics.satelliteTracker('quickViewClick');
        });
      });
    }
  }
}

export default CollectionOfHotels;
