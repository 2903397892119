import { isPublish, _LOCALE_, _LANGUAGE_ } from '../base/vars.js';
import { exists,ratigStart } from '../base/dom-utils.js';
import CollectionOfHotels from '../pages/collection-hotels.js';
import RenderFavourite from './render-favourite.js';
import { getHiddenFieldValueForQuickView, createPictureElement } from '../base/utils.js';
import Analytics from '../base/wyn-analytics-module.js';
import { fetchOtherAmenities } from '../base/utils.js';

class FeaturedCard {
  constructor() {
    this.handleClickAnalytics();
    this.initCarousel();
    if (isPublish === false || exists('.collection-hotels-page')) {
      const crrPath = location.pathname;
      const crrResourcePath = crrPath.split('.')[0];
      this.collectionOfHotels = new CollectionOfHotels();
      this.collectionOfHotels.fetchDynamicProperties(crrResourcePath, _LOCALE_);
    }
    this.payload = {};
    if (exists('.featurecard')) {
      this.handleDynamicCards();
    }
  }

  initCarousel() {
    if ($('.featured-card .featured-card-carousel').length > 0) {
      $('.featured-card .featured-card-carousel')
        .not('.slick-initialized')
        .slick({
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 0,
          responsive: [
            {
              breakpoint: 1199,
              settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 719,
              settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
              },
            },
            {
              breakpoint: 374,
              settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
              },
            },
          ],
        });
    }
  }

  async handleDynamicCards() {
    let imgIdsArr = [];
    let requestObject = {};
    const propertiesObj = [];
    const featurecardInput = document.querySelector('.featured-card .dynamicFeaturedId');
    const collectionTypeInput = document.querySelector('.featured-card .collectionType');
    const dynamicIdsValue = featurecardInput.value;
    const collectionTypeValue = collectionTypeInput.value;
    const dynamicIdArr = dynamicIdsValue.split(',');

    if (collectionTypeInput && collectionTypeValue !== '') {
      requestObject.collectionType = collectionTypeValue;
    }

    if (featurecardInput && dynamicIdsValue !== '') {
      try {
        const res = await this.collectionOfHotels.callCollectionAPIFeatured(dynamicIdArr);
        const dynamicIdscollection = this.collectionOfHotels.renderFcDynamicContent(dynamicIdArr, res.data.searchProperties.properties);
        dynamicIdscollection.forEach((item) => {
          imgIdsArr.push({ brandId: item.brand.toLowerCase(), propertyId: item.propertyId });
          const amentiesArr = item.otherAmenities.map((item) => item.amenityName);
          const obj = {
            "language": _LANGUAGE_,
            startAmenities: amentiesArr,
            propertyId: item.propertyId
          };
          propertiesObj.push(obj);
        });

        this.payload = propertiesObj;
        // const resAmenties = await this.getStarAmenities();
        const resAmenties = await fetchOtherAmenities(this.payload);
        const keys = Object.keys(resAmenties);

        keys.forEach((itemId) => {
          dynamicIdscollection.forEach((data) => {
            if (data.propertyId === itemId) {
              data['amenityName'] = resAmenties[itemId]['amenityName'];
            }
          });
        });

        requestObject.properties = imgIdsArr;
        const resImgTagging = await this.collectionOfHotels.getImageTaggingData(requestObject);
        const featuredCardContainer = document.querySelector('.featured-card-container');

        if (featuredCardContainer) {
          dynamicIdscollection.forEach(cardItem => this.renderFeatureCard(cardItem, featuredCardContainer, resImgTagging));
          this.initCarousel();
          this.collectionOfHotels.handleQuickViewModal('.featured-card', '.quick-view-button');
        }
      } catch (error) {
        console.error('Error handling dynamic cards:', error);
      }
    }
  }
  getRecommendation(cardItem) {
    const additionalDetails = cardItem.additionalDetails;
    if (!Array.isArray(additionalDetails)) {
      return 'All Inclusive'; // or any other default string
    }
    const detail = additionalDetails.find(
      ({ name, values }) => name === 'hotelQualityScoreRequirementFlag' && Array.isArray(values) && values.includes('Y')
    );
    return detail ? 'Guest Recommended' : cardItem['amenityName'] ? cardItem['amenityName'] : "";
  }

  renderFeatureCard(cardItem, container, resData) {
    const quickViewElementID = 'quickviewcta';
    const cardListElement = document.createElement('div');
    cardListElement.classList.add('featured-card-list');

    const cardImageElement = document.createElement('div');
    cardImageElement.classList.add('featured-card-image');

    const carouselElement = document.createElement('div');
    carouselElement.classList.add('featured-card-carousel');
    cardImageElement.appendChild(carouselElement);

    const eyebrowElement = document.createElement('div');
    eyebrowElement.classList.add('featured-card-eyebrow');
    eyebrowElement.textContent = this.getRecommendation(cardItem);
    this.getRecommendation(cardItem) && cardImageElement.appendChild(eyebrowElement);
    RenderFavourite.fetchFavourite(cardImageElement, cardItem.propertyId, 'event-or-session');
    const cardDetailsElement = document.createElement('div');
    cardDetailsElement.classList.add('featured-card-details');

    const headlineElement = document.createElement('h3');
    headlineElement.classList.add('headline-h');
    headlineElement.textContent = cardItem.name;
    cardDetailsElement.appendChild(headlineElement);

    const descriptionElement = document.createElement('div');
    descriptionElement.classList.add('featured-card-description');

    const locationElement = document.createElement('p');
    locationElement.classList.add('location-text');
    locationElement.textContent = `${cardItem.address.city}, ${cardItem.address.state}`;
    descriptionElement.appendChild(locationElement);

    const currentUrl = window.location.href;
    const firstSlashIndex = currentUrl.indexOf('/', currentUrl.indexOf('//') + 2);
    const baseUrl = currentUrl.substring(0, firstSlashIndex);
    console.log("baseUrl", baseUrl);

    if(cardItem.ratingSiteStatus.toLowerCase() == 'active' && cardItem.rating != "") {
      // Create the feature review card div
      let reviewCard;
      if(cardItem.ratingCount && cardItem.ratingCount){
        reviewCard = document.createElement('a');
        reviewCard.className = 'featured-card-review';
        reviewCard.href = baseUrl + '/' + DOMPurify.sanitize(cardItem.uniqueUrl) + '#review-rating-section';
      }else {
        reviewCard = document.createElement('div');
        reviewCard.className = 'featured-card-review';
      }
      
      // Create the review section
      const reviewSection = document.createElement('div');
      reviewSection.className = 'review-section';

      // Create the rating value
      const ratingDiv = document.createElement('div');
      const ratingValue = document.createElement('span');
      ratingValue.className = 'reviews-value';
      ratingValue.textContent = cardItem.rating;
      ratingDiv.appendChild(ratingValue);

      // Create the star wrapper
      const starWrapper = document.createElement('div');
      starWrapper.className = 'review-star-wrapper';

      ratigStart(starWrapper,cardItem.rating);

      // Append rating div and star wrapper to review section
      if(cardItem.ratingCount && cardItem.ratingCount){
        reviewSection.appendChild(ratingDiv);
      }
      reviewSection.appendChild(starWrapper);

      // Create the review counts
      const reviewCounts = document.createElement(cardItem.ratingCount ? 'a' : 'div');
      reviewCounts.className = 'review-counts ' + (cardItem.ratingCount ? '' : 'no-reviews');
      reviewCounts.textContent =  cardItem.ratingCount ?  container.getAttribute('review-count-label').replace('${ratingCount}',cardItem.ratingCount) :  container.getAttribute('no-review-label');

      // Append review section and counts to the review card
      reviewCard.appendChild(reviewSection);
      reviewCard.appendChild(reviewCounts);

      // Append review card to the description div
      descriptionElement.appendChild(reviewCard);
    }

    const quickViewBtnElement = document.createElement('a');
    quickViewBtnElement.classList.add('btn-secondary', 'quick-view-button');
    quickViewBtnElement.href = DOMPurify.sanitize(cardItem.uniqueUrl);
    quickViewBtnElement.dataset.id = DOMPurify.sanitize(cardItem.propertyId);
    quickViewBtnElement.textContent = getHiddenFieldValueForQuickView(quickViewElementID) || 'Quick View';

    cardDetailsElement.appendChild(descriptionElement);
    cardDetailsElement.appendChild(quickViewBtnElement);

    cardListElement.appendChild(cardImageElement);
    cardListElement.appendChild(cardDetailsElement);

    const imageUrls = resData[cardItem.propertyId] || [];
    imageUrls.forEach((imageUrl) => {
      const classNames = ['pll-image-full-size', 'lazy-load-pageload', 'carousel-img', 'featured-card-carousel-image'];
      const pictureElement = createPictureElement(imageUrl, classNames);
      carouselElement.appendChild(pictureElement);
    });

    container.appendChild(cardListElement);
  }

  handleClickAnalytics() {
    const carouselArrows = document.querySelectorAll('.featured-card .slick-arrow');
    if (carouselArrows.length > 0) {
      carouselArrows.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          Analytics.satelliteTracker('carausalArrowClick');
        });
      });
    }
  }
}

export default new FeaturedCard();
