class LanguageRedirectModal {
  constructor() {
    this.defineReferences();
    this.addEventListeners();
    this.prepareAndOpenDialog();
  }

  defineReferences() {
    const dialogSelector = '.language-redirect-modal';
    const $dialogs = $(dialogSelector);

    this.dom = {
      $dialogs,
      $close: $dialogs.find(`${dialogSelector}__close`),
      $overlay: $dialogs.find(`${dialogSelector}__overlay`),
      $links: $dialogs.find(`${dialogSelector}__link`),
      $defaultDialog: $dialogs.filter('[data-message-id="redirect-default"]'),
    };
  }

  addEventListeners() {
    const { $close, $overlay, $links } = this.dom;

    $close.on('click', this.hide.bind(this));
    $overlay.on('click', this.hide.bind(this));
    $links.on('click', this.onLanguageClick.bind(this));
  }

  onLanguageClick() {
    if (window._satellite) {
      window._satellite.track('languageSelection');
    }
  }

  open($dialog) {
    $dialog.prop('open', true);

    if (window._satellite) {
      window._satellite.track('internationalLanguageErrorBox');
    }
  }

  hide() {
    const { $dialogs } = this.dom;

    $dialogs.prop('open', false);
  }

  prepareAndOpenDialog() {
    const { $dialogs, $links , $defaultDialog } = this.dom;
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const messageIdParam = 'messageId';
    const messageId = searchParams.get(messageIdParam);

    if (!messageId) return;

    const $localeDialog = $dialogs.filter(`[data-message-id="${messageId}"]`);

    $links.each(function() {
      const $link = $(this);
      const href = new URL($link.prop('href'));

      href.search = searchParams.toString();
      href.searchParams.delete(messageIdParam);

      $link.prop('href', href.toString());
    });

    if ($localeDialog.length) {
      this.open($localeDialog);
    } else if ($defaultDialog.length) {
      this.open($defaultDialog);
    }
  }
}

export default new LanguageRedirectModal();
