import { exists } from '../base/dom-utils.js';
import { $_PAGE_ , brand_id, REVIEWS_INIT_LIMIT } from '../base/vars.js';
import { getServiceUrl } from '../base/utils.js';
class RevievsOfHotels {
  constructor(propertyId ,offset = 1, limit = REVIEWS_INIT_LIMIT) {
    this.propertyId = propertyId;
    this.reviews = this.callReviewAPI(offset, limit);
  }

  async callReviewAPI(offset, limit = REVIEWS_INIT_LIMIT, sortOptions = { revievsSort: 'reviewsResponseDate', sortOrder: 'DESC'}) {
    if(exists('.listproperties')) {
      $_PAGE_.addClass('loading');
    }
    const serviceUrl = getServiceUrl('getReviews');
    const callCollectionEndpoint = serviceUrl;
    const callCollection = `
      query sample($searchParameters: SearchParameters) {
        version
        searchProperties(searchParameters: $searchParameters) {
          properties {
              propertyId
              brand
              reviewsSiteStatus
              pagination{
                  totalRecords
                  totalPages
                  currentPage
                  pageSize
              }
              reviews{
                  reviewsSurveyId
                  reviewsInteractionId
                  reviewsGuestName
                  reviewsGuestStateProvince
                  reviewsGuestCountry
                  reviewsGuestRewardsLevel
                  reviewsGuestRewardsLevelDesc
                  reviewsGuestResponseDate
                  reviewsCreatedTimestamp
                  reviewsUpdatedTimestamp
                  reviewsRating
                  reviewsService
                  reviewsCleanliness
                  reviewsGuestroom
                  reviewsValue
                  reviewsMainComment
                  reviewsMainCommentEnglishTranslation
                  reviewsRapidResponseComment
                  reviewsActivities
                  reviewsSurveyStatus
                  reviewsOptInFlag
                  reviewsMemberLocation
              }
          }
        }
      }
    `;

    const callCollectionVariables = {
      searchParameters: {
          limit: limit,
          page: offset,
          reviewsBrandId :  brand_id.toLocaleUpperCase() ,
          reviewsPropertyId:  this.propertyId ? this.propertyId : null ,
          sortBy: {
            sortingKey: sortOptions.revievsSort,
            sortOrder: sortOptions.sortOrder
          }
      },
    };

    try {
      const response = await fetch(callCollectionEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: callCollection,
          variables: callCollectionVariables,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseBody = await response.json();
      return responseBody;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

}

export default RevievsOfHotels;
